import { Component, createRef } from "react";
import { ConditionalRequired } from "common/widgets/conditional-required";
import { getLocalizedName } from "common";
import { searchApi } from "common/api/search";
import {
  isCustomOrSystemFk,
  isRestrictedForRole,
} from "common/entities/entity-column/functions";
import { type EntityColumn } from "common/entities/entity-column/types";
import { type Entity } from "common/entities/types";
import {
  type FormValidationProps,
  type LookupConfiguration,
  type MappedField,
} from "common/form/types";
import { classNames } from "common/utils/jsx";
import { LabelWidget } from "common/form/widget/label-widget";
import {
  getFkId,
  getSubFKTitle,
  isForeignKey,
} from "common/functions/foreign-key";
import { addFilterToQuery } from "common/query/filter";
import { type Context } from "common/types/context";
import { type FkValue, type ForeignKey } from "common/types/foreign-key";
import { type Properties } from "common/types/records";
import { VerticalField } from "common/ui/field";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { AlertErrorTip } from "common/widgets/alert";
import { Selector } from "common/widgets/selector";
import { type SelectorOption } from "common/widgets/selector/types";
import { type ValueProps } from "common/with-value-for";
import { getReadOnlyFieldIcon } from "common/form/widget/functions";
import { type FieldRef } from "common/types/html";
import {
  getEmptyOption,
  getLevelQuery,
  isEmptyOption,
  unwrap,
} from "./functions";

interface InternalPropTypes
  extends ValueProps<Properties>,
    FormValidationProps {
  context: Context;
  entity: Entity;
  index: number;
  mainFkColumnName: string;
  targetEntityName: string;
  mappedFields: MappedField[];
  column: EntityColumn;
  previousLvlName: string;
  level: MappedField;
  unwrap: (value: FkValue) => ForeignKey;
  readOnly?: boolean;
  isRestricted?: boolean;
  fieldRef?: FieldRef;
}

interface StateType {
  resolvedValue?: ForeignKey;
  isOptionValid: boolean;
  options: ForeignKey[];
  menuIsOpen: boolean;
  isLoading: boolean;
}

const getFkOptionLabel = (option: SelectorOption<ForeignKey>) =>
  isGroupedOption(option) ? option.label : getSubFKTitle(option.title);

const valueNeedsResolving = (column: EntityColumn, value: keyof Properties) =>
  value && column?.dataType === "fk" && !isForeignKey(value as FkValue);

const getFkResolutionRequest = (
  context: Context,
  relatedEntity: Entity,
  levelValue: any,
) => {
  return searchApi(context.apiCall)
    .runQueryForLookup({
      entity: relatedEntity.name,
      query: addFilterToQuery(
        { name: "id", op: "eq", value: levelValue },
        relatedEntity.query,
      ),
    })
    .then((results: ForeignKey[]) => {
      return results.find((r) => r.id === levelValue);
    });
};

const getMatchingOption = (value: FkValue, options: ForeignKey[]) => {
  const valueId = getFkId(value);
  return valueId ? options?.find((option) => option.id === valueId) : undefined;
};

class InternalLevel extends Component<InternalPropTypes, StateType> {
  drilldownLevelRef = createRef<Selector<ForeignKey>>();
  state: StateType = {
    resolvedValue: undefined,
    isOptionValid: true,
    options: undefined,
    menuIsOpen: undefined,
    isLoading: false,
  };

  componentDidMount() {
    const { value, previousLvlName } = this.props;
    if (!previousLvlName || !!value[previousLvlName]) this.fetchOptions();
  }

  componentDidUpdate(prevProps: InternalPropTypes) {
    if (this.props.index === 0) return;
    const { level, value, onChange, previousLvlName } = this.props;

    const previousChanged =
      value[previousLvlName] !== prevProps.value[previousLvlName];
    if (!previousChanged) return;

    if (value[previousLvlName]) {
      this.fetchOptions().then((options) => {
        if (options.length === 1) {
          // only 1 possible option, set it
          onChange({ ...value, [level.columnName]: options[0] });
        } else if (options.length > 1) {
          this.setState({ menuIsOpen: true });
        }
      });
    } else {
      this.setState({
        options: undefined,
        menuIsOpen: undefined,
        resolvedValue: undefined,
        isOptionValid: true,
      });
    }
  }

  fetchOptions = () => {
    const {
      index,
      targetEntityName,
      mappedFields,
      column,
      context,
      entity,
      value,
      unwrap,
      level: { columnName, targetColumnName },
    } = this.props;

    const query = getLevelQuery(
      entity,
      targetEntityName,
      targetColumnName,
      mappedFields,
      value,
      index,
    );

    this.setState({ isLoading: true });
    return searchApi(context.apiCall)
      .runQueryFkExpansion(query)
      .then((results: Properties[]) => {
        const options: ForeignKey[] = [];

        results.forEach((properties) => {
          const value = properties[targetColumnName];
          // if level is required from entity config and value is null,
          // we filter out the Not Set option
          if (value || !column?.required) {
            options.push(unwrap(value));
          }
        });

        return options;
      })
      .then((options: ForeignKey[]) => {
        const relatedEntity = context.entities[column?.relatedEntity];
        const levelValue = value[columnName];

        const resolutionRequest = valueNeedsResolving(column, levelValue)
          ? getFkResolutionRequest(context, relatedEntity, levelValue)
          : Promise.resolve(undefined);

        return resolutionRequest.then((resolvedValue) => {
          const finalValue = resolvedValue ?? levelValue;
          const isOptionValid =
            !finalValue || !!getMatchingOption(finalValue, options);

          this.setState({
            options,
            resolvedValue,
            isOptionValid,
            isLoading: false,
          });
          return options;
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        throw error;
      });
  };

  onLevelChange = (levelValue: ForeignKey) => {
    const { mappedFields, mainFkColumnName, value, index, level, onChange } =
      this.props;

    const fieldsToReset = mappedFields
      .slice(index + 1)
      .reduce((acc, f) => ({ ...acc, [f.columnName]: undefined }), {});

    onChange({
      ...value,
      ...fieldsToReset,
      [mainFkColumnName]: undefined, // main field/fk cleared too
      [level.columnName]: levelValue,
    });
    this.setState({ resolvedValue: undefined, isOptionValid: true });
  };

  getOptions = () => {
    const { value, level } = this.props;
    const { options, resolvedValue } = this.state;
    const levelValue = value[level.columnName];

    const selected =
      getMatchingOption(levelValue, options) || resolvedValue || levelValue;

    return { selected, options };
  };

  render() {
    const {
      context,
      readOnly,
      isRestricted,
      column,
      fieldRef,
      level: { columnName },
      value,
    } = this.props;
    const { menuIsOpen, isOptionValid, isLoading } = this.state;
    const { options, selected } = this.getOptions();
    const hasError = (column?.required && !selected) || !isOptionValid;

    return (
      <VerticalField
        ref={fieldRef}
        key={columnName}
        label={getLocalizedName(column)}
        error={hasError}
        isRequired={column?.required}
        className={`qa-${columnName}`}
        input={
          <ConditionalRequired
            isRequired={column?.required}
            value={isEmptyOption(selected) ? undefined : selected}
          >
            {readOnly ? (
              <div
                className={classNames([
                  "x-read-only-label-wrapper",
                  hasError ? "x-has-error" : undefined,
                ])}
              >
                <div className="x-read-only-label">
                  <LabelWidget
                    context={context}
                    column={column}
                    value={value[columnName]}
                  />
                </div>
                {isRestricted || column?.userReadOnly ? (
                  <div className="x-read-only-icon">
                    {getReadOnlyFieldIcon(column, isRestricted)}
                  </div>
                ) : undefined}
              </div>
            ) : (
              <Selector
                ref={this.drilldownLevelRef}
                getOptionLabel={getFkOptionLabel}
                allowClear={true}
                options={options}
                isLoading={isLoading}
                menuIsOpen={menuIsOpen}
                value={selected}
                onChange={this.onLevelChange}
              />
            )}
            {!isOptionValid ? (
              <AlertErrorTip
                message={_("The selected value is not a valid option")}
              />
            ) : undefined}
          </ConditionalRequired>
        }
      />
    );
  }
}

interface PropTypes extends ValueProps<Properties>, FormValidationProps {
  context: Context;
  entity: Entity;
  index: number;
  mainFkColumnName: string;
  column: EntityColumn;
  lookupConfiguration: LookupConfiguration;
  readOnly?: boolean;
  fieldRef?: FieldRef;
}

export const DrilldownLevel = ({
  context,
  entity,
  index,
  readOnly,
  mainFkColumnName,
  column,
  lookupConfiguration,
  formValidation,
  onFormValidationChange,
  fieldRef,
  value,
  onChange,
}: PropTypes) => {
  const { targetEntity, mappedFields } = lookupConfiguration;
  const level = mappedFields[index];
  const isRestricted = isRestrictedForRole(column?.roleIds, context.role);
  const unwrapLevel = (value: FkValue) => {
    return value ? unwrap(value, isCustomOrSystemFk(column)) : getEmptyOption();
  };

  return (
    <div className="x-drilldown-level">
      <InternalLevel
        fieldRef={fieldRef}
        context={context}
        entity={entity}
        readOnly={readOnly}
        isRestricted={isRestricted}
        unwrap={unwrapLevel}
        mainFkColumnName={mainFkColumnName}
        column={column}
        targetEntityName={targetEntity}
        index={index}
        mappedFields={mappedFields}
        previousLvlName={mappedFields[index - 1]?.columnName}
        level={level}
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

import * as R from "ramda";
import { Component } from "react";
import { defaultFor } from "common";
import {
  getRelatedEntities,
  isSharedMultipleSitesEntity,
} from "common/entities";
import { Entity } from "common/entities/types";
import { BehaviorOverviewForm } from "common/form/behavior-overview";
import { notHiddenSystemColumn } from "common/form/functions/entity";
import {
  FormValidationProps,
  Layout as FormLayout,
  OverviewPosition,
  RelatedEntity,
} from "common/form/types";
import { merge2, mergeChain } from "common/merge";
import { type FieldRefs } from "common/types/html";
import { RelatedValue } from "common/record/form/content/related/types";
import type { Reload } from "common/record/types";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { GoFn } from "common/types/url";
import { ValueProps } from "common/with-value-for";
import { SitesSelector } from "common/record/form/content/detail/sites-selector";
import { OverviewRelatedRecords } from "./overview-related-records";
import {
  filterWritableRelatedEntities,
  getRelatedEntitiesShowInOverview,
} from "./functions";

interface PropTypes extends ValueProps<RelatedValue>, FormValidationProps {
  layout: FormLayout;
  withLinks: boolean;
  context: Context;
  entity: Entity;
  reload: Reload;
  isTemplate: boolean;
  goTo?: GoFn;
  allowDynamicValues?: boolean;
  fieldRefs?: FieldRefs;
}

export class EditRecord extends Component<PropTypes> {
  static readonly displayName = "EditRecord";

  onChangeRelated = (relatedForm: RelatedValue) => {
    const { value = defaultFor<RelatedValue>(), onChange } = this.props;
    const { partialForm, form } = relatedForm.related;

    onChange(
      mergeChain(value)
        .prop("related")
        .set("partialForm", partialForm)
        .set("form", form)
        .output(),
    );
  };

  onChangeOverviewForm = (formValue: Properties) => {
    const { value = defaultFor<RelatedValue>(), onChange } = this.props;

    onChange(merge2("record", "properties", formValue, value));
  };

  getOverviewRelatedRecordsByPosition = (
    relatedEntities: RelatedEntity[],
    position: OverviewPosition,
  ) => {
    const { context, entity, reload, isTemplate, value, goTo } = this.props;

    const relatedEntitiesForOverview = getRelatedEntitiesShowInOverview(
      relatedEntities,
      context,
      position,
    );

    return relatedEntitiesForOverview?.length > 0 ? (
      <OverviewRelatedRecords
        entity={entity}
        relatedEntitiesInOverview={relatedEntitiesForOverview}
        isTemplate={isTemplate}
        reload={reload}
        value={value}
        goTo={goTo}
        onChange={this.onChangeRelated}
      />
    ) : undefined;
  };

  render() {
    const {
      context,
      entity,
      layout,
      withLinks,
      allowDynamicValues,
      fieldRefs,
      formValidation,
      onFormValidationChange,
      value,
      onChange,
    } = this.props;
    const { record } = value;
    const { relatedEntities } = layout;

    const getAvailableColumns = (entity: Entity) =>
      entity.columns.filter((c) => notHiddenSystemColumn(entity, c.name));

    const showInOverview = (e: Entity) => {
      const related = R.find((r) => r.name === e.name, relatedEntities || []);
      return related && related.showInOverview;
    };

    const layoutRelated =
      relatedEntities ||
      getRelatedEntities(entity, context.entities).map((e: Entity) => ({
        name: e.name,
        columns: getAvailableColumns(e).map((c) => ({
          columnName: c.name,
        })),
        showInOverview: showInOverview(e),
      }));

    const writableRelatedEntities = filterWritableRelatedEntities(
      context,
      entity,
      record,
      layoutRelated,
    );

    const relatedEntitiesForOverviewTop =
      this.getOverviewRelatedRecordsByPosition(writableRelatedEntities, "top");

    const relatedEntitiesForOverviewBottom =
      this.getOverviewRelatedRecordsByPosition(
        writableRelatedEntities,
        "bottom",
      );

    const hideSiteSelector = context.tenant.multiSiteEntity && !record.isNew;
    return (
      <div className="x-record-details-edit">
        {!hideSiteSelector && isSharedMultipleSitesEntity(entity) ? (
          <SitesSelector
            context={context}
            entity={entity}
            value={value}
            onChange={onChange}
          />
        ) : undefined}
        {relatedEntitiesForOverviewTop}
        <BehaviorOverviewForm
          context={context}
          parentEntity={undefined}
          entity={entity}
          layout={layout}
          withLinks={withLinks}
          allowDynamicValues={allowDynamicValues}
          fieldRefs={fieldRefs}
          formValidation={formValidation}
          onFormValidationChange={onFormValidationChange}
          value={record.properties}
          onChange={this.onChangeOverviewForm}
        />
        {relatedEntitiesForOverviewBottom}
      </div>
    );
  }
}

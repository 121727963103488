import * as R from "ramda";
import * as React from "react";
import { Context } from "common/types/context";
import { getLocalizedName } from "common";
import { searchApi } from "common/api/search";
import { isSingleSiteEntity } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { FormValidationProps } from "common/form/types";
import { getAllSingleSites } from "common/functions/sites";
import { ValueComponent } from "common/with-value-for";
import { getSiteLabelByName } from "x/account-settings/sites/functions";
import type { UniqueQueryResult, YourComponentProps } from "./types";
import { withUnique } from "./base";

interface UniqueRunQueryPropTypes {
  recordId: string;
  entityName: string;
  context: Context;
  column: EntityColumn;
  recordSites?: string[];
}

type UniqueRunQueryProps<PropTypes> = PropTypes &
  UniqueRunQueryPropTypes &
  FormValidationProps;

const DEFAULT_PAGE_SIZE = 10;

export function withUniqueRunQuery<PropTypes>(
  YourComponent: React.ComponentType<YourComponentProps<PropTypes>>,
) {
  const UniqueWrapper = withUnique<PropTypes>(YourComponent);

  return class UniqueRunQuery extends ValueComponent<
    any,
    UniqueRunQueryProps<PropTypes>
  > {
    fetchIsUnique = (value: string) => {
      const { column, context, entityName, recordId, recordSites } = this.props;
      const { sites } = context;
      const singleSites = getAllSingleSites(recordSites, sites);

      const entity = context.entities[entityName];
      const applySitesFilter =
        isSingleSiteEntity(entity) &&
        singleSites?.length &&
        (singleSites.length > 1 || singleSites[0] !== context.site.name);

      const equalFilter = { op: "eq", name: column.name, value };
      const recordFilter = { op: "neq", name: "id", value: recordId };
      const sitesFilter = {
        op: "in",
        name: "site",
        value: singleSites.join(","),
      };

      const filter =
        recordId || applySitesFilter
          ? {
              and: [
                equalFilter,
                ...(recordId ? [recordFilter] : []),
                ...(applySitesFilter ? [sitesFilter] : []),
              ],
            }
          : equalFilter;

      return searchApi(context.apiCall)
        .runQuery({
          entity: entityName,
          query: {
            select: [{ name: column.name }],
            filter,
            pageSize: DEFAULT_PAGE_SIZE,
          },
        })
        .then((data: UniqueQueryResult[]) => {
          const isUnique = !(data && data.length);
          const usedInSites = R.uniq(
            data
              ?.filter((d) => !!d.site)
              .map((d) => getSiteLabelByName(sites, d.site)),
          );
          return { isUnique, usedInSites };
        });
    };

    render() {
      const { column, formValidation, onFormValidationChange } = this.props;

      return (
        <UniqueWrapper
          {...this.props}
          useUnique={column.unique}
          validateOnMount={true}
          columName={column.name}
          columnLocalizedName={getLocalizedName(column)}
          formValidation={formValidation}
          onFormValidationChange={onFormValidationChange}
          fetchIsUnique={this.fetchIsUnique}
        />
      );
    }
  };
}

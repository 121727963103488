import { getFkId } from "common/functions/foreign-key";
import { Context } from "common/types/context";
import { Entity } from "common/entities/types";
import { RelatedValue } from "common/record/form/content/related/types";
import { searchApi } from "common/api/search";
import { Query } from "common/query/types";
import { getGroupForSelect } from "common/query/select";
import {
  createDefaultPartLocationQuery,
  createPartLocationLookupQuery,
} from "common/record/form/content/related/part-location/functions";
import { ForeignKey } from "common/types/foreign-key";
import { Properties, Record } from "common/types/records";
import { defaultFor } from "common/index";
import { merge2 } from "common/merge";
import {
  replaceLookupQueries,
  updateContextForms,
} from "common/record/form/content/related/common/functions";
import { updateEntity } from "common/record/form/content/related/purchase-order-item/functions";

export const COMPLETED_STATUS_ID = 3;
export const CANCELED_STATUS_ID = 4;
export const MISMATCHED_STATUS_ID = 6;

type Value = {
  onHand: number;
  finalCount: number;
  cost: number;
  manualBatchSelection: boolean;
  batch: ForeignKey;
};

export const searchDefaultLocation = (
  context: Context,
  entity: Entity,
  value: RelatedValue,
  partId: string,
) =>
  searchApi(context.apiCall)
    .runQuery(createDefaultPartLocationQuery(context, entity, value, partId))
    .then((rs: Properties[] = []) => (rs.length ? rs[0] : undefined));

export const updatePartsToBeAddedContext = (
  context: Context,
  value: RelatedValue,
  entity: Entity = defaultFor<Entity>(),
) => {
  const { entities } = context;
  const updatedContext = merge2(
    "entities",
    entity.name,
    updateEntity(entities[entity.name]),
    context,
  );

  const partLocationLookupQuery = createPartLocationLookupQuery(
    updatedContext,
    entity,
    value,
    true,
  );

  const updateSettings = replaceLookupQueries(
    "partLocationId",
    partLocationLookupQuery,
  );

  return updateContextForms(updatedContext, entity.name, (form) => ({
    ...form,
    settings: updateSettings(form.settings),
  }));
};

export const isValueValid = (value: Value) => {
  const { manualBatchSelection, finalCount, onHand, batch, cost } = value ?? {};

  const hasBatch = !manualBatchSelection || getFkId(batch) !== undefined;
  const needsCost =
    finalCount > onHand && (!manualBatchSelection || getFkId(batch) === "new");

  return !!finalCount && hasBatch && (!needsCost || cost !== undefined);
};

export const isRecordDisabled = (record: Record) =>
  record?.properties?.statusId?.id === COMPLETED_STATUS_ID ||
  record?.properties?.statusId?.id === MISMATCHED_STATUS_ID;

export const showDisabledRecordsWarning = (
  value: RelatedValue,
  entity: Entity,
) => {
  if (!value || !entity) return false;
  const { record } = value;
  const records = record?.related?.[entity.name] ?? [];

  return !record?.isNew && records.some(isRecordDisabled);
};

export const addOnHand = (batchEntity: Entity, query: Query): Query => {
  const group = getGroupForSelect(query.select);

  const select = query.select.concat([
    {
      name: "quantity",
      alias: "onHand",
      path: `/partLocationId/${batchEntity.name}.stockId`,
      fn: "COUNT",
      label: _("On Hand"),
    },
  ]);

  const joins = (query.joins || []).concat([
    {
      column: "partLocationId",
      joins: [
        {
          entity: batchEntity.name,
          column: "stockId",
        },
      ],
    },
  ]);

  return {
    ...query,
    select,
    joins,
    group,
  };
};

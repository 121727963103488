import * as R from "ramda";
import { Entity } from "common/entities/types";
import { merge3 } from "common/merge";
import {
  mapRelatedSummaryToSelect,
  mapSelectToRelatedSummary,
} from "common/query/select";
import { ListPrefs, PreferenceService } from "common/types/preferences";
import { Report } from "common/types/reports";
import { Site } from "common/types/sites";
import { ContentType } from "x/records/list/types";

export const getPreferences = (
  preferenceService: PreferenceService,
  site: Site,
  entity: Entity,
  report: Report,
  scope: string,
) => {
  const filterFn = report
    ? (p: ListPrefs) => p.reportId === report.id
    : entity
      ? (p: ListPrefs) =>
          !p.reportId && p.entity === entity.name && p.scope === scope
      : () => true;
  const listPreferences = preferenceService
    .get()
    .list.find(
      (prefs: ListPrefs) => prefs.site === site.name && filterFn(prefs),
    );

  const lastFilterQuery = listPreferences?.lastFilter?.filter?.query;

  return lastFilterQuery
    ? merge3(
        "lastFilter",
        "filter",
        "query",
        mapRelatedSummaryToSelect(lastFilterQuery),
        listPreferences,
      )
    : listPreferences;
};

export const getPreferencesParams = (
  preferenceService: PreferenceService,
  site: Site,
  entity: Entity,
  report: Report,
  newValue: ContentType,
  scope: string,
) => {
  const prefs = getPreferences(preferenceService, site, entity, report, scope);

  const filter = newValue?.filter;
  const preferred = filter?.id;
  const rawWidths = newValue?.body?.widths || prefs?.widths?.[preferred] || [];

  return {
    prefs,
    filter,
    preferred,
    widths: rawWidths.map(Math.floor),
    output: newValue?.output ?? "Table",
    secondaryQueries: filter?.secondaryQueries,
  };
};

export const setPreferences = (
  preferenceService: PreferenceService,
  site: Site,
  entity: Entity,
  report: Report,
  value: ContentType,
  page: number,
  pageSize: number,
  scope: string,
) => {
  const oldPrefs = preferenceService.get();
  const { prefs, preferred, filter, widths, output, secondaryQueries } =
    getPreferencesParams(preferenceService, site, entity, report, value, scope);

  const lastFilterValue = {
    filter: filter?.query
      ? {
          ...filter,
          secondaryQueries,
          query: mapSelectToRelatedSummary(filter.query),
        }
      : filter,
    widths,
  };

  const newList = oldPrefs.list
    .filter(
      (p) =>
        p.site !== site.name ||
        p.entity !== entity.name ||
        p.scope !== scope ||
        p.reportId !== report?.id,
    )
    .concat([
      {
        site: site.name,
        entity: entity.name,
        lastFilter: lastFilterValue,
        preferred,
        output,
        widths: { ...prefs?.widths, [preferred]: widths },
        page,
        pageSize,
        reportId: report?.id,
        scope,
      },
    ]);

  const newPrefs = { ...oldPrefs, list: newList };

  if (!R.equals(oldPrefs, newPrefs)) {
    preferenceService.set(newPrefs);
  }
};

import { JSX } from "react";
import { StandardValue } from "common/record/types";
import { behaveAs, getRelatedEntities } from "common/entities";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { ExtraValidationResponse } from "common/record/form/form";
import { getLocalizedName } from "common";
import { getRelatedRecordsByRelation } from "../functions";

export const isGroupMemberEntity = (
  mainEntity: Entity,
  relatedEntity: Entity,
) =>
  behaveAs("Group", mainEntity) &&
  behaveAs("GroupMember", relatedEntity) &&
  relatedEntity.arguments.groupEntity === mainEntity.name;

export const validateGroupMemberLength = (
  context: Context,
  entity: Entity,
  value: StandardValue,
): ExtraValidationResponse => {
  const groupMemberEntity = getRelatedEntities(
    entity,
    context.entities,
    (relatedEntity) => isGroupMemberEntity(entity, relatedEntity),
  )?.[0];

  if (!groupMemberEntity) {
    const validationMessage: JSX.Element[] = [
      <span key="missingGroupMemberEntity">
        {_(
          "{ENTITY} does not have Group Member related entity configured.",
        ).replace("{ENTITY}", getLocalizedName(entity))}
      </span>,
    ];

    return {
      isValid: false,
      validationMessage,
    };
  }

  const groupMemberRecords = getRelatedRecordsByRelation(
    entity,
    context.entities,
    value,
    isGroupMemberEntity,
  );

  const validationMessage: JSX.Element[] = !groupMemberRecords.length
    ? [
        <span key="missingGroupMemberRecords">
          {_("{ENTITY} requires {GROUP_MEMBER} related entity to have records.")
            .replace("{ENTITY}", getLocalizedName(entity))
            .replace("{GROUP_MEMBER}", getLocalizedName(groupMemberEntity))}
        </span>,
      ]
    : [];

  return {
    isValid: !!groupMemberRecords.length,
    validationMessage,
  };
};

import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { Filter } from "common/types/filters";

export const filtersApi = (apiCall: ApiCall) => ({
  listForEntity: (entityName: string, site?: string) => {
    const url = !site
      ? `api/ui/filters/${entityName}`
      : `api/ui/filters/${entityName}?site=${site}`;
    return apiCall<Filter[]>("get", url);
  },
  create: (filter: Filter) =>
    apiCall("post", "api/ui/filters", filter).then(
      returnAndNotify(_("The view was successfully created")),
    ),
  update: (filter: Filter) =>
    apiCall("put", `api/ui/filters/${filter.id}`, filter).then(
      returnAndNotify(_("The view was successfully updated")),
    ),
  remove: (id: number) =>
    apiCall("delete", `api/ui/filters/${id}`).then(
      returnAndNotify(_("The view was successfully deleted")),
    ),
});

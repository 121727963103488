import { Component } from "react";
import { defaultFor, getLocalizedName, toKebabCase } from "common";
import { getError } from "common/api/error";
import { workflowsApi } from "common/api/workflows";
import { getByBehaviorArgument } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { setColumnIsValid } from "common/form/functions/validation";
import { FormValidationProps } from "common/form/types";
import { DebounceExpression } from "common/query-builder/expression";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { type FieldRef } from "common/types/html";
import { Properties } from "common/types/records";
import { VerticalField } from "common/ui/field";
import { isNumeric } from "common/utils/number";
import { AlertWarning } from "common/widgets/alert";
import { Float } from "common/widgets/number";
import { ValueProps } from "common/with-value-for";
import { getUpdateSourceFieldsForEntity } from "x/account-settings/workflows/form/actions/functions";

interface PropTypes extends ValueProps<Properties>, FormValidationProps {
  context: Context;
  entity: Entity;
  column: EntityColumn;
  fieldRef: FieldRef;
}

interface StateType {
  error: ApiErrorResponse;
  isExpression: boolean;
}

// @todo Generalize the expression toggle to be used in other places as well (ex. workflows)
export class ExceptionFieldToggle extends Component<PropTypes, StateType> {
  static readonly displayName = "ExceptionFieldToggle";
  state: StateType = {
    error: undefined,
    isExpression: false,
  };

  componentDidMount() {
    const { value, column } = this.props;

    if (
      value?.[column.name] && // checking value as isNumeric assumes empty string to be 0
      !isNumeric(value[column.name])
    )
      this.setState({ isExpression: true });
  }

  onExpressionToggle = () => {
    const { value, column, formValidation, onFormValidationChange, onChange } =
      this.props;
    const { isExpression } = this.state;

    this.setState({ isExpression: !isExpression });
    onFormValidationChange(
      setColumnIsValid(formValidation, column.name, isExpression),
    );
    onChange({
      ...value,
      [column.name]: undefined,
    });
  };

  onExpressionChange = (expression: string = "") => {
    const {
      context,
      column,
      formValidation,
      value,
      onFormValidationChange,
      onChange,
    } = this.props;

    workflowsApi(context.apiCall)
      .validateExpression(expression)
      .then(() => {
        this.setState({ error: undefined });
        onFormValidationChange(
          setColumnIsValid(formValidation, column.name, true),
        );
        onChange({ ...value, [column.name]: expression });
      })
      .catch((error) => {
        this.setState({ error });
        onFormValidationChange(
          setColumnIsValid(formValidation, column.name, false),
        );
        onChange({ ...value, [column.name]: expression });
      });
  };

  onValueChange = (newValue: number) => {
    const { value, column, onChange } = this.props;

    onChange({ ...value, [column.name]: newValue });
  };

  render() {
    const {
      entity,
      context,
      column,
      fieldRef,
      value = defaultFor<Properties>(),
    } = this.props;
    const { isExpression, error } = this.state;
    const { entities, uiFormat } = context;

    const expressionError = isExpression && error ? getError(error) : undefined;
    const expressionClass =
      !value[column.name] || expressionError ? "x-has-error" : "";

    const assetMeterEntity = getByBehaviorArgument(
      entities,
      "AssetMeter",
      "meterEntity",
      entity.arguments.meterEntity,
    );
    const assetEntity = assetMeterEntity.arguments.assetEntity;
    const updateSourceFields = assetEntity
      ? getUpdateSourceFieldsForEntity(entities[assetEntity], entities)
      : undefined;

    return (
      <VerticalField
        ref={fieldRef}
        className={`qa-${toKebabCase(column?.name)}-field`}
        label={getLocalizedName(column)}
        input={
          <>
            <div
              className={
                "x-expression-toggle" +
                (isExpression ? " x-expression-toggle-on" : "")
              }
              title={_("Toggle custom expression")}
              onClick={this.onExpressionToggle}
            >
              <a className="fa fa-code" />
            </div>
            <div className="x-expression-input">
              {isExpression ? (
                <DebounceExpression
                  context={context}
                  className={expressionClass}
                  placeholder={_("Expression is required")}
                  fields={updateSourceFields}
                  onChange={this.onExpressionChange}
                  value={value[column.name] || ""}
                />
              ) : (
                <Float
                  decimalSeparator={uiFormat.decimalSeparator}
                  value={value[column.name]}
                  onChange={this.onValueChange}
                />
              )}
            </div>
            {expressionError ? (
              <AlertWarning
                message={expressionError}
                className="col-md-12 x-expression-error qa-expression-error"
              />
            ) : undefined}
          </>
        }
      />
    );
  }
}

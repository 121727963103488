import { Component, ChangeEvent } from "react";
import { SiteSelector } from "common/widgets/site-selector";
import { defaultFor } from "common";
import { filterRolesByIds } from "common/functions/roles";
import { getSitesDictionary } from "common/functions/sites";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";
import { Role } from "common/types/roles";
import { Site } from "common/types/sites";
import { VerticalField } from "common/ui/field";
import { AlertErrorTip } from "common/widgets/alert";
import { Chicklets } from "common/widgets/chicklets";
import { MaxLength } from "common/widgets/max-length";
import { Required } from "common/widgets/required";
import {
  RestrictToRoles,
  RolesSettings as RolesValue,
} from "x/account-settings/roles/restrict-to-roles";
import { getDisplaySiteFn } from "common/widgets/site-tree-selector-with-search/functions";
import { ValueProps } from "common/with-value-for";
import {
  getSitesOrDefaultToAll,
  isChildSite,
  isGroupSite,
} from "x/account-settings/sites/functions";
import { isUniqueReportName } from "./functions";
import { ReportBuilder } from "./query-builder";
import { RolesWarning } from "./roles/warning";

interface PropTypes extends ValueProps<Report> {
  context: Context;
  roles: Role[];
  reports?: Report[];
}

const MAX_REPORT_NAME_LENGTH = 100;

export const defaultValue = defaultFor<Report>();

export class ReportForm extends Component<PropTypes> {
  static readonly displayName = "ReportForm";

  onRolesChange = (roleValue: RolesValue) => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      ...roleValue,
    });
  };

  onNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = defaultValue, onChange } = this.props;
    const newName = e.target.value;

    onChange({
      ...value,
      name: newName,
      label: newName,
    });
  };

  onChangeSite = (sites: string[] = []) => {
    const { value, onChange } = this.props;

    onChange({ ...value, sites });
  };

  onChangeSitesChicklets = (sites: Site[] = []) => {
    const { value, onChange } = this.props;
    const sitesNames = sites.map((s) => s.name);

    onChange({ ...value, sites: sitesNames });
  };

  render() {
    const {
      context,
      roles = [],
      reports = [],
      value = defaultValue,
      onChange,
    } = this.props;
    const { roleIds = [], isGlobal, sites = [] } = value;
    const { culture } = context.uiFormat;

    const nameLabel =
      value?.labels?.[culture]?.name || value.label || value.name;

    const availableSites = context?.sites;
    const sitesDictionary = getSitesDictionary(availableSites);
    const displaySite = getDisplaySiteFn(sitesDictionary);
    const reportSites = getSitesOrDefaultToAll(sites, availableSites);
    const isNameUnique = isUniqueReportName(culture, value, reports);

    return (
      <div className="qa-report-form">
        <div className="x-section-header row">
          <Required value={nameLabel}>
            <MaxLength
              value={nameLabel}
              maxLength={MAX_REPORT_NAME_LENGTH}
              withHint={true}
              className="x-report-name"
            >
              {/* TODO replace me by a widget */}
              <input
                className="x-section-name col-md-6"
                placeholder={_("Report name")}
                value={nameLabel || ""}
                onChange={this.onNameChanged}
                maxLength={MAX_REPORT_NAME_LENGTH}
              />
              {!isNameUnique ? (
                <AlertErrorTip
                  message={_("A report with this name already exists")}
                />
              ) : undefined}
            </MaxLength>
          </Required>
        </div>
        <ReportBuilder context={context} value={value} onChange={onChange} />
        {availableSites && availableSites.length > 1 ? (
          <>
            <VerticalField
              className="form-group x-flex-grow-1 qa-report-editor-site"
              label={_("Restrict report to specific sites?")}
              input={
                <SiteSelector
                  context={context}
                  sitesToOmit={reportSites}
                  includeGroupSites={false}
                  allowMultipleSelection={true}
                  isExpandable={true}
                  value={sites}
                  onChange={this.onChangeSite}
                />
              }
            />
            <Chicklets<Site>
              className="x-margin-bottom-5 qa-site-chicklets"
              display={displaySite}
              isHighlightedChicklet={isGroupSite}
              canDeleteChicklet={isChildSite}
              value={reportSites}
              onChange={this.onChangeSitesChicklets}
            />
          </>
        ) : undefined}
        <RestrictToRoles
          label={_("Restrict report to specific roles?")}
          roles={roles}
          onChange={this.onRolesChange}
          value={{ isGlobal, roleIds }}
        />
        <RolesWarning
          context={context}
          report={value}
          roles={filterRolesByIds(roles, roleIds)}
        />
      </div>
    );
  }
}

import * as R from "ramda";
import { Component } from "react";
import { getTimestamp } from "common/date-time/common";
import { filterByBehavior } from "common/entities";
import { isRestrictedForRole } from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { isDirty, isNew } from "common/functions/standard-value";
import { merge1, merge2, merge3, merge4 } from "common/merge";
import { RecordContentForm } from "common/record/form/form";
import { isStandardUiValue, StandardValue } from "common/record/types";
import { pseudoCancellable } from "common/types/promises";
import { Record } from "common/types/records";
import { DeleteWarning } from "common/widgets/warning/delete-warning";
import { ValueProps } from "common/with-value-for";
import { PropTypes } from "x/records/edit/types";
import { READONLY_PO_STATUS, DISABLED_PO_STATUS } from "x/records/edit/consts";

export const getNewPONumber = () => `PO${getTimestamp()}`;

type Props = PropTypes & ValueProps<StandardValue>;

interface StateType {
  showConfirmation?: boolean;
  onApprove?: () => void;
  onReject?: () => void;
}

export class PurchaseOrderForm extends Component<Props, StateType> {
  static readonly displayName = "PurchaseOrderForm";
  state: StateType = {
    showConfirmation: false,
    onApprove: undefined,
    onReject: undefined,
  };

  onChange = (newValue: StandardValue) => {
    this.props.onChange(
      this.hasSupplierChanged(newValue)
        ? this.getEventAfterSupplierChange(newValue)
        : newValue,
    );
  };

  hasItems = (newValue: StandardValue) => {
    const { context } = this.props;
    const { entities } = context;
    const relatedEntitiesNames = this.getPOItemEntitiesNames(entities);
    const allSavedRelated = newValue?.record?.related || {};
    const allEditedRelated = newValue?.ui?.related?.form || {};
    const relatedSavedPOI = R.pick(relatedEntitiesNames, allSavedRelated);
    const relatedEditedPOI = R.pick(relatedEntitiesNames, allEditedRelated);

    const hasSavedPOI = R.values(relatedSavedPOI).some((records: Record[]) => {
      return records && records.length > 0;
    });
    const hasEditedPOI = R.values(relatedEditedPOI).some(
      (records: Record[]) => {
        return records && records.length > 0;
      },
    );

    return hasSavedPOI || hasEditedPOI;
  };

  hasSupplierChanged = (newValue: StandardValue) => {
    const { value } = this.props;
    if (!isStandardUiValue(newValue) || !isStandardUiValue(value)) return event;

    const oldForm = value?.ui?.detail?.form;
    const newForm = newValue?.ui?.detail?.form;
    const oldSupplierId = oldForm?.supplierId?.id;
    const newSupplierId = newForm?.supplierId?.id;

    return oldSupplierId && newSupplierId && oldSupplierId !== newSupplierId;
  };

  getPOItemEntitiesNames = (entities: Entities) =>
    R.values(filterByBehavior("PurchaseOrderItem", entities)).map(
      (item: Entity) => item.name,
    );

  removeRelatedPOItems = (
    newValue: StandardValue,
    relatedEntitiesNames: string[],
  ) => {
    const { value } = this.props;

    const savedRelated = value?.record?.related || {};
    const valueWithoutSaved = merge2(
      "record",
      "related",
      R.omit(relatedEntitiesNames, savedRelated),
      newValue,
    );

    const editedRelated = valueWithoutSaved?.ui?.related?.form || {};
    return merge3(
      "ui",
      "related",
      "form",
      R.omit(relatedEntitiesNames, editedRelated),
      valueWithoutSaved,
    );
  };

  removeRelatedPartialForms = (
    newValue: StandardValue,
    relatedEntitiesNames: string[],
  ) => {
    const partialForms = newValue?.ui?.related?.partialForm;
    const newPartialForms = R.omit(relatedEntitiesNames, partialForms);
    return merge3("ui", "related", "partialForm", newPartialForms, newValue);
  };

  setRelatedDirty = (newValue: StandardValue) =>
    merge3("ui", "related", "isDirty", true, newValue);

  clearSavedSupplier = (newValue: StandardValue) =>
    merge3("record", "properties", "supplierId", undefined, newValue);

  getEventAfterSupplierChange = (newValue: StandardValue): StandardValue => {
    const { context } = this.props;
    const { entities } = context;
    const relatedEntitiesNames = this.getPOItemEntitiesNames(entities);

    const withoutRelated = this.removeRelatedPOItems(
      newValue,
      relatedEntitiesNames,
    );
    const withoutPartialForms = this.removeRelatedPartialForms(
      withoutRelated,
      relatedEntitiesNames,
    );
    const withoutSupplier = this.clearSavedSupplier(withoutPartialForms);
    return this.setRelatedDirty(withoutSupplier);
  };

  updateValue = () => {
    const { value } = this.props;
    const form = value?.ui?.detail?.form;
    return isNew(value) && form && !isDirty(value)
      ? merge4(
          "ui",
          "detail",
          "form",
          "purchaseOrderNumber",
          getNewPONumber(),
          value,
        )
      : value;
  };

  approveChange = () =>
    pseudoCancellable(
      new Promise<void>((resolve, reject) => {
        const onApprove = () => {
          this.setState({ showConfirmation: false });
          resolve();
        };
        const onReject = () => {
          this.setState({ showConfirmation: false });
          reject();
        };
        this.setState({ showConfirmation: true, onApprove, onReject });
      }),
    );

  getApproveChange = (column: EntityColumn) => {
    const { value } = this.props;
    return column.name === "supplierId" && this.hasItems(value)
      ? this.approveChange
      : undefined;
  };

  getReadOnly = (statusId: number, column: EntityColumn) => {
    return (
      (R.includes(statusId, READONLY_PO_STATUS) &&
        column.name !== "comments") ||
      column.readOnly ||
      column.userReadOnly ||
      isRestrictedForRole(column.roleIds, this.props.context.role)
    );
  };

  updateEntity = () => {
    const { entity } = this.props;
    if (!entity) return entity;
    const statusId = this.getCurrentStatusId();
    const newColumns = entity.columns.map((column: EntityColumn) => {
      return {
        ...column,
        readOnly: this.getReadOnly(statusId, column),
        approveChange: this.getApproveChange(column),
        canAutoPopulationOverwrite: true,
      };
    });
    return merge1("columns", newColumns, entity);
  };

  updateDisableEdit = () => {
    const { disableEdit } = this.props;
    return (
      R.includes(this.getCurrentStatusId(), DISABLED_PO_STATUS) || !!disableEdit
    );
  };

  getCurrentStatusId = () => {
    return this.props.value?.record?.properties?.statusId?.id;
  };

  render() {
    const { showConfirmation, onApprove, onReject } = this.state;
    return (
      <>
        <RecordContentForm
          {...this.props}
          entity={this.updateEntity()}
          disableEdit={this.updateDisableEdit()}
          value={this.updateValue()}
          onChange={this.onChange}
        />
        {showConfirmation ? (
          <DeleteWarning
            confirmationTitle={_(
              "All included parts will be removed from this Purchase Order",
            )}
            confirmationLabel={_("Yes")}
            onCancel={onReject}
            onDelete={onApprove}
          />
        ) : undefined}
      </>
    );
  }
}

import { getFormTemporaryIdsMapper } from "common/record/form/functions";
import { behaveAs } from "common/entities";
import { getRecordDependencies } from "common/record/dependencies";
import { DependenciesComp } from "common/with-dependencies";
import { MapEditController } from "x/interactive-maps/edit-controller";
import { PropTypes } from "x/records/edit-controller/types";
import { RecordEditControllerBehavior } from "./edit-controller-behavior";

export const RecordEditControllerIndex = (props: PropTypes) => {
  const { context, entity, formId, defaultProperties, copySettings, isNew } =
    props;

  if (behaveAs("Map", entity)) return <MapEditController {...props} />;

  const defaultValue = getRecordDependencies(
    context,
    entity,
    isNew,
    defaultProperties,
    formId,
    undefined,
    undefined,
    copySettings,
  );

  return (
    <DependenciesComp
      dependencies={{ defaultValue }}
      mapDependencies={
        isNew ? getFormTemporaryIdsMapper(entity, defaultProperties) : undefined
      }
      child={<RecordEditControllerBehavior {...props} />}
    />
  );
};

RecordEditControllerIndex.displayName = "RecordEditControllerIndex";

import { redirect } from "common/record/actions/functions/url";
import { DismissAction, PropTypes } from "common/record/actions/types";
import { Create } from "common/record/actions/ui/create";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { getFkFromProperties } from "common/utils/foreign-key";
import { withValue, WithValue } from "common/with-value";

export interface FollowUpValue {
  formId: number;
}

type Props = PropTypes & WithValue<FollowUpValue>;

export const CreateFollowUp = withValue<FollowUpValue, PropTypes>(
  (props: Props) => {
    const {
      sourceEntity,
      entity,
      records = [],
      context,
      value,
      extraProperties,
      onPerform,
      onChangeSetValue,
      goTo,
    } = props;
    const properties = records[0]?.properties;

    const onOk = (_: ApiCall, dismiss: DismissAction) => {
      onPerform?.();

      const extraPropertiesWithParent = {
        parentId: getFkFromProperties(entity, properties),
        ...extraProperties,
      };
      redirect(context, goTo, entity, value?.formId, {
        copyType: "follow-up",
        sourceEntity: sourceEntity?.name,
        sourceId: properties?.id,
        extraProperties: extraPropertiesWithParent,
      });
      return CancellablePromise.resolve(dismiss);
    };

    const actionProps = {
      hideRecordNumber: true,
      title: _("Create Follow-up"),
      onOk,
    };

    return (
      <Create
        {...props}
        actionProps={actionProps}
        value={value}
        onChange={onChangeSetValue}
      />
    );
  },
  "CreateFollowUp",
);

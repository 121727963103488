import { Component } from "react";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { merge1 } from "common/merge";
import { createUniqueAssetsQuery } from "common/query/behaviors/assets";
import { Entity } from "common/entities/types";
import { RequiredField } from "common/ui/required-field";
import { filterLayout } from "common/form/functions/layout";
import { redirect } from "common/record/actions/functions/url";
import { AdvancedSingleRecordSelector } from "common/widgets/record-selector";
import { Properties } from "common/types/records";
import { QueryForEntity } from "common/query/types";
import { ValueProps } from "common/with-value-for";
import { RelatedValue, CustomConfig, CustomConfigSettings } from "../types";

interface PropTypes extends ValueProps<Properties> {
  context: Context;
  assetsEntity: Entity;
  query: QueryForEntity;
}

class WorkOrderAssetsStatus extends Component<PropTypes> {
  onAssetChange = (assetId: ForeignKey) => {
    this.props.onChange({ ...this.props.value, assetId });
  };

  render() {
    const { context, assetsEntity, value, query } = this.props;

    return (
      <div className="x-section x-work-order-assets">
        <RequiredField
          label={_("Asset")}
          value={value.assetId}
          input={
            <AdvancedSingleRecordSelector
              context={context}
              entity={assetsEntity}
              query={query}
              withLinks={true}
              type="dropdown"
              value={value.assetId}
              onChange={this.onAssetChange}
            />
          }
        />
      </div>
    );
  }
}

export const workOrderAssetRef: CustomConfig<RelatedValue> = ({
  context,
  parentEntity,
  relatedEntity,
  value: { record, related },
  goTo,
}): CustomConfigSettings<RelatedValue> => {
  const { name } = parentEntity;
  const { entities, forms } = context;
  const assetsEntity = entities[parentEntity.arguments.assetEntity];
  const assetsQuery = createUniqueAssetsQuery(
    assetsEntity,
    relatedEntity,
    record,
    related,
  );

  return {
    defaultForm: undefined,
    layoutMapper: (l) =>
      filterLayout(
        (c) => c.columnName !== "assetId" && c.columnName !== "status",
        l,
      ),
    custom: (value, onChange) =>
      assetsEntity ? (
        <WorkOrderAssetsStatus
          context={context}
          assetsEntity={assetsEntity}
          query={assetsQuery}
          value={value.id ? value : merge1("status", "P", value)}
          onChange={onChange}
        />
      ) : (
        <div>
          {_("The ENTITY entity is not accessible").replace(
            "ENTITY",
            parentEntity.arguments.assetEntity,
          )}
        </div>
      ),
    customOnChange: undefined,
    actions: [
      {
        name: "CreateWorkOrder",
        fn: (properties) => {
          redirect(
            context,
            goTo,
            parentEntity,
            forms.find((f) => f.entityName === name)?.id,
            {
              copyType: "create",
              sourceEntity: relatedEntity?.name,
              sourceId: properties.id,
            },
          );
        },
      },
    ],
  };
};

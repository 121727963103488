import { isTypeWithoutValues } from "common";
import { CancellablePromise } from "common/types/promises";
import { Value, UniqueValue, type UniqueResult } from "./types";

const isUniqueObject = isTypeWithoutValues<Value, UniqueValue>([
  "value",
  "pending",
  "duplicated",
]);

export const wrapWithUniqueResult =
  (
    originalFn: (value: string) => CancellablePromise<boolean>,
  ): ((value: string) => CancellablePromise<UniqueResult>) =>
  (value: string) =>
    originalFn(value).then((isUnique) => ({ isUnique }));

export const getValueFromUniqueValue = (v: any) =>
  isUniqueObject(v) ? v.value : v;

export const getUniqueErrorMessage = (column: string, sites: string[]) =>
  sites?.length
    ? _("This {COLUMN} already exists for sites: {SITES}")
        .replace("{COLUMN}", column)
        .replace("{SITES}", sites.join(", "))
    : _("This {COLUMN} already exists").replace("{COLUMN}", column);

import * as React from "react";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import { ValueProps } from "common/with-value-for";
import {
  Secondaries,
  QueryForEntity,
  isDerivedColumn,
} from "common/query/types";
import { Checkbox } from "common/widgets/checkbox/checkbox";
import { ColumnDefinition } from "../advanced-types";
import { getDefinitionLabel } from "../common";
import { Column, Props as ColumnProps } from "./column";
import {
  ColumnDraggable,
  ColumnDraggableValue,
} from "./column/column-draggable";
// eslint-disable-next-line import/no-cycle
import { Filter } from "./filter";
import { RelatedGridFilter } from "./filter/related";

export interface HeaderValue {
  selectAll: boolean;
  query: QueryForEntity;
  widths: number[];
  secondaryQueries: Secondaries;
}

const defaultQuery = defaultFor<QueryForEntity>();

const defaultValue: HeaderValue = {
  selectAll: false,
  query: defaultQuery,
  widths: [],
  secondaryQueries: undefined,
};

export interface BasicPropTypes {
  columnDefinitions: ColumnDefinition[];
  context: Context;
  hasActions: boolean;
  allowSelect: boolean;
}

interface PropTypes extends BasicPropTypes {
  allowFilter: boolean;
  allowDrag: boolean;
  allowOrder: boolean;
  hasSelectAction: boolean;
  toggleOrder: () => void;
}

interface StateType {
  firstRowHeight: number;
}

export type Props = PropTypes & ValueProps<HeaderValue>;

const toNumber = (value: boolean) => (value ? 1 : 0);

export class Header extends React.Component<Props, StateType> {
  static readonly displayName = "Header";
  firstRowRef: React.RefObject<HTMLTableRowElement> = React.createRef();
  state: StateType = {
    firstRowHeight: undefined,
  };

  componentDidMount() {
    this.setState({ firstRowHeight: this.getFirstRowHeight() });
  }

  componentDidUpdate() {
    const { firstRowHeight } = this.state;
    const height = this.getFirstRowHeight();

    if (firstRowHeight !== height) {
      this.setState({ firstRowHeight: height });
    }
  }

  // can be removed when chrome and other browsers fix support for sticky on thead, see #1471
  getFirstRowHeight = () => this.firstRowRef?.current?.clientHeight;

  getDynamicStyle = (): React.CSSProperties => {
    const { firstRowHeight } = this.state;
    return firstRowHeight ? { top: `${firstRowHeight}px` } : undefined;
  };

  onChangeSecondaryQueries = (secondaryQueries: Secondaries) => {
    const { value, onChange } = this.props;
    onChange({ ...value, secondaryQueries });
  };

  onChangeQuery = (query: QueryForEntity) => {
    const { value, onChange } = this.props;
    onChange({ ...value, query });
  };

  onChangeSelectAll = (selectAll: boolean) => {
    const { value, onChange } = this.props;
    onChange({ ...value, selectAll });
  };

  getRowFilters = () => {
    const {
      context,
      allowFilter,
      allowSelect,
      columnDefinitions = [],
      hasActions,
      value = defaultValue,
    } = this.props;

    if (!allowFilter) return null;

    const cellStyle = this.getDynamicStyle();

    const select = allowSelect ? <td style={cellStyle} /> : undefined;

    const actionsColumn = hasActions ? <td style={cellStyle} /> : undefined;

    const rowFilters = columnDefinitions.map((col, i) => (
      <td key={i} className={`qa-inline-${col.valueKey}`} style={cellStyle}>
        {col.isRelatedEntity ? (
          <RelatedGridFilter
            context={context}
            entityName={col && col.entity}
            value={value.secondaryQueries}
            onChange={this.onChangeSecondaryQueries}
          />
        ) : (
          <Filter
            columnDefinition={col}
            context={context}
            value={value.query}
            onChange={this.onChangeQuery}
          />
        )}
      </td>
    ));

    return (
      <tr key="rowFilter" className="x-table-filter-row">
        {select}
        {actionsColumn}
        {rowFilters}
      </tr>
    );
  };

  onDragColumnChange = (column: ColumnDraggableValue) => {
    const { value, onChange } = this.props;
    const { query, widths } = column;
    onChange({ ...value, query, widths });
  };

  getHeader = () => {
    const {
      context,
      allowDrag,
      allowOrder,
      allowSelect,
      columnDefinitions = [],
      value = defaultValue,
      hasActions,
    } = this.props;
    const { query, widths } = value;

    const offset = toNumber(allowSelect) + toNumber(hasActions);

    return columnDefinitions.map((def, i) => {
      const colProps: ColumnProps = {
        column: def?.column?.name,
        queryColumn: def.item,
        value: query,
        onChange: this.onChangeQuery,
        allowOrder: !!allowOrder,
        label: getDefinitionLabel(context.entities, query.entity, def),
        hasEmphasis: def.hasEmphasis,
        relatedEntityName: def.isRelatedEntity ? def.entity : undefined,
      };

      return !isDerivedColumn(colProps.queryColumn) && allowDrag ? (
        <ColumnDraggable
          key={i}
          index={i}
          columnDefinitions={columnDefinitions}
          offset={offset}
          column={colProps}
          value={{ query, widths }}
          onChange={this.onDragColumnChange}
        />
      ) : (
        <Column key={i} {...colProps} />
      );
    });
  };

  render() {
    const {
      allowSelect,
      hasActions,
      hasSelectAction,
      toggleOrder,
      value = defaultValue,
    } = this.props;

    const selectColumn = allowSelect ? (
      <th key="allowSelect" className="x-table-select-all">
        <Checkbox value={value.selectAll} onChange={this.onChangeSelectAll} />
      </th>
    ) : undefined;

    const actionsColumn = hasActions ? (
      <th className="x-actions-column-header">
        {toggleOrder ? (
          <div
            className="x-align-center x-pointer x-padding-5"
            onClick={toggleOrder}
          >
            <i className="fa fa-exchange fa-rotate-90" />
          </div>
        ) : undefined}
        {hasSelectAction ? (
          <div className="x-padding-5">Select</div>
        ) : undefined}
      </th>
    ) : undefined;

    const header = this.getHeader();
    const rowFilters = this.getRowFilters();

    return (
      <thead>
        <tr ref={this.firstRowRef} className="x-table-header-row">
          {selectColumn}
          {actionsColumn}
          {header}
        </tr>
        {rowFilters}
      </thead>
    );
  }
}

import { useEffect } from "react";
import { rolesApi } from "common/api/roles";
import { hasPermissionToRead } from "common/functions/roles";
import { Context } from "common/types/context";
import { Filter } from "common/types/filters";
import { Site } from "common/types/sites";
import { VerticalField } from "common/ui/field";
import { findRoot } from "common/utils/tree";
import { Checkbox } from "common/widgets/checkbox/checkbox";
import { InputWithSubmit } from "common/widgets/input-with-submit";
import { Required } from "common/widgets/required";
import { SitesSelectorWithChicklets } from "common/widgets/site-selector/with-chicklets";
import { ValueProps } from "common/with-value-for";
import {
  RestrictToRoles,
  RolesSettings,
} from "x/account-settings/roles/restrict-to-roles";
import { SaveFilterState } from "x/records/list/types";

interface PropTypes extends ValueProps<Filter> {
  saveFilterState: SaveFilterState;
  lastFilterName: string;
  filters: Filter[];
  context: Context;
  canCreateShared: boolean;
  onSave: () => void;
}

const isDuplicateFilterName = (
  isNewFilter: boolean,
  lastFilterName: string,
  filters: Filter[] = [],
  name: string = "",
) =>
  filters.some((f) => f.name.toLowerCase() === name.toLowerCase()) &&
  (isNewFilter || lastFilterName !== name);

const getDefaultSite = (activeSite: Site, userSite: Site) => {
  return userSite.isGroup ? [activeSite.name] : [userSite.name];
};

export const FilterContentForm = ({
  saveFilterState,
  lastFilterName,
  filters,
  context,
  canCreateShared,
  value,
  onChange,
  onSave,
}: PropTypes) => {
  const { apiCall, userTypes, role, site } = context;
  const {
    name,
    shared,
    sites = [],
    roleIds,
    isGlobal = !roleIds?.length,
  } = value;
  const userSite = findRoot(context.sites);

  useEffect(() => {
    if (shared && !sites.length && saveFilterState === "new") {
      onChange({
        ...value,
        sites: getDefaultSite(site, userSite),
      });
    }
  }, []);

  const onNameChange = (name: string) => {
    onChange({ ...value, name });
  };

  const onSharedChange = (shared: boolean) => {
    onChange({
      ...value,
      shared,
      ...(shared
        ? {
            sites: getDefaultSite(site, userSite),
          }
        : {
            isGlobal: true,
            roleIds: undefined,
            sites: undefined,
          }),
    });
  };

  const onSitesChange = (sites: string[]) => {
    onChange({ ...value, sites });
  };

  const onRolesChange = (rolesSettings: RolesSettings) => {
    const { isGlobal, roleIds } = rolesSettings;
    onChange({ ...value, isGlobal, roleIds });
  };

  const duplicatedName = isDuplicateFilterName(
    saveFilterState === "new",
    lastFilterName,
    filters,
    name,
  );

  return (
    <>
      <VerticalField
        key="name-input"
        label={_("View name")}
        error={!name || duplicatedName}
        input={
          <InputWithSubmit
            inputId="name-input"
            className="x-input-text qa-filter-name-input"
            hasError={duplicatedName}
            errorMessage={_("A View with this name already exists.")}
            value={name}
            onSubmit={onSave}
            onChange={onNameChange}
          />
        }
      />
      {canCreateShared ? (
        <div>
          <label key="shared-input" className="qa-label-checkbox">
            <Checkbox
              className="qa-filter-shared-input"
              value={shared}
              onChange={onSharedChange}
            />
            <span className="x-margin-left-5">
              {_("Shared (anyone can see this view)")}
            </span>
          </label>
          {shared && (
            <div className="x-margin-top-10">
              {userSite.isGroup || !sites.length ? (
                <VerticalField
                  label={_("Select Site")}
                  input={
                    <Required value={sites.length > 0 ? sites : undefined}>
                      <SitesSelectorWithChicklets
                        context={context}
                        placeholder={_("Select")}
                        value={sites}
                        onChange={onSitesChange}
                      />
                    </Required>
                  }
                />
              ) : undefined}
              <RestrictToRoles
                label={_("Restrict view to specific roles?")}
                fetchRoles={
                  hasPermissionToRead(userTypes, role, "Roles") &&
                  rolesApi(apiCall).list
                }
                onChange={onRolesChange}
                value={{ isGlobal, roleIds }}
              />
            </div>
          )}
        </div>
      ) : undefined}
    </>
  );
};

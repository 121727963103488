import { Component } from "react";
import { mediaApi } from "common/api/media";
import { returnAndNotify } from "common/api/with-notifications";
import { workflowsMediaApi } from "common/api/workflows-media";
import { setColumnIsValid } from "common/form/functions/validation";
import { FormValidationProps } from "common/form/types";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { isWorkflowsUsageArea } from "common/widgets/functions";
import type { UsageArea } from "common/widgets/types";
import type { ValueProps } from "common/with-value-for";
import { ImageWithUpload } from ".";

interface PropTypes extends FormValidationProps {
  context: Context;
  entityName: string;
  columnName?: string;
  recordId: string;
  isDefault: boolean;
  allowClear: boolean;
  isLarge?: boolean;
  alt?: string;
  usageArea?: UsageArea;
}

type Props = PropTypes & ValueProps<string>;

export class ImageWithUploadController extends Component<Props> {
  static readonly displayName = "ImageWithUploadController";

  uploadRequest: CancellablePromise<unknown>;
  uploadAndSetAsDefaultRequest: CancellablePromise<unknown>;
  setAsDefaultRequest: CancellablePromise<unknown>;

  componentWillUnmount() {
    this.uploadRequest?.cancel();
    this.uploadAndSetAsDefaultRequest?.cancel();
    this.setAsDefaultRequest?.cancel();
  }

  uploadImage = (file: FileType) => {
    const {
      context,
      entityName,
      columnName,
      recordId,
      formValidation,
      isDefault,
      usageArea,
      onFormValidationChange,
      onChange,
    } = this.props;
    const { apiCall, site } = context;

    if (columnName) {
      onFormValidationChange?.(
        setColumnIsValid(formValidation, columnName, false, true),
      );
    }

    // Marks the form as dirty for the time when the file is being uploaded
    onChange(undefined);

    const uploadPromise = isWorkflowsUsageArea(usageArea)
      ? workflowsMediaApi(apiCall).uploadAndGetUrl(recordId, file)
      : isDefault
        ? mediaApi(apiCall).uploadAndSetAsDefault(
            site.name,
            entityName,
            recordId,
            file,
          )
        : mediaApi(apiCall).uploadAndGetUrl(
            site.name,
            entityName,
            recordId,
            file,
          );

    this.uploadRequest = uploadPromise.then((filePath) => {
      onChange(filePath);
      if (columnName) {
        onFormValidationChange?.(
          setColumnIsValid(formValidation, columnName, true, false),
        );
      }
    });

    return this.uploadRequest;
  };

  unSetDefault = () => {
    const { context, entityName, recordId, onChange } = this.props;

    this.setAsDefaultRequest = mediaApi(context.apiCall)
      .setAsDefault(entityName, recordId, null)
      .then(() => onChange(undefined))
      .catch(() => returnAndNotify(_("We could not unset the default image")));

    return this.setAsDefaultRequest;
  };

  removeImage = () => {
    const { isDefault, usageArea, onChange } = this.props;
    if (isWorkflowsUsageArea(usageArea)) return;
    isDefault ? this.unSetDefault() : onChange(undefined);
  };

  render() {
    const { context, allowClear, isLarge, alt, value, onChange } = this.props;

    return (
      <ImageWithUpload
        context={context}
        allowClear={allowClear}
        onUpload={this.uploadImage}
        onRemove={this.removeImage}
        isLarge={isLarge}
        alt={alt}
        value={value}
        onChange={onChange}
      />
    );
  }
}

import * as R from "ramda";
import { useEffect, useState } from "react";
import { defaultFor } from "common";
import {
  filterRolesByIds,
  getFullPermissionRoleIds,
  isRoleWithFullPermissions,
} from "common/functions/roles";
import { CancellablePromise } from "common/types/promises";
import { Role } from "common/types/roles";
import { VerticalField } from "common/ui/field";
import { createList } from "common/widgets/fancy-checkbox/list";
import { LoadingIcon } from "common/widgets/loading-icon";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { ValueProps } from "common/with-value-for";

export interface RolesSettings {
  isGlobal: boolean;
  roleIds: number[];
}

interface PropTypes extends ValueProps<RolesSettings> {
  label: string;
  roles?: Role[];
  fetchRoles?: () => CancellablePromise<Role[]>;
}

const List = createList<Role>();
const defaultValue = defaultFor<RolesSettings>();

export const RestrictToRoles = (props: PropTypes) => {
  const { value = defaultValue, label, fetchRoles } = props;
  const { isGlobal, roleIds = [] } = value;

  const [roles, setRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.roles) {
      setRoles(props.roles);
      return;
    }
    if (fetchRoles) {
      setIsLoading(true);
      fetchRoles()
        .then((roles) => {
          setRoles(roles);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  const onChange = (value: Role[]) => {
    const { onChange } = props;
    const roleIds = (value || []).map((r) => r.id);
    onChange({ ...props.value, roleIds });
  };

  const onToggle = (isRestricted: boolean) => {
    const { onChange } = props;
    const isGlobal = !isRestricted;
    onChange({
      ...props.value,
      isGlobal,
      roleIds: isRestricted ? getFullPermissionRoleIds(roles) : undefined,
    });
  };

  const roleInput = (
    <List
      display={R.prop("name")}
      isDisabled={isRoleWithFullPermissions}
      items={roles}
      value={filterRolesByIds(roles, roleIds)}
      onChange={onChange}
    />
  );

  if (isLoading) return <LoadingIcon />;
  if (roles.length === 0) return null;
  return (
    <div className="qa-restrict-to-roles">
      <VerticalField
        label={label}
        input={<YesNo name="isGlobal" onChange={onToggle} value={!isGlobal} />}
      />
      {!isGlobal ? (
        <VerticalField
          label={_("Select one or more roles")}
          input={roleInput}
        />
      ) : undefined}
    </div>
  );
};

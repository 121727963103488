import { ChangeEvent } from "react";
import { defaultFor } from "common";
import { validatePassword } from "common/api/authentication/user";
import { getError } from "common/api/error";
import { Culture } from "common/culture/supported-cultures";
import { RecoverLink } from "common/explicit-auth/forgot-password";
import { useResetEmail } from "common/explicit-auth/use-reset-email";
import { HorizontalField, Section } from "common/form/ui";
import { hasPermissionToUpdate } from "common/functions/roles";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { PasswordPolicy } from "common/types/settings";
import { UserContactInfo } from "common/types/user-contact-info";
import { isVendorUserType } from "common/types/users";
import { UiFormat } from "common/types/ui-format";
import { showErrorNotification } from "common/ui/notification";
import { email } from "common/validate";
import { AlertWarning } from "common/widgets/alert";
import { Email } from "common/widgets/email";
import { ImageWithUpload } from "common/widgets/image-with-upload";
import { StringInput } from "common/widgets/input-with-submit/string";
import { ValueProps } from "common/with-value-for";
import { T1000 } from "common/widgets/text";
import { LazyPhone } from "common/widgets/phone";
import { CultureSettings } from "x/culture/culture-settings";
import { UserDetails } from "../types";

export type UploadAvatar = (file: FileType) => CancellablePromise<any>;
export type RemoveAvatar = () => void;

interface PropTypes extends ValueProps<UserDetails> {
  cultures: Culture[];
  passwordPolicy: PasswordPolicy;
  uploadAvatar: UploadAvatar;
  removeAvatar: RemoveAvatar;
  context: Context;
}

const passwordsMatch = (value: UserDetails): boolean =>
  value?.password === value.confirmPassword;

export const validDetails = (
  value: UserDetails,
  passwordPolicy: PasswordPolicy,
): boolean =>
  value &&
  email(value.userName) &&
  !validatePassword(passwordPolicy, value.password, value.userName) &&
  passwordsMatch(value);

export const Details = ({
  value = defaultFor<UserDetails>(),
  context,
  uploadAvatar,
  removeAvatar,
  cultures = [],
  passwordPolicy,
  onChange,
}: PropTypes) => {
  const {
    name,
    image,
    userName,
    currentPassword,
    password,
    confirmPassword,
    uiFormat,
    userContactInfo,
  } = value;
  const { firstName, lastName } = userContactInfo ?? {};

  const { resetPasswordError, isSent, onSendClick } = useResetEmail(context);

  const onImageNotFound = () => {
    onChange({ ...value, image: undefined });
  };

  const onChangeFor = (field: keyof UserDetails) => (newValue: string) => {
    onChange({
      ...value,
      [field]: newValue,
    });
  };

  const onUserContactInfoChangeFor =
    (field: keyof UserContactInfo) => (newValue: string) => {
      onChange({
        ...value,
        userContactInfo: {
          ...value.userContactInfo,
          [field]: newValue,
        },
      });
    };

  const onCurrentPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      currentPassword: e.target.value,
    });
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, password: e.target.value });
  };

  const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      confirmPassword: e.target.value,
    });
  };

  const onUiFormatChange = (uiFormat: UiFormat) => {
    onChange({ ...value, uiFormat });
  };

  const currentPasswordError =
    !currentPassword && (password || confirmPassword);

  const passwordPolicyError = validatePassword(
    passwordPolicy,
    password,
    userName,
  );

  const passwordConfirmError = confirmPassword && !passwordsMatch(value);
  const canUpdate = hasPermissionToUpdate(
    context.userTypes,
    context.role,
    "ProfileSettings",
  );
  const isVendor = isVendorUserType(context.userTypes);
  const isFeatureEnabled = context.isEnabledFeature("masterUserContacts");
  return (
    <div className="x-user-profile-details">
      <div className="row">
        <div className="col-md-3 col-lg-2 x-image-uploader-wrapper">
          <ImageWithUpload
            context={context}
            allowClear={true}
            alt={_("User Avatar")}
            isLarge={true}
            onUpload={uploadAvatar}
            onRemove={removeAvatar}
            onError={onImageNotFound}
            value={image}
            onChange={onChangeFor("image")}
          />
        </div>
        <div className="row col-md-9 col-lg-10">
          <div className="col-md-6">
            <Section legend={_("Personal Details")} className="x-section">
              <HorizontalField
                className="qa-name"
                label={_("Name")}
                disabled={!canUpdate || isVendor}
              >
                <StringInput
                  maxLength={255}
                  placeholder={_("Name")}
                  value={name || ""}
                  onChange={onChangeFor("name")}
                />
              </HorizontalField>
              {isFeatureEnabled && (
                <>
                  <HorizontalField
                    className="qa-full-name"
                    label={_("First Name")}
                    disabled={!canUpdate || isVendor}
                  >
                    <StringInput
                      maxLength={128}
                      value={firstName}
                      onChange={onUserContactInfoChangeFor("firstName")}
                    />
                  </HorizontalField>
                  <HorizontalField
                    className="qa-full-name"
                    label={_("Last Name")}
                    disabled={!canUpdate || isVendor}
                  >
                    <StringInput
                      maxLength={128}
                      value={lastName}
                      onChange={onUserContactInfoChangeFor("lastName")}
                    />
                  </HorizontalField>
                </>
              )}
              <HorizontalField
                className="qa-email"
                label={_("Email (Username)")}
                disabled={!canUpdate || isVendor}
                error={!userName || !email(userName)}
              >
                <Email
                  value={userName}
                  validate={true}
                  onChange={onChangeFor("userName")}
                />
              </HorizontalField>
            </Section>
          </div>
          <div className="col-md-6">
            <Section legend={_("Security")} className="x-section">
              {context.isPasswordExpired && (
                <AlertWarning
                  message={_(
                    "Your eMaint X5 password has expired. Please update your password and click save. An updated password is required for continued use of your account.",
                  )}
                />
              )}
              <div className="x-confirmPassword-wrapper">
                {passwordPolicyError && (
                  <span className="x-confirmPassword-no-match">
                    {passwordPolicyError}
                  </span>
                )}
                <HorizontalField label={_("Password")}>
                  <form>
                    {" "}
                    {/* needs form tag for autoComplete to work ATM */}
                    {/* TODO create a password widget */}
                    <input
                      type="password"
                      className="qa-password"
                      placeholder={_("New password")}
                      autoComplete="off"
                      value={password || ""}
                      onChange={onPasswordChange}
                    />
                  </form>
                </HorizontalField>
              </div>
              <div className="x-confirmPassword-wrapper">
                {passwordConfirmError && (
                  <span className="x-confirmPassword-no-match">
                    {_("Passwords do not match")}
                  </span>
                )}
                <HorizontalField label={_("Confirm password")}>
                  <form>
                    {" "}
                    {/* needs form tag for autoComplete to work ATM */}
                    {/* TODO create a password widget */}
                    <input
                      type="password"
                      className="qa-confirm-password"
                      placeholder={_("Confirm new password")}
                      autoComplete="off"
                      value={confirmPassword || ""}
                      onChange={onConfirmPasswordChange}
                    />
                  </form>
                </HorizontalField>
              </div>
              <div className="x-confirmPassword-wrapper">
                {currentPasswordError && (
                  <span className="x-confirmPassword-no-match">
                    {_("Current password is required")}
                  </span>
                )}
                <HorizontalField label={_("Current password")}>
                  <form>
                    {" "}
                    {/* needs form tag for autoComplete to work ATM */}
                    {/* TODO create a password widget */}
                    <input
                      type="password"
                      className="qa-password"
                      placeholder={_("Current password")}
                      autoComplete="off"
                      value={currentPassword || ""}
                      onChange={onCurrentPasswordChange}
                    />
                  </form>
                </HorizontalField>
                <RecoverLink onSendClick={onSendClick} isSent={isSent} />
                {resetPasswordError &&
                  showErrorNotification(getError(resetPasswordError))}
              </div>
            </Section>
          </div>
          <div className="col-md-6">
            <Section legend={_("Company")} className="x-section">
              <HorizontalField
                className="qa-primary-location"
                label={_("Primary Location")}
                disabled={!canUpdate}
              >
                <StringInput
                  maxLength={255}
                  placeholder={_("Primary Location")}
                  value={userContactInfo?.location}
                  onChange={onUserContactInfoChangeFor("location")}
                />
              </HorizontalField>
              <HorizontalField
                className="qa-job-title"
                label={_("Job Title")}
                disabled={!canUpdate}
              >
                <StringInput
                  maxLength={255}
                  placeholder={_("Job Title")}
                  value={userContactInfo?.jobTitle}
                  onChange={onUserContactInfoChangeFor("jobTitle")}
                />
              </HorizontalField>
              <HorizontalField
                className="qa-department"
                label={_("Department")}
                disabled={!canUpdate}
              >
                <StringInput
                  maxLength={255}
                  placeholder={_("Department")}
                  value={userContactInfo?.department}
                  onChange={onUserContactInfoChangeFor("department")}
                />
              </HorizontalField>
              <HorizontalField label={_("Phone Number")} disabled={!canUpdate}>
                <LazyPhone
                  value={userContactInfo?.phone}
                  onChange={onUserContactInfoChangeFor("phone")}
                />
              </HorizontalField>
              <HorizontalField
                label={_("Business Address")}
                disabled={!canUpdate}
              >
                <T1000
                  placeholder={_("Business Address")}
                  value={userContactInfo?.businessAddress}
                  onChange={onUserContactInfoChangeFor("businessAddress")}
                />
              </HorizontalField>
            </Section>
          </div>
          <div className="col-md-6">
            <Section className="x-section" legend={_("Preferences")}>
              <CultureSettings
                cultures={cultures}
                value={uiFormat}
                onChange={onUiFormatChange}
              />
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
};

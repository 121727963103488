import * as R from "ramda";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import {
  dataSourceValues,
  translateDataSource,
} from "common/functions/data-source";
import { displaySite, getNonGroupSiteNames } from "common/functions/sites";
import { getIntFkId } from "common/functions/system-int";
import { ProcedureTypes } from "common/types/procedure-type";
import { Properties } from "common/types/records";
import { Site } from "common/types/sites";
import { SystemIntFk } from "common/types/system-int";
import {
  COMPLETED_PROCEDURE_STATUS_ID,
  FAIL_PROCEDURE_STATUS_ID,
  NOT_APPLICABLE_PROCEDURE_STATUS_ID,
  NO_PROCEDURE_STATUS_ID,
  PASS_PROCEDURE_STATUS_ID,
  URGENT_REPAIR_PROCEDURE_STATUS_ID,
  YES_PROCEDURE_STATUS_ID,
} from "common/types/system-strings";

interface SystemString {
  options: string[];
  translate: (s: string) => string;
}

export interface MappedSystemIntFkOptions {
  options: SystemIntFk[];
  translate: (s: SystemIntFk) => string;
}

export const dataSource: SystemString = {
  options: dataSourceValues.map((value) => value.toString()),
  translate: translateDataSource,
};

export const assignmentStatus: SystemString = {
  options: ["N", "P"],
  translate: (value: string) => {
    switch (value) {
      case "N":
        return _("Not Published");
      case "P":
        return _("Published");
      default:
        return value;
    }
  },
};

export const translateCycleCountStatus = (value: string) => {
  switch (value) {
    case "Open":
      return _("Open");
    case "Additional Count Required":
      return _("Additional Count Required");
    case "Completed":
      return _("Completed");
    case "Mismatched":
      return _("Mismatched");
    default:
      return value;
  }
};

// TODO remove me after translations come from api
export const getPurchaseOrderStatus = (value: string) => {
  switch (value) {
    case "Open":
      return _("Open");
    case "Waiting For Approval":
      return _("Waiting For Approval");
    case "Waiting For Parts":
      return _("Waiting For Parts");
    case "Closed":
      return _("Closed");
    case "Rejected":
      return _("Rejected");
    case "Cancelled":
      return _("Cancelled");
    default:
      return value;
  }
};

// TODO remove me after translations come from api
export const getPurchaseOrderItemStatus = (value: string) => {
  switch (value) {
    case "Not Received":
      return _("Not Received");
    case "Fully Received":
      return _("Fully Received");
    case "Partially Received":
      return _("Partially Received");
    default:
      return value;
  }
};

// TODO remove me after translations come from api
export const getPartRequisitioningStatus = (value: string) => {
  switch (value) {
    case "Open":
      return _("Open");
    case "Awaiting Approval":
      return _("Awaiting Approval");
    case "Approved":
      return _("Approved");
    case "Rejected":
      return _("Rejected");
    case "In Purchasing":
      return _("In Purchasing");
    case "Under Review":
      return _("Under Review");
    default:
      return value;
  }
};

// TODO remove me after translations come from api
export const getPartRequisitioningItemStatus = (value: string) => {
  switch (value) {
    case "Awaiting Approval":
      return _("Awaiting Approval");
    case "Approved":
      return _("Approved");
    case "Rejected":
      return _("Rejected");
    default:
      return value;
  }
};

export const translateProcedureType = (value: string) => {
  switch (value) {
    case "Completable":
      return _("Completable");
    case "Pass / Fail / Urgent Repair":
      return _("Pass / Fail / Urgent Repair");
    case "Yes / No / NA":
      return _("Yes / No / NA");
    case "MeterReading":
      return _("Meter Reading");
    default:
      return value;
  }
};

export const translateProcedureStatus = (value: string) => {
  switch (value) {
    case "Pass":
      return _("Pass");
    case "Fail":
      return _("Fail");
    case "Urgent Repair":
      return _("Urgent Repair");
    case "Yes":
      return _("Yes");
    case "No":
      return _("No");
    case "Not Applicable":
      return _("Not Applicable");
    case "Meter Reading Provided":
      return _("Meter Reading Provided");
    default:
      return _("Completed");
  }
};

// TODO remove me after translations come from api
export const getMeterType = (value: string) => {
  switch (value) {
    case "Total":
      return _("Total");
    case "Running":
      return _("Running");
    case "Trending":
      return _("Trending");
    default:
      return value;
  }
};

export const translateEntityColumnType = (value: string) => {
  switch (value) {
    case "System Field":
      return _("System Field");
    case "Custom Field":
      return _("Custom Field");
    case "Standard Field":
      return _("Standard Field");
    case "Recommended Field":
      return _("Recommended Field");
    default:
      return value;
  }
};

// TODO remove me after translations come from api
export const translateDomainLoginType = (value: string) => {
  switch (value) {
    case "Based on Company Email Domain":
      return _("Based on Company Email Domain");
    case "Based on Pre-Existing User":
      return _("Based on Pre-Existing User");
    default:
      return value;
  }
};

export const requestStatus: SystemString = {
  options: ["O", "X", "A"],
  translate: (value: string) => {
    switch (value) {
      case "O":
        return _("Open");
      case "X":
        return _("Rejected");
      case "A":
        return _("Approved");
      default:
        return value;
    }
  },
};

export const workOrderStatusDictionary = (): { [index: string]: string } => ({
  C: _("Completed"),
  F: _("Future"),
  H: _("Closed"),
  O: _("Open"),
});

export const workOrderStatus: SystemString = {
  options: Object.keys(workOrderStatusDictionary()),
  translate: (value: string) => workOrderStatusDictionary()[value] ?? value,
};

export const workOrderAssetStatus: SystemString = {
  options: ["P", "S", "F"],
  translate: (value: string) => {
    switch (value) {
      case "P":
        return _("Pending");
      case "S":
        return _("Success");
      case "F":
        return _("Fail");
      default:
        return value;
    }
  },
};

export const scheduledEventType: SystemString = {
  options: ["P", "I", "E", "C"],
  translate: (value: string) => {
    switch (value) {
      case "P":
        return _("Preventive Maintenance");
      case "I":
        return _("Inspection Round");
      case "E":
        return _("External Trigger");
      case "C":
        return _("Condition Monitoring");
      default:
        return value;
    }
  },
};

export const projectionIntervalType: SystemString = {
  options: ["d"],
  translate: (value: string) => {
    switch (value) {
      case "d":
        return _("Days");
      default:
        return value;
    }
  },
};

const getStatusesByProcedureType = (
  columnOptions: SystemIntFk[],
  properties: Properties,
): SystemIntFk[] => {
  const procedureTypeId = getIntFkId(properties?.procedureTypeId);

  switch (procedureTypeId) {
    case ProcedureTypes.Completable:
      return columnOptions.filter(
        (option) => option.id === COMPLETED_PROCEDURE_STATUS_ID,
      );

    case ProcedureTypes.PassFailUrgentRepair:
      return columnOptions.filter(
        (option) =>
          option.id === PASS_PROCEDURE_STATUS_ID ||
          option.id === FAIL_PROCEDURE_STATUS_ID ||
          option.id === URGENT_REPAIR_PROCEDURE_STATUS_ID,
      );

    case ProcedureTypes.YesNoNotApplicable:
      return columnOptions.filter(
        (option) =>
          option.id === YES_PROCEDURE_STATUS_ID ||
          option.id === NO_PROCEDURE_STATUS_ID ||
          option.id === NOT_APPLICABLE_PROCEDURE_STATUS_ID,
      );

    default:
      return columnOptions;
  }
};

export const getProcedureStatus = (
  columnOptions: SystemIntFk[],
  dependencies: Properties,
): MappedSystemIntFkOptions => ({
  options: getStatusesByProcedureType(columnOptions, dependencies),
  translate: (value?: SystemIntFk) => translateProcedureStatus(value?.title),
});

export const requisitioningItemStatus = (
  columnOptions: SystemIntFk[],
): MappedSystemIntFkOptions => ({
  options: columnOptions.filter(
    (o) => o.id !== 1 || o.title !== "Awaiting Approval",
  ),
  translate: (value: SystemIntFk) =>
    getPartRequisitioningItemStatus(value?.title),
});

const meterType = (columnOptions: SystemIntFk[]): MappedSystemIntFkOptions => ({
  options: columnOptions,
  translate: (value: SystemIntFk) => getMeterType(value?.title),
});

export const getSystemString = (entity: Entity, columnName: string) => {
  if (columnName === "dataSource") return dataSource;
  if (columnName === "status") {
    if (behaveAs("Assignment", entity)) return assignmentStatus;
    if (behaveAs("Request", entity)) return requestStatus;
    if (behaveAs("WorkOrder", entity)) return workOrderStatus;
    if (behaveAs("WorkOrderAsset", entity)) return workOrderAssetStatus;
  }
  if (behaveAs("ScheduledEvent", entity)) {
    if (columnName === "projectionInterval") return projectionIntervalType;
    if (columnName === "type") return scheduledEventType;
  }
  return undefined;
};

export const getSiteSystemString = (sites: Site[]): SystemString => ({
  options: getNonGroupSiteNames(sites),
  translate: displaySite(sites),
});

export const getMappedSystemIntFkOptions = (
  options: SystemIntFk[],
  entity: Entity,
  name: string,
  dependencies?: Properties,
) => {
  if (
    name === "statusId" &&
    !!options &&
    behaveAs("RequisitioningItem", entity)
  ) {
    return requisitioningItemStatus(options);
  }

  if (name === "meterTypeId" && !!options) {
    if (behaveAs("Meter", entity)) return meterType(options);
  }
  if (name === "procedureStatusId" && behaveAs("Procedure", entity)) {
    return getProcedureStatus(options, dependencies);
  }

  return undefined;
};

export const getSystemStringTranslate = (
  entity: Entity,
  columnName: string,
) => {
  const ss = getSystemString(entity, columnName);
  return ss ? ss.translate : R.identity;
};

import { BehaviorName } from "common/types/behaviors";
import { Properties } from "common/types/records";
import { behaveAs, getRelatedEntities } from "common/entities";
import { Entities } from "common/entities/types";
import { getRulesMapper } from "common/query/filter";
import { mergeJoins } from "common/query/joins";
import {
  ActionsByRecordId,
  isFieldRule,
  Query,
  QueryForEntity,
} from "common/query/types";
import { RGBColor } from "common/types/colors";
import { Context } from "common/types/context";
import { Filter, defaultFilter } from "common/types/filters";
import { hasPermissionTo } from "common/functions/roles";
import { Action } from "common/types/roles";
import { getSelectFieldByTitle } from "common/query/select";
import { getRuleLabel } from "x/account-settings/color-coding/functions";
import { hasSecondaryQueries } from "x/records/list-controller/functions/query";
import { ContentType } from "x/records/list/types";

export interface ColorLegendItem {
  label: string;
  color: RGBColor;
}

export interface ColorLegendGroup {
  label?: string;
  items: ColorLegendItem[];
}

export const getFiltersPermissions = (
  context: Context,
  actions: Action[] = [],
) => {
  const { userTypes, role } = context;
  return actions.reduce((acc: any, action: Action) => {
    const actionKey = `can${action}`;
    return {
      ...acc,
      [actionKey]: hasPermissionTo(userTypes, role, "Filters", action),
    };
  }, {});
};

export const getFilterById = (filters: Filter[] = [], id: number) =>
  filters.find((f) => f.id === id);

export const getColorLegendGroupsByBehavior = (
  behavior: BehaviorName,
  context: Context,
): ColorLegendGroup[] => {
  const { entities, uiFormat } = context;

  return Object.values(entities).reduce((groups, entity) => {
    const { colorRules, labels } = entity;

    if (!colorRules?.length || !behaveAs(behavior, entity)) return groups;

    return [
      ...groups,
      {
        label: labels[uiFormat.culture].name,
        items: colorRules
          .map((rule) => ({
            label: getRuleLabel(context, rule),
            color: rule.color,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      },
    ];
  }, []);
};

export const getRecordsWithActions = (
  recordsProperties: Properties[],
  actionsByRecordId: ActionsByRecordId,
  allowedActions?: string[],
) =>
  recordsProperties?.map((properties: Properties) => {
    const recordActions = actionsByRecordId?.[properties.id] || [];
    return {
      actions: allowedActions
        ? recordActions.filter((a) => allowedActions.includes(a))
        : recordActions,
      properties,
    };
  });

const getQueryWithRelatedFilterForExport = (
  value: Filter,
  entities: Entities,
  entityName: string,
): QueryForEntity => {
  const query = value.query;
  const secondaryQueries = value.secondaryQueries;

  const relatedEntities = getRelatedEntities(entities[entityName], entities);

  const newQueryParts = Object.keys(secondaryQueries).reduce(
    (acc, relatedEntityName) => {
      const secondary = secondaryQueries[relatedEntityName];

      if (!secondary) return acc;

      const relatedEntity = relatedEntities.find(
        (e) => e.name === relatedEntityName,
      );
      const relatedPath = `/${relatedEntity.name}.${relatedEntity.ownerFkColumn}`;
      const getFilterWithRelatedPaths = getRulesMapper((rule) =>
        rule && isFieldRule(rule)
          ? { ...rule, path: `${relatedPath}${rule.path ?? ""}` }
          : undefined,
      );

      const titleField = getSelectFieldByTitle(secondary.select);
      const selectWithRelatedPaths = {
        ...titleField,
        path: `${relatedPath}${titleField.path || ""}`,
        alias: relatedEntityName,
      };
      const groupsWithRelatedPaths = {
        name: titleField.name,
        path: `${relatedPath}${titleField.path || ""}`,
      };

      return {
        joins: [
          ...acc.joins,
          {
            column: relatedEntity.ownerFkColumn,
            entity: relatedEntity.name,
            joins: secondary.joins,
          },
        ],
        groups: [
          ...acc.groups,
          { name: relatedEntity.ownerFkColumn, path: relatedPath },
          groupsWithRelatedPaths,
        ],
        filters: [...acc.filters, getFilterWithRelatedPaths(secondary.filter)],
        select: [...acc.select, selectWithRelatedPaths],
      };
    },
    { joins: [], groups: [], filters: [], select: [] },
  );

  return {
    entity: entityName,
    query: {
      ...query,
      joins: mergeJoins(newQueryParts.joins, query.joins),
      group: [...(query?.group ?? []), ...newQueryParts.groups],
      filter: {
        and: [query?.filter, ...newQueryParts.filters].filter((f) => !!f),
      },
      select: [...query.select, ...newQueryParts.select],
    },
  };
};

export const getQueryForExport = (
  value: ContentType,
  entities: Entities,
  entityName: string,
  query: Query,
): QueryForEntity => {
  const filter = value.filter || defaultFilter;
  const listQuery = hasSecondaryQueries(value)
    ? getQueryWithRelatedFilterForExport(filter, entities, entityName)
    : { entity: entityName, query: query };
  return listQuery;
};

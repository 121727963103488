import { PropTypes } from "x/records/edit-controller/types";
import { FormWithStar } from "../edit/with-star";
import { GenericRecordDetailController } from "./generic-record-detail-controller";

export const RecordEditController = (props: PropTypes) => {
  const {
    id,
    onPreSave,
    context,
    entity,
    formId,
    goTo,
    hideRibbon,
    disableEdit,
    eventId,
    auditTrailId,
    withLinks,
    onHasChanged,
    isNew,
    mapLayout,
    hidePanels,
    canDelete,
    canRestore,
    forceFetch,
    onCancel,
    onDelete,
    onNotFound,
    onRecordChanged,
    onRestore,
    onSave,
    skipDeleteConfirmation,
    unwrap,
    wrap,
    dependencies,
    needSaveConfirmation,
    saveConfirmationMessage,
    crumbs,
    getUrl,
    displaySidebar,
    displayHeader,
    // unused:
    // confirmationTitle,
    // scheduledAssetIds // seems to be used only up in the tree
  } = props;

  return (
    <GenericRecordDetailController
      confirmationTitle={_("Related records will be archived")}
      context={context}
      entity={entity}
      id={id}
      isNew={isNew}
      dependencies={dependencies}
      onPreSave={onPreSave}
      formId={formId}
      canDelete={canDelete}
      canRestore={canRestore}
      forceFetch={forceFetch}
      onCancel={onCancel}
      onDelete={onDelete}
      onHasChanged={onHasChanged}
      onNotFound={onNotFound}
      onRecordChanged={onRecordChanged}
      onRestore={onRestore}
      onSave={onSave}
      skipDeleteConfirmation={skipDeleteConfirmation}
      unwrap={unwrap}
      wrap={wrap}
      needSaveConfirmation={needSaveConfirmation}
      saveConfirmationMessage={saveConfirmationMessage}
    >
      <FormWithStar
        // injected by GenericRecordDetailController
        runQuery={undefined}
        // injected by GenericRecordDetailController => ... => base
        deleting={undefined}
        loading={undefined}
        onChange={undefined}
        onDelete={undefined}
        reload={undefined}
        save={undefined}
        saving={undefined}
        value={undefined}
        id={undefined}
        onCancel={undefined}
        // injected by with-star, should not be here
        starred={undefined}
        toggleStar={undefined}
        //
        goTo={goTo}
        isNew={isNew}
        eventId={eventId}
        auditTrailId={auditTrailId}
        withLinks={withLinks}
        context={context}
        entity={entity}
        hidePanels={hidePanels}
        onHasChanged={onHasChanged}
        hideRibbon={hideRibbon}
        disableEdit={disableEdit}
        mapLayout={mapLayout}
        crumbs={crumbs}
        getUrl={getUrl}
        deleteLabels={{
          idle: _("Archive"),
          loading: _("Archiving"),
        }}
        layout={undefined} // not used, injected by FormWithLayout
        displaySidebar={displaySidebar}
        displayHeader={displayHeader}
      />
    </GenericRecordDetailController>
  );
};

RecordEditController.displayName = "RecordEditController";

import * as R from "ramda";
import {
  Children,
  cloneElement,
  Component,
  PropsWithChildren,
  ReactElement,
} from "react";
import { defaultFor } from "common";
import { isCancelError } from "common/api/error";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { dataDog } from "common/monitoring/datadog";
import { Crud, RequestOptions } from "common/types/api";
import {
  CancellablePromise,
  pseudoCancellable,
  Reject,
  Resolve,
} from "common/types/promises";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Warning } from "common/widgets/warning";
import { DeleteWarning } from "common/widgets/warning/delete-warning";
import { ResolvedDependencies } from "common/with-dependencies";
import { ChildPropTypes } from "./types";

export interface Injected<TValue, TId, TRecord> extends ChildPropTypes<TValue> {
  onChange: (value: TValue) => any;
  initialValue: TValue;
  value: TValue;
  save: (record: TRecord, confirmDelete: boolean) => CancellablePromise<any>;
  id: TId;
  deleteWarningMessage?: string;
}

export function createInjected<TValue, TId, TRecord>() {
  return defaultFor<Injected<TValue, TId, TRecord>>();
}

type WrapRecord<TValue, TRecord> = (
  value: TValue,
  record: TRecord,
  deps: ResolvedDependencies,
) => TValue;

type UnwrapRecord<TValue, TRecord> = (value: TValue) => TRecord;

export interface ExternalPropTypes<TId, TRecord>
  extends PropsWithChildren<unknown> {
  id: TId;
  isNew: boolean;
  onNotFound?: () => any;
  onPreSave?: (
    record: TRecord,
    initialRecord: TRecord,
  ) => CancellablePromise<TRecord>;
  onSave?: (id: TId) => void;
  onDelete?: () => any;
  onCancel?: () => any;
  canRestore?: (record: TRecord) => boolean;
  onRestore?: () => any;
  restoring?: boolean;
  canDelete?: (record: TRecord) => boolean;
  needSaveConfirmation?: boolean;
  skipDeleteConfirmation?: boolean;
  onHasChanged?: (isDirty: boolean) => any;
  onRecordChanged?: (newRecord: TRecord, oldRecord: TRecord) => void;
  entity?: Entity;
  forceFetch?: () => boolean;
}

export interface PropTypesBase<TValue, TId, TRecord>
  extends ExternalPropTypes<TId, TRecord> {
  api: Crud<TId, TRecord>;
  dependencies?: ResolvedDependencies;
  defaultValue?: TValue;
  dontLoad?: boolean;
  /**
   * Mapping function to transform any data from parent into form value.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  wrapRecord: WrapRecord<TValue, TRecord>;
  /**
   * Mapping function to transform any form value back to a record.
   */
  unwrapRecord: UnwrapRecord<TValue, TRecord>;
}

interface PropTypes<TValue, TId, TRecord>
  extends PropTypesBase<TValue, TId, TRecord> {
  canDo: (what: string) => boolean;
  confirmationTitle?: string;
  confirmationLabel?: string;
  passError?: (error: any) => boolean;
  deleteWarningMessage?: string;
  saveConfirmationMessage?: string;
}

interface StateType<TValue> {
  error: any;
  initialized: boolean;
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  noRender: boolean;
  initialItem: TValue;
  item: TValue;
  confirmDelete: Resolve<unknown>;
  cancelDelete: Reject;
  confirmSave: boolean;
  saveConfirmedCallback?: () => void;
}

export class EditControllerBase<TValue, TId, TRecord> extends Component<
  PropTypes<TValue, TId, TRecord>,
  StateType<TValue>
> {
  state: StateType<TValue> = {
    initialized: false,
    loading: false,
    saving: false,
    deleting: false,
    noRender: false,
    item: undefined,
    error: undefined,
    initialItem: undefined,
    confirmDelete: undefined,
    cancelDelete: undefined,
    confirmSave: false,
    saveConfirmedCallback: undefined,
  };
  fetchRecordRequest: CancellablePromise<unknown>;

  componentDidMount() {
    this.loadItem();
  }

  componentDidUpdate(prevProps: PropTypes<TValue, TId, TRecord>) {
    const { id, forceFetch } = this.props;

    if (prevProps.id !== id || (forceFetch && forceFetch())) {
      this.loadItem();
    }
  }

  componentWillUnmount() {
    this.fetchRecordRequest?.cancel();
  }

  /**
   * @param [hideLoading] - Suppress loading
   * @param [recordId] - Record ID to force page reload for a new record.
   */
  reload = (hideLoading: boolean = false, recordId?: TId) => {
    const props = recordId ? { ...this.props, id: recordId } : this.props;
    if (props.id) {
      this.fetchRecord(props, this.state.item, !hideLoading);
    }
  };

  handleOnNotFound = (onNotFound: () => any) => {
    this.setState({ noRender: true, initialized: true });
    onNotFound();
  };

  handleItemNotFound = () => {
    this.setState({ error: { status: 404 }, initialized: true });
  };

  handleDontLoad = (item: any) => {
    const { onNotFound, id } = this.props;
    const itemNotFound = !item && id;

    if (itemNotFound) {
      if (onNotFound) {
        this.handleOnNotFound(onNotFound);
      } else {
        this.handleItemNotFound();
      }
    } else {
      this.setState({ item, initialized: true });
    }
  };

  handleCaptureException = (errorMessage: string) => {
    dataDog.logCustomError(new Error(errorMessage));

    this.setState({ noRender: true, initialized: true });
  };

  loadItem = () => {
    const { id, dontLoad, defaultValue, dependencies, isNew } = this.props;
    const item = this.state.item || dependencies?.defaultValue || defaultValue;

    if (dontLoad) {
      this.handleDontLoad(item);
    } else if (isNew) {
      this.setState({ item, initialized: true });
    } else if (id) {
      this.fetchRecord(this.props, item);
    } else {
      this.handleCaptureException(
        "No ID provided for existing record edit form",
      );
    }
  };

  fetchRecord = (
    props: PropTypes<TValue, TId, TRecord>,
    item: TValue,
    loading: boolean = true,
  ) => {
    const { id, api, wrapRecord, onRecordChanged, dependencies, onNotFound } =
      props;

    this.setState({ loading });

    this.fetchRecordRequest = api
      .get(id)
      .then((record) => {
        if (!record) {
          this.handleCaptureException(`Base controller record is falsy: ${id}`);
          return;
        }

        const wrappedRecord = wrapRecord(item, record, dependencies);

        this.setState({
          initialItem: wrappedRecord,
          item: wrappedRecord,
          loading: false,
          initialized: true,
        });

        if (onRecordChanged) onRecordChanged(record, undefined);
      })
      .catch((error) => {
        const isNotFoundError = onNotFound && error?.status === 404;

        if (isNotFoundError) {
          this.handleOnNotFound(onNotFound);
        } else {
          this.setState({ loading: false, error, initialized: true });
        }
      });
  };

  saveFromState = () => {
    const { item } = this.state;
    const { unwrapRecord } = this.props;
    this.setState({ saving: true, error: undefined });
    return this.save(unwrapRecord(item), false);
  };

  save = (
    record: TRecord,
    useConfirmDelete: boolean,
    requestOptions?: RequestOptions,
  ) => {
    this.state.saveConfirmedCallback &&
      this.setState({ saveConfirmedCallback: undefined });
    return useConfirmDelete
      ? this.confirmDelete().then(() => this.doSave(record, requestOptions))
      : this.doSave(record, requestOptions);
  };

  doSave = (record: TRecord, requestOptions?: RequestOptions) => {
    const {
      api,
      onHasChanged,
      id,
      entity,
      unwrapRecord,
      isNew,
      onPreSave,
      onSave,
    } = this.props;
    const { item, initialItem = defaultFor<TValue>() } = this.state;

    this.setState({ saving: true, error: undefined });

    // TODO: RTS
    const recordToSave =
      entity && behaveAs("ScheduledEvent", entity)
        ? unwrapRecord(item)
        : record;

    const promise = onPreSave
      ? onPreSave(recordToSave, unwrapRecord(initialItem))
      : CancellablePromise.resolve(recordToSave);

    return promise
      .then((r) =>
        isNew ? api.create(r, requestOptions) : api.update(r, requestOptions),
      )
      .then((r) => {
        this.setState({ saving: false });
        const recordId = r?.id || id;

        if (onHasChanged) onHasChanged(false); // reset dirty flag

        /**
         * The page should be reloaded after save. However, for chained operations,
         * like save -> submit, we have to prevent reload on save, and call it
         * manually after submit to avoid components unmount and breaking the chain.
         */
        if (!requestOptions?.preventReloadAfterSave && onSave) onSave(recordId);

        return recordId;
      })
      .catch((error) => {
        this.setState({
          saving: false,
          error: isCancelError(error) ? undefined : error,
        });
        if (onHasChanged) onHasChanged(false);

        /**
         * Returning `CancellablePromise.reject` to allow for components
         * calling save function to react on saving error.
         */
        return CancellablePromise.reject(error);
      });
  };

  confirmDelete = () => {
    return pseudoCancellable(
      new Promise((resolve, reject) => {
        this.setState({
          confirmDelete: resolve,
          cancelDelete: reject,
        });
      })
        .then(this.resetConfirmDelete)
        .catch((error) => {
          this.resetConfirmDelete();
          throw error;
        }),
    );
  };

  remove = () => {
    const { skipDeleteConfirmation } = this.props;

    return skipDeleteConfirmation
      ? this.doDelete()
      : this.confirmDelete().then(this.doDelete);
  };

  doDelete = () => {
    const { api, id, onDelete } = this.props;
    this.setState({ deleting: true, error: undefined });

    return api
      .remove(id)
      .then(() => {
        if (onDelete) onDelete();
        this.setState({ deleting: false });
      })
      .catch((error) => {
        this.setState({
          deleting: false,
          error: isCancelError(error) ? undefined : error,
        });
      });
  };

  onDeleteConfirmed = () => {
    const { confirmDelete } = this.state;
    if (confirmDelete) confirmDelete();
  };

  onDeleteCancelled = () => {
    const { cancelDelete } = this.state;
    if (cancelDelete) cancelDelete();
  };

  saveWithConfirmation = () => {
    this.setState({ confirmSave: true });
    return CancellablePromise.resolve();
  };

  onSaveConfirmed = () => {
    const { saveConfirmedCallback } = this.state;
    this.setState({ confirmSave: false });
    saveConfirmedCallback ? saveConfirmedCallback() : this.saveFromState();
  };

  onSaveCancelled = () => {
    this.setState({ confirmSave: false, saveConfirmedCallback: undefined });
  };

  onChange = (value: TValue) => {
    const { unwrapRecord, onHasChanged, onRecordChanged } = this.props;
    const { item } = this.state;
    const before = item && unwrapRecord(item);
    const after = unwrapRecord(value);
    if (onHasChanged && !R.equals(before, after)) onHasChanged(true);
    this.setState({ item: value });
    if (onRecordChanged) onRecordChanged(after, before);
  };

  saveWithCallBackAndConfirmation = (
    record: TRecord,
    useConfirmDelete: boolean,
    requestOptions?: RequestOptions,
  ) => {
    this.setState({
      confirmSave: true,
      saveConfirmedCallback: () =>
        this.save(record, useConfirmDelete, requestOptions),
    });
    return CancellablePromise.resolve();
  };

  resetConfirmDelete = () => {
    this.setState({
      confirmDelete: undefined,
      cancelDelete: undefined,
    });
  };

  render() {
    const {
      canDo,
      children,
      id,
      onCancel,
      onRestore,
      canDelete,
      canRestore,
      restoring,
      passError,
      unwrapRecord,
      confirmationTitle,
      confirmationLabel,
      deleteWarningMessage,
      needSaveConfirmation,
      saveConfirmationMessage,
      dependencies,
    } = this.props;
    const {
      item,
      initialItem,
      initialized,
      error,
      loading,
      saving,
      deleting,
      confirmDelete,
      noRender,
      confirmSave,
    } = this.state;

    const shouldPassError = error && passError?.(error);

    if (noRender) return null;
    if (error && !shouldPassError) return <ApiError error={error} />;
    if (!initialized) return <LoadingIcon />;

    const record = item && unwrapRecord(item);

    const isAllowedToSave = canDo(id ? "Update" : "Create");
    const isAllowedToDelete =
      canDo("Delete") &&
      id &&
      record &&
      (!canDelete || canDelete(record)) &&
      (!canRestore || !canRestore(record));
    const isAllowedToRestore =
      canDo("Restore") &&
      record &&
      canRestore &&
      R.equals(item, initialItem) &&
      canRestore(record);

    const injected: Injected<TValue, TId, TRecord> = {
      initialValue: initialItem,
      value: item,
      onChange: this.onChange,
      save: isAllowedToSave
        ? needSaveConfirmation
          ? this.saveWithCallBackAndConfirmation
          : this.save
        : undefined,
      onSave: isAllowedToSave
        ? needSaveConfirmation
          ? this.saveWithConfirmation
          : this.saveFromState
        : undefined,
      onDelete: isAllowedToDelete ? this.remove : undefined,
      onRestore: isAllowedToRestore ? onRestore : undefined,
      reload: this.reload,
      error: shouldPassError ? error : undefined,
      loading,
      saving,
      restoring,
      deleting,
      // these are just props passed down
      dependencies, // also used in logic here
      id,
      deleteWarningMessage,
      onCancel,
    };

    const saveWarningContent = `${saveConfirmationMessage} ${_(
      "Are you sure you want to save the changes",
    )}?`;

    return (
      <div className="x-base">
        {Children.map(children, (c: ReactElement) => cloneElement(c, injected))}
        {confirmDelete ? (
          <DeleteWarning
            confirmationTitle={confirmationTitle}
            confirmationLabel={confirmationLabel}
            onCancel={this.onDeleteCancelled}
            onDelete={this.onDeleteConfirmed}
          />
        ) : undefined}
        {confirmSave ? (
          <Warning
            title={_("Save confirmation")}
            content={saveWarningContent}
            action1={_("No, don't save")}
            action2={_("Yes, save the changes")}
            onAction1={this.onSaveCancelled}
            onAction2={this.onSaveConfirmed}
          />
        ) : undefined}
      </div>
    );
  }
}

import { Component } from "react";
import { recordsApi } from "common/api/records";
import { mergeChain } from "common/merge";
import { StandardRelated } from "common/record/form/content/related/standard-related";
import { PropTypes } from "common/record/form/content/related/table-with-form/types";
import { RelatedValue } from "common/record/form/content/related/types";
import { getRelatedRecords } from "common/record/utils";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { AlertWarning } from "common/widgets/alert";
import { ValueProps } from "common/with-value-for";
import { CostSummary } from "./cost-summary";
import {
  getPOIIgnoredColumns,
  getPurchaseOrderItemRelatedEntities,
  searchDefaultLocation,
  searchUnitCost,
  updateDisplayTypes,
  updatePOIContext,
} from "./functions";

type Props = PropTypes & ValueProps<RelatedValue>;

export class PurchaseOrderItem extends Component<Props> {
  static readonly displayName = "PurchaseOrderItem";

  getPOFormValue = (v: RelatedValue) => {
    const {
      entity: { name },
    } = this.props;
    return v?.related?.partialForm?.[name];
  };

  hasPartChanged = (oldProps: Properties, newProps: Properties) => {
    const newPartId: string = newProps?.partId?.id;
    return (
      oldProps?.id === newProps?.id &&
      newPartId &&
      oldProps?.partId?.id !== newPartId
    );
  };

  hasTaxRateChanged = (oldProps: Properties, newProps: Properties) => {
    return (
      oldProps?.id === newProps?.id &&
      oldProps?.taxRateId?.id !== newProps?.taxRateId?.id
    );
  };

  setPartialForm = (eventValue: RelatedValue, props: Properties) => {
    const { entity, onChange } = this.props;
    const mergedProps = mergeChain(eventValue)
      .prop("related")
      .prop("partialForm")
      .set(entity.name, props)
      .output();

    onChange(mergedProps);
  };

  updatePartProperties = (newProps: Properties, eventValue: RelatedValue) => {
    const { context, value, entity } = this.props;
    const newPartId: string = newProps?.partId?.id;
    return CancellablePromise.all([
      recordsApi(context.apiCall).get(newProps?.partId?.entity, newPartId),
      searchUnitCost(context, entity, value, newPartId),
      searchDefaultLocation(context, entity, value, newPartId),
    ]).then(([part, unitCost, defaultPartLocation]) => {
      const props = {
        ...newProps,
        ...unitCost,
        partDescription: part.properties.description,
        partNumber: part.properties.partNumber,
        partLocationId: defaultPartLocation,
      };

      return this.setPartialForm(eventValue, props);
    });
  };

  updateTaxRateProperties = (
    newProps: Properties,
    eventValue: RelatedValue,
  ) => {
    const newTaxRate: string = newProps?.taxRateId?.rate;
    const newTaxId: string = newProps?.taxRateId?.id;
    const props: Properties = {
      ...newProps,
      taxRateId: newTaxId,
      taxRate: newTaxRate,
      taxValue: undefined,
      total: undefined,
    };

    return this.setPartialForm(eventValue, props);
  };

  onChangeValue = (eventValue: RelatedValue) => {
    const { value, onChange } = this.props;
    const oldProps = this.getPOFormValue(value);
    const newProps = this.getPOFormValue(eventValue);

    if (this.hasPartChanged(oldProps, newProps)) {
      return this.updatePartProperties(
        {
          ...newProps,
          ...(oldProps?.partId // part "really" changed. partLocation then is reset
            ? { partLocationId: undefined as unknown }
            : {}),
        },
        eventValue,
      );
    } else if (this.hasTaxRateChanged(oldProps, newProps)) {
      return this.updateTaxRateProperties(newProps, eventValue);
    } else {
      return onChange(eventValue);
    }
  };

  render() {
    const { context, entity, value, displayTypes, parentEntity } = this.props;
    const { partSupplierEntity } = getPurchaseOrderItemRelatedEntities(
      context,
      entity,
    );
    if (!partSupplierEntity) {
      return (
        <AlertWarning
          message={_("Missing permission for Part Supplier Entity.")}
        />
      );
    }
    const { record, related } = value;
    const relatedRecords = getRelatedRecords(entity.name, record, related.form);
    const updatedContext = updatePOIContext(context, entity, value);
    const { entities } = updatedContext;
    const updatedEntity = entities[entity.name];

    const costSummary = value?.record?.properties ? (
      <CostSummary
        entity={parentEntity}
        context={context}
        properties={value.record.properties}
      />
    ) : null;

    return (
      <>
        <StandardRelated
          {...this.props}
          entity={updatedEntity}
          displayTypes={updateDisplayTypes(value, displayTypes)}
          context={updatedContext}
          onChange={this.onChangeValue}
          ignore={getPOIIgnoredColumns(relatedRecords)}
          forceInclude={["number"]}
        />
        {costSummary}
      </>
    );
  }
}

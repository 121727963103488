import * as R from "ramda";
import { getColumn } from "common/entities";
import { isSameColumnData } from "common/entities/entity-column/functions";
import { ColumnTypes, EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { getLayoutGroupColumns } from "common/form/functions/common";
import { GroupColumn } from "common/form/types";
import { getFkId } from "common/functions/foreign-key";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { Properties, Record, RelatedRecords } from "common/types/records";
import {
  PreviewPayload,
  PurchaseOrderItem,
  PurchaseOrderPayload,
} from "./purchase-order/types";
import { Requisition } from "./requisition/types";

export const getFakeEntityColumns = (context: Context) => {
  // we can't guess the relatedEntity here (if there's more than one entity by behavior)
  // we use the entity name which comes from the expanded FKs
  const columns: EntityColumn[] = [
    {
      name: "partId",
      localizedName: _("Part"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: "Parts",
      relatedColumn: "id",
      readOnly: true,
    },
    {
      name: "description",
      localizedName: _("Description"),
      dataType: "string",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "locationId",
      localizedName: _("Part Location"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: "PartInventories",
      relatedColumn: "number",
      readOnly: true,
    },
    {
      name: "preferredSupplier",
      localizedName: _("Preferred Supplier"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: undefined,
    },
    {
      name: "onHand",
      localizedName: _("On Hand"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "onOrderQuantity",
      localizedName: _("On Order Quantity"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "reorderPoint",
      localizedName: _("Reorder Point"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "reorderQuantity",
      localizedName: _("Reorder Quantity"),
      dataType: "int",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "unitCost",
      localizedName: _("Unit Cost"),
      dataType: "currency",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      getDependencies: (row: Properties): Properties => ({
        currencyId: row.currency,
      }),
    },
    {
      name: "conversionRate",
      localizedName: "Conversion Rate",
      dataType: "ufloat",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "totalCost",
      localizedName: _("Total Cost"),
      dataType: "currency",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "requisitionTitle",
      localizedName: _("Requisition Title"),
      dataType: "string",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
    },
    {
      name: "requestedBy",
      localizedName: _("Requested By"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedEntity: "Contacts",
      relatedColumn: "id",
      readOnly: true,
    },
    {
      name: "approvalGroupId",
      localizedName: _("Approval Group"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedColumn: "id",
      relatedEntity: "ApprovalGroups",
    },
    {
      name: "approvalCostCenterId",
      localizedName: _("Approval Cost Center"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedColumn: "id",
      relatedEntity: "ApprovalCostCenters",
    },
    {
      name: "partRequisitionId",
      localizedName: _("Part Requisition"),
      dataType: "fk",
      availableForList: true,
      isSystem: true,
      columnType: ColumnTypes.System,
      isForeignKey: true,
      relatedColumn: "id",
      relatedEntity: "PartRequisitions",
    },
  ];

  const siteColumn: EntityColumn = {
    name: "site",
    dataType: "string",
    columnType: ColumnTypes.System,
    localizedName: _("Site"),
    isSystem: true,
    readOnly: true,
    required: true,
    maxLength: 0,
  };

  return context.site.isGroup ? [siteColumn, ...columns] : columns;
};

export const toPurchaseOrderGroups = (
  purchaseOrderItems: PurchaseOrderItem[],
) => R.groupBy((item) => item.purchaseOrderNumber, purchaseOrderItems);

export const toPurchaseOrderPayload = (
  purchaseOrderItems: PurchaseOrderItem[],
  formId: number,
  requiredValues: Properties,
  requiredItemValues: Properties[],
): PurchaseOrderPayload[] => {
  const groups = toPurchaseOrderGroups(purchaseOrderItems);

  return Object.keys(groups).map((poId) => {
    const { supplierId } = groups[poId][0];
    return {
      purchaseOrderNumber: poId,
      supplierId: supplierId.id,
      formId,
      properties: requiredValues,
      items: groups[poId].map((poItem) => {
        const { reorderQuantity, ...currentItemValues } =
          requiredItemValues.find((obj) => obj[poItem.id])?.[poItem.id] || {};
        return {
          partId: getFkId(poItem.partId),
          partLocationId: getFkId(poItem.locationId),
          unitCost: poItem.unitCost,
          stockEntity: poItem.stockEntity,
          properties: {
            orderedQty: Number(reorderQuantity),
            ...currentItemValues,
          },
        };
      }),
    };
  });
};

export const toPreviewPayload = (
  purchaseOrderItems: PurchaseOrderItem[],
  requiredItemValues: Properties[],
): PreviewPayload[] => {
  const groups = toPurchaseOrderGroups(purchaseOrderItems);
  return Object.keys(groups).flatMap((poId) =>
    groups[poId].map((poItem) => {
      const reorderQuantity =
        requiredItemValues.find((obj) => obj[poItem.id])?.[poItem.id]
          ?.reorderQuantity || "";
      return {
        partId: getFkId(poItem.partId),
        partLocationId: getFkId(poItem.locationId),
        supplierId: getFkId(poItem.supplierId),
        orderedQty: Number(reorderQuantity),
        unitCost: poItem.unitCost,
      };
    }),
  );
};

export const toPartRequisitionRecord = (
  requisition: Requisition,
  isNewRecord: boolean,
): Record => {
  const { partRequisitionItems, requestedBy, ...parRequisitionProps } =
    requisition.partRequisition;
  const properties: Properties = isNewRecord
    ? {
        ...parRequisitionProps,
        requestedBy: requestedBy?.id,
      }
    : { id: requisition.partRequisitionId?.id };

  const related: RelatedRecords = {
    PartRequisitionItems: partRequisitionItems.map(
      (partRequisitionItem): Record => {
        const {
          locationId,
          id,
          isNew,
          partId,
          partLocationId,
          ...parRequisitionItemProps
        } = partRequisitionItem;

        return {
          isNew,
          properties: isNew
            ? { partId, partLocationId, ...parRequisitionItemProps }
            : {
                id,
                quantity: partRequisitionItem.quantity,
                ...parRequisitionItemProps,
              },
          actions: [],
        };
      },
    ),
  };

  return {
    isNew: isNewRecord,
    properties,
    related,
    actions: [],
  };
};

const getKey = (column: EntityColumn) => {
  return "columnName" in column ? column.columnName : column.name;
};

const getUniqueColumns = (columns: EntityColumn[]) =>
  Array.from(new Map(columns.map((item) => [getKey(item), item])).values());

const getFormLayoutColumns = (form: Form) =>
  getLayoutGroupColumns(form?.settings.groups);

const getRequiredFieldsFromEntity = (entity: Entity): EntityColumn[] =>
  entity.columns.filter(
    (column) =>
      !column.readOnly &&
      column.required &&
      !column.unique &&
      !(column.relatedEntity && column.columnType === 1),
  );

const meetsCondition = (
  layoutColumn: GroupColumn,
  entityColumn: EntityColumn,
) =>
  !entityColumn?.readOnly &&
  !entityColumn?.unique &&
  (layoutColumn?.required || entityColumn?.required);

const getRequiredFieldsFromForms = (
  entity: Entity,
  form: Form,
): EntityColumn[] => {
  const layoutColumns = getFormLayoutColumns(form);

  return layoutColumns?.reduce((acc, layoutColumn) => {
    const entityColumn = getColumn(entity, layoutColumn.columnName);
    return meetsCondition(layoutColumn, entityColumn)
      ? [...acc, entityColumn]
      : acc;
  }, []);
};

const getNonMatchingColumns = (
  entityColumns: EntityColumn[],
  fakeEntityColumns: EntityColumn[],
): EntityColumn[] => {
  return R.differenceWith(
    (a: EntityColumn, b: EntityColumn) => isSameColumnData(a)(b),
    entityColumns,
    fakeEntityColumns,
  );
};

export const findRequiredColumns = (
  entity: Entity,
  selectedForm: Form,
  partReorderListEntityColumns: EntityColumn[],
  supplierEntityColumns?: EntityColumn[],
) => {
  const requiredFieldsFromEntity = getRequiredFieldsFromEntity(entity);
  const requiredFieldsFromForms = getRequiredFieldsFromForms(
    entity,
    selectedForm,
  );
  const entityColumns = getUniqueColumns([
    ...requiredFieldsFromForms,
    ...requiredFieldsFromEntity,
  ]);
  const missingFields = getNonMatchingColumns(entityColumns, [
    ...(supplierEntityColumns || []),
    ...partReorderListEntityColumns,
  ]);

  return missingFields;
};

export const getFormDefaults = (form: Form) => {
  return form?.settings?.defaults;
};

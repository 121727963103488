import { Component } from "react";
import { defaultFor } from "common";
import { getEntityByBehavior } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { filterFormsByEntity } from "common/functions/forms";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { FormSwitcher } from "common/record/form/content/related/form-switcher";
import { TableWithFormValue } from "common/record/form/content/related/table-with-form/types";
import { ValueProps } from "common/with-value-for";
import { PartRequisitionItem } from "../types";
// eslint-disable-next-line import/no-cycle
import { RequisitionGroupFields } from "../requisition-group-field";

interface PropTypes extends ValueProps<PartRequisitionItem> {
  context: Context;
  columns: EntityColumn[];
  requiredItemColumns: EntityColumn[];
  isFirst: boolean;
  showHint?: boolean;
}

export class RequisitionItem extends Component<PropTypes> {
  static readonly displayName = "RequisitionItem";

  getGroupField = (columnName: string) => {
    const { context, columns, requiredItemColumns, showHint, value, onChange } =
      this.props;

    return (
      <RequisitionGroupFields
        key={columnName}
        context={context}
        columnName={columnName}
        columns={[...columns, ...(requiredItemColumns || [])]}
        showHint={showHint}
        value={value}
        onChange={onChange}
      />
    );
  };

  renderRequiredGroupFields = (
    requiredItemColumns: EntityColumn[],
    isOdd: boolean,
  ) =>
    requiredItemColumns
      .filter((_, index) => (isOdd ? index % 2 !== 0 : index % 2 === 0))
      .map((requiredItemColumn) => this.getGroupField(requiredItemColumn.name));

  onItemsFormChange = (newForm: TableWithFormValue) => {
    const { value, onChange } = this.props;

    onChange({ ...value, formId: newForm.form.formId });
  };

  render() {
    const { context, requiredItemColumns, isFirst, value } = this.props;
    const partRequisitionItemEntity = getEntityByBehavior(
      "RequisitioningItem",
      context.entities,
    );
    const requisitionItemForms: Form[] = filterFormsByEntity(
      context.forms,
      partRequisitionItemEntity.name,
    );
    const formValue: TableWithFormValue = {
      ...defaultFor<TableWithFormValue>(),
      form: { formId: value.formId || requisitionItemForms[0]?.id },
    };

    return (
      <div className="row">
        {requisitionItemForms.length > 1 && isFirst && (
          <FormSwitcher
            context={context}
            entity={partRequisitionItemEntity}
            defaultForm={undefined}
            value={formValue}
            onChange={this.onItemsFormChange}
          />
        )}
        <div className="col-sm-6" data-testid="left-group-items">
          {this.getGroupField("partId")}
          {this.renderRequiredGroupFields(requiredItemColumns, false)}
        </div>
        <div className="col-sm-6" data-testid="right-group-items">
          {this.getGroupField("locationId")}
          {this.renderRequiredGroupFields(requiredItemColumns, true)}
        </div>
      </div>
    );
  }
}

import * as React from "react";
import { classNames } from "common/utils/jsx";

import "./index.scss";

interface PropTypes {
  starred: boolean;
  recordId: string;
  toggleStar: (id: string, starred: boolean) => void;
  className?: string;
}

export class Star extends React.Component<PropTypes> {
  static readonly displayName = "Star";

  toggle = () => {
    const { starred, recordId, toggleStar } = this.props;
    toggleStar(recordId, !starred);
  };

  render() {
    const { starred, className } = this.props;

    const buttonClassNames = classNames(["x-star qa-star", className]);
    return (
      <div
        className={buttonClassNames}
        aria-label={_("Mark the record with a star")}
        role="checkbox"
        aria-checked={starred}
        onClick={this.toggle}
      >
        <i className={starred ? "fa fa-star" : "fa fa-star-o"} />
      </div>
    );
  }
}

import * as R from "ramda";
import { Context } from "common/types/context";
import { defaultFor, getLocalizedName } from "common";
import { getColumn, isSitesColumn } from "common/entities";
import { formBlacklist } from "common/entities/entity-column/data-type/types";
import { isRestrictedForRole } from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { createColumn } from "common/form/functions/group-column";
import { getStaticEntries } from "common/record/utils";
import { Role } from "common/types/roles";
import { Group, GroupColumn, Layout } from "common/form/types";
import { getLayoutGroupColumns } from "./common";
import { isRestrictedColumn } from "./entity";
import { mapColumnsFromGroups } from "./group";
import { getDefaultHeader } from "./header";

export const createDefaultLayout = (
  entity: Entity,
  context: Context,
  properties: any,
  includeNameInLabel: boolean,
): Layout => {
  const header = getDefaultHeader(entity);

  const inHeader = (columnName: string) =>
    Object.values(header).some(
      (headerColumName: string) => columnName === headerColumName,
    );

  const getColumn = (name: string) =>
    entity.columns.find((c) => c.name === name, entity.columns);

  const isInformed = (columnName: string) =>
    !getColumn(columnName).readOnly ||
    (!!(properties && properties[columnName]) && !inHeader(columnName));

  const columns = entity.columns.filter(
    (ec) =>
      isInformed(ec.name) &&
      !formBlacklist.includes(ec.dataType) &&
      !isRestrictedColumn(entity, ec.name),
  );

  const localizedName =
    includeNameInLabel && getLocalizedName(entity)
      ? _("ENTITY Details").replace("ENTITY", getLocalizedName(entity))
      : _("Details");

  return {
    header,
    defaults: undefined,
    reports: undefined,
    staticEntries: getStaticEntries(entity, context).map((e) => e.value),
    relatedEntities: undefined,
    lookupQueries: undefined,
    groups: [
      {
        name: localizedName,
        label: includeNameInLabel ? localizedName : undefined,
        position: "left",
        columns: columns.map(createColumn),
      },
    ],
  };
};

export const omitLayoutColumn = (
  layout: Layout,
  columnName: string,
): Layout => ({
  ...layout,
  groups: layout.groups.map((g) => ({
    ...g,
    columns: g.columns.filter((c) => c.columnName !== columnName),
  })),
});

export const mapLayout = (
  mapFn: (col: GroupColumn) => GroupColumn,
  layout: Layout = defaultFor<Layout>(),
): Layout => ({
  ...layout,
  groups: mapColumnsFromGroups(mapFn, layout.groups),
});

export const filterLayout = (
  filterFn: (col: GroupColumn) => boolean,
  layout: Layout,
): Layout => mapLayout((col) => (filterFn(col) ? col : undefined), layout);

/** Returns layout with only the columns that exist in the entity */
export const filterColumns = (entity: Entity, layout: Layout) =>
  filterLayout((c) => !!getColumn(entity, c.columnName), layout);

export const getLayoutColumnNames = (layout: Layout): string[] =>
  getLayoutGroupColumns(layout.groups).map((c) => c.columnName);

export const getDrilldownColumns = (groups: Group[] = []): string[] =>
  groups.flatMap((group) =>
    group.columns
      .filter((column) => column.lookupConfiguration?.type === "drilldown")
      .flatMap((column) =>
        column.lookupConfiguration.mappedFields.map(
          (field) => field.columnName,
        ),
      ),
  );

export const getLayoutAndDrilldownColumns = (layout: Layout) => {
  if (!layout) return [];

  const drilldownColumns = getDrilldownColumns(layout.groups) || [];
  const layoutColumns = getLayoutColumnNames(layout) || [];

  return drilldownColumns.concat(layoutColumns);
};

export const findMissingColumns = (layout: Layout, entity: Entity) => {
  if (!layout || !layout.groups) return [];
  const { groups, defaults } = layout;

  const columnNamesWithDefault = Object.keys(defaults || {}).filter(
    (columnName) => !R.isNil(defaults[columnName]),
  );
  const required = entity.columns
    .filter(
      (c) =>
        c.required &&
        !c.readOnly &&
        c.name !== entity.ownerFkColumn &&
        !columnNamesWithDefault.includes(c.name) &&
        !isSitesColumn(entity, c.name),
    )
    .map((c) => c.name);

  const drilldownColumnNames = getDrilldownColumns(groups);

  const missingColNames = R.difference(
    required,
    getLayoutColumnNames(layout).concat(drilldownColumnNames),
  );
  return entity.columns.filter((c) => missingColNames.includes(c.name));
};

export const appendColumnsToLastGroup = (
  cols: EntityColumn[],
  layout: Layout,
): Layout =>
  R.mergeRight(layout, {
    groups: (layout.groups || []).map((g, i) =>
      i === layout.groups.length - 1
        ? R.mergeRight(g, {
            columns: (g.columns || []).concat(cols.map(createColumn)),
          })
        : g,
    ),
  });

export const isReadOnlyColumn = (
  entity: Entity,
  col: GroupColumn,
  role: Role,
) => {
  if (!col) return false;

  const entityColumn = getColumn(entity, col.columnName);
  return !!(
    col.readOnly ||
    entityColumn?.readOnly ||
    entityColumn?.userReadOnly ||
    isRestrictedForRole(entityColumn?.roleIds, role)
  );
};

export const findGroupColumn = (columnName: string, layout: Layout) =>
  getLayoutGroupColumns(layout.groups).find((c) => c.columnName === columnName);

import { ApiCall } from "common/types/api";
import { getTimestamp } from "common/date-time/common";
import { FileType } from "common/types/media";

export const avatarUrl = "api/ui/avatar";
const constructAvatarUrl = (userId?: string) => {
  const userIdPath = userId ? `/${userId}` : "";
  return `/${avatarUrl}${userIdPath}`;
};

export const avatarApi = (apiCall: ApiCall) => ({
  upload: (file: FileType, userId?: string) =>
    apiCall("post", constructAvatarUrl(userId), file, {
      upload: true,
    }).then(() => `${constructAvatarUrl(userId)}?ts=${getTimestamp()}`),
  remove: (userId?: string) => apiCall("delete", constructAvatarUrl(userId)),
});

import * as R from "ramda";
import { Component } from "react";
import { getLocalizedName } from "common";
import type { UTCDateRange } from "common/date-time/types";
import type { Entity } from "common/entities/types";
import type { RelatedEntity, RelatedEntityColumn } from "common/form/types";
import { merge2, merge3 } from "common/merge";
import { Section } from "common/record/form/content/detail/view/section-group";
import { RelatedRecordsForm } from "common/record/form/content/related";
import type { RelatedValue } from "common/record/form/content/related/types";
import type { Record, RecordPayload } from "common/types/records";
import type { RelatedFormDisplayTypes } from "common/types/related-form-display";
import { ModifyButton } from "common/ui/buttons";
import { AlertWarning } from "common/widgets/alert";
import { getSortedAssignments } from "x/scheduler2/shared/events";
import {
  getAssignmentEntity,
  getEntityConfig,
} from "x/scheduler2/workorder-view/functions";
import type { SectionProps } from "x/scheduler2/workorder-view/section/types";

interface PropTypes extends SectionProps {
  assignmentsWithConflicts: string[];
  onChangeRelated: (v: RelatedValue) => void;
  toggleNewForm: () => void;
  hideNewForm: boolean;
  hideNewButton?: boolean;
}

interface StateType {
  entity: Entity;
}

const plannerDefaultColumns: RelatedEntityColumn[] = [
  { columnName: "targetId" },
  { columnName: "rangeFrom" },
  { columnName: "rangeTo" },
  { columnName: "c_estimatedhours" },
  { columnName: "c_assignedby" },
  { columnName: "assignmentStatusId" },
  { columnName: "status" },
];

const getColumns = (isPlanner: boolean, columns: RelatedEntityColumn[]) =>
  isPlanner
    ? columns
    : columns.filter((column) => column.columnName !== "status");

export const getPreviousAssignment = (
  relatedValue: RelatedValue,
  assignmentEntityName: string,
): Record => {
  const entityNameLowered = assignmentEntityName.toLocaleLowerCase();
  const assignments = relatedValue?.record?.related?.[entityNameLowered] || [];

  return assignments.length ? getSortedAssignments(assignments)[0] : undefined;
};

export const getRangeFromAssignment = (assignment: Record): UTCDateRange => {
  if (!assignment) return undefined;
  const properties = assignment.properties || {};
  const { rangeFrom, rangeTo } = properties;
  return {
    from: rangeFrom,
    to: rangeTo,
  };
};

const getDisplayType = (
  isPlanner: boolean,
  hideNewForm: boolean,
): RelatedFormDisplayTypes => {
  return !isPlanner
    ? ["table"]
    : hideNewForm
      ? ["actions", "table"]
      : ["form", "table", "actions"];
};

export class AssignmentSection extends Component<PropTypes, StateType> {
  static readonly displayName = "AssignmentSection";

  constructor(props: PropTypes) {
    super(props);
    const { context, workOrderEntityName } = props;
    this.state = {
      entity: getAssignmentEntity(workOrderEntityName, context.entities),
    };
  }

  getNewAsUnpublished = (value: RelatedValue) => {
    const { entity } = this.state;
    const { related } = value;
    const entityName = entity.name;
    const newAssignments = R.path<RecordPayload[]>(
      ["form", entityName],
      related,
    );
    const unpublishedAssignments = R.map((assignment: RecordPayload) => {
      return !R.path<string>(["properties", "status"], assignment)
        ? merge2("properties", "status", "N", assignment)
        : assignment;
    }, newAssignments || []);

    return merge3("related", "form", entityName, unpublishedAssignments, value);
  };

  onChange = (value: RelatedValue) => {
    const { onChangeRelated } = this.props;
    const { related } = value;

    onChangeRelated(related?.isDirty ? this.getNewAsUnpublished(value) : value);
  };

  render() {
    const {
      context,
      workOrderEntityName,
      relatedValue,
      workOrderId,
      hideNewForm,
      hideNewButton,
      assignmentsWithConflicts = [],
      toggleNewForm,
      isPlanner,
    } = this.props;
    const { entity } = this.state;

    if (!entity) return null;

    const partialForm = relatedValue?.related?.partialForm?.[entity.name];
    const isUpdating = hideNewForm && !!partialForm;
    const haveAssignmentsConflicts = assignmentsWithConflicts.length > 0;
    const lastAssignedRange =
      isPlanner &&
      !hideNewForm &&
      getRangeFromAssignment(getPreviousAssignment(relatedValue, entity.name));

    const entityConfigFromForm = getEntityConfig(
      context,
      relatedValue?.record?.properties?.formId,
      entity.name,
    );

    const entityConfig: RelatedEntity = {
      ...entityConfigFromForm,
      columns: getColumns(
        isPlanner,
        entityConfigFromForm?.columns ?? plannerDefaultColumns,
      ),
    };

    return (
      <Section
        className="qa-WorkOrderView-assignments"
        title={getLocalizedName(entity)}
      >
        {!hideNewButton || haveAssignmentsConflicts ? (
          <div className="x-flex x-assignments-header">
            {haveAssignmentsConflicts && (
              <AlertWarning
                message={_("Highlighted contacts have conflicts")}
                className="x-alert-clean"
              />
            )}
            {!hideNewButton && hideNewForm ? (
              <ModifyButton
                onClick={toggleNewForm}
                title={_("Add Assignee")}
                disabled={isUpdating}
              >
                {_("Add Assignee")}
              </ModifyButton>
            ) : undefined}
          </div>
        ) : undefined}
        <RelatedRecordsForm
          context={context}
          parentEntity={context.entities[workOrderEntityName]}
          entity={entity}
          recordId={workOrderId}
          entityConfig={entityConfig}
          isTemplate={false}
          displayTypes={getDisplayType(isPlanner, hideNewForm)}
          recordDetail={undefined}
          reload={undefined}
          defaultDates={lastAssignedRange}
          highlighted={assignmentsWithConflicts}
          onCancel={toggleNewForm}
          value={relatedValue}
          onChange={this.onChange}
        />
      </Section>
    );
  }
}

import * as R from "ramda";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import { merge1 } from "common/merge";
import { Entity } from "common/entities/types";
import { Record, Properties } from "common/types/records";
import { isSameColumnData } from "common/entities/entity-column/functions";
import { Action } from "common/record/actions/types";

export const toSiteRecords = (context: Context, records: Record[]) => {
  const { site } = context;
  const recordsBySite = site.isGroup
    ? R.groupBy((record) => record.properties.site || site.name, records)
    : { [site.name]: records };

  const idsBySite = R.mapObjIndexed(
    (records: Record[], siteName: string) => ({
      site: siteName,
      ids: records.map((record: Record) => record.properties.id),
    }),
    recordsBySite,
  );

  return R.values(idsBySite);
};

export const getAdditionalProperties = (
  entity: Entity,
  targetEntity: Entity,
  record: Record,
) => {
  const commonEntityColumns = entity.columns.filter((c) =>
    R.any(isSameColumnData(c), targetEntity.columns),
  );

  return commonEntityColumns.reduce(
    (acc, c) => merge1(c.name, record.properties[c.name], acc),
    defaultFor<Properties>(),
  );
};

export const getHandlerRecords = (
  entity: Entity,
  records: Record[],
  action: Action,
) => {
  const { target } = action;

  return target && records.length === 1
    ? [
        {
          properties: {
            [target.column]: records[0].properties.id,
            ...getAdditionalProperties(entity, target.entity, records[0]),
            ...action?.extraProperties,
            id: records[0].properties.id,
          },
          actions: undefined,
        },
      ]
    : records;
};

export const getSortedRecordNumbers = (records: Record[] = []) => {
  const recordNumbers: number[] = records.reduce((acc, r) => {
    const num = r?.properties?.number;
    return num ? acc.concat(num) : acc;
  }, []);

  return recordNumbers.sort((a, b) => a - b);
};

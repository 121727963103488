import * as R from "ramda";
import { defaultFor } from "common";
import {
  BooleanTypes,
  EntityColumn,
} from "common/entities/entity-column/types";
import { FormSelector } from "common/form/form-selector";
import {
  FormValidationProps,
  RecordSitesProps,
  WidgetOverwrite,
} from "common/form/types";
import {
  getSiteSystemString,
  getSystemString,
} from "common/functions/system-string-options";
import { setFkExpansion } from "common/query/expansion";
import { SelectField } from "common/query/types";
import { Context } from "common/types/context";
import { BooleanInput } from "common/widgets/boolean";
import { CalendarSelector } from "common/widgets/calendar-selector";
import { CurrencySelector } from "common/widgets/currency-selector";
import { DefaultCurrencyInput } from "common/widgets/currency/input-with-default";
import { Date } from "common/widgets/date/date";
import { DateOffset } from "common/widgets/date/date-offset";
import { DateTime } from "common/widgets/date/date-time";
import { DocumentWithUploadController } from "common/widgets/document/controller";
import { Email } from "common/widgets/email";
import { GeolocationInput } from "common/widgets/geolocation";
import { ImageWithUploadController } from "common/widgets/image-with-upload/controller";
import { StringInput } from "common/widgets/input-with-submit/string";
import { withBuffer } from "common/widgets/line-buffered-widget";
import { Float, Int, Ufloat, Uint } from "common/widgets/number";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
// eslint-disable-next-line import/no-cycle
import { AdvancedSingleRecordSelector } from "common/widgets/record-selector";
import { Selector } from "common/widgets/selector";
import { ReferenceSelector } from "common/widgets/record-selector/references";
import { SitesSelectorWithChicklets } from "common/widgets/site-selector/with-chicklets";
import { SystemIntFkSelector } from "common/widgets/system-int-foreign-key";
import { T1000 } from "common/widgets/text";
import { LazyTextEditor } from "common/widgets/text-editor";
import type { UsageArea } from "common/widgets/types";
import { WebLink } from "common/widgets/web-link";
import { WithValue, withValue } from "common/with-value";
import { MediaInputController } from "common/widgets/media/controller";
import { getSelfOutFilter, isSiteRelatedColumn } from "./functions";

interface PropTypes
  extends WidgetOverwrite,
    FormValidationProps,
    RecordSitesProps {
  context: Context;
  column: EntityColumn;
  disabled: boolean;
  validate: boolean;
  buffer: boolean;
  allowClear?: boolean;
  entityName?: string;
  recordId?: string;
  inputId?: string;
  minDate?: string;
  maxDate?: string;
  currencyId?: string;
  hideSymbol?: boolean;
  addToSelect?: SelectField[];
  label?: string;
  usageArea?: UsageArea;
}

type Props = PropTypes & WithValue<any>;

export const InputWidget = withValue<any, PropTypes>(
  ({
    context,
    column = defaultFor<EntityColumn>(),
    disabled = false,
    validate = false,
    buffer,
    allowClear = false,
    entityName,
    recordId,
    inputId,
    addToSelect,
    referenceFilter,
    minDate,
    maxDate,
    currencyId,
    hideSymbol,
    placeholder,
    label,
    query,
    formValidation,
    onFormValidationChange,
    recordSites,
    usageArea,
    value,
    onChangeSetValue,
  }: Props) => {
    const {
      dataType,
      relatedSystem,
      maxLength,
      minValue,
      maxValue,
      isForeignKey,
      relatedEntity,
      approveChange,
      name,
      decimalScale,
      booleanType,
      localizedName,
    } = column;
    const fieldLabel = label ?? localizedName;
    const { entities, uiFormat, forms, calendars, systemTables, sites } =
      context;
    const withValueProps = {
      allowClear,
      disabled,
      maxLength,
      minValue,
      maxValue,
      decimalScale,
      onChange: onChangeSetValue,
      className: "qa-input-widget-" + dataType,
      label: fieldLabel,
      inputId,
      columnName: name,
      value,
      formValidation,
      onFormValidationChange,
    };

    const entity = entities[entityName];

    const systemString = getSystemString(entity, name);
    if (systemString) {
      return (
        <Selector<string>
          {...withValueProps}
          placeholder={placeholder}
          getOptionLabel={systemString.translate}
          options={systemString.options}
        />
      );
    }

    if (isSiteRelatedColumn(column)) {
      const siteSystemString = getSiteSystemString(sites);
      return (
        <Selector<string>
          {...withValueProps}
          placeholder={placeholder}
          getOptionLabel={siteSystemString.translate}
          options={siteSystemString.options}
          value={value}
        />
      );
    }

    if (name === "sites") {
      return (
        <SitesSelectorWithChicklets
          context={context}
          value={value}
          onChange={onChangeSetValue}
        />
      );
    }

    const buffered = buffer ? withBuffer(value, onChangeSetValue) : R.identity;

    if (entities && isForeignKey) {
      const widgetQuery = query ?? column.query;

      return entities[relatedEntity].type === "Reference" ? (
        <ReferenceSelector
          context={context}
          placeholder={placeholder}
          allowClear={allowClear}
          entity={relatedEntity}
          query={widgetQuery}
          disabled={disabled}
          filter={referenceFilter}
          recordSites={recordSites}
          formValidation={formValidation}
          onFormValidationChange={onFormValidationChange}
          column={column}
          label={fieldLabel}
          value={value}
          onChange={onChangeSetValue}
        />
      ) : (
        <AdvancedSingleRecordSelector
          context={context}
          entity={entities[relatedEntity]}
          query={
            widgetQuery
              ? setFkExpansion({ entity: relatedEntity, query: widgetQuery })
              : undefined
          }
          disabled={disabled}
          withLinks={false}
          allowClear={allowClear}
          addToSelect={addToSelect}
          extraFilter={getSelfOutFilter(entity, column, recordId)}
          placeholder={placeholder}
          recordSites={recordSites}
          column={column}
          label={fieldLabel}
          formValidation={formValidation}
          onFormValidationChange={onFormValidationChange}
          value={value}
          onChange={onChangeSetValue}
          approveChange={approveChange}
        />
      );
    }
    switch (dataType) {
      case "int":
        return <Int {...withValueProps} placeholder={placeholder} />;
      case "uint":
        return buffered(<Uint {...withValueProps} placeholder={placeholder} />);
      case "date":
        return buffered(<Date {...withValueProps} uiFormat={uiFormat} />);
      case "datetime":
        return buffered(
          <DateTime
            {...withValueProps}
            minDate={minDate}
            maxDate={maxDate}
            uiFormat={uiFormat}
          />,
        );
      case "dateoffset":
        return buffered(<DateOffset {...withValueProps} />);
      case "currency":
        return buffered(
          <DefaultCurrencyInput
            {...withValueProps}
            column={column}
            currencyId={currencyId}
            context={context}
            hideSymbol={hideSymbol}
          />,
        );
      case "float":
        return buffered(
          <Float
            {...withValueProps}
            placeholder={placeholder}
            decimalSeparator={uiFormat.decimalSeparator}
          />,
        );
      case "ufloat":
        return buffered(
          <Ufloat
            {...withValueProps}
            placeholder={placeholder}
            decimalSeparator={uiFormat.decimalSeparator}
          />,
        );
      case "bool":
        return booleanType === BooleanTypes.YesNo ? (
          <YesNo {...withValueProps} name={name} />
        ) : (
          <BooleanInput {...withValueProps} name={name} />
        );
      case "string":
        return buffered(
          <StringInput {...withValueProps} placeholder={placeholder} />,
        );
      case "email":
        return buffered(<Email {...withValueProps} validate={validate} />);
      case "image":
        return (
          <div className="x-flex-start-center">
            <ImageWithUploadController
              {...withValueProps}
              context={context}
              entityName={entityName}
              recordId={recordId}
              isDefault={false}
              isLarge={true}
              usageArea={usageArea}
            />
          </div>
        );
      case "document":
        return (
          <DocumentWithUploadController
            {...withValueProps}
            context={context}
            entityName={entityName}
            recordId={recordId}
            usageArea={usageArea}
          />
        );
      case "media":
        return (
          <MediaInputController
            {...withValueProps}
            context={context}
            entityName={entityName}
            recordId={recordId}
            usageArea={usageArea}
          />
        );
      case "text":
        return <T1000 {...withValueProps} />;
      case "geolocation":
        return <GeolocationInput {...withValueProps} />;
      case "hyperlink":
        return <WebLink {...withValueProps} />;
      case "html":
        return <LazyTextEditor {...withValueProps} />;
      case "systemintfk":
        if (relatedSystem === "Calendars") {
          return (
            <CalendarSelector
              {...withValueProps}
              placeholder={placeholder}
              calendars={calendars}
            />
          );
        }
        if (relatedSystem === "Forms") {
          return (
            <FormSelector
              {...withValueProps}
              placeholder={placeholder}
              forms={forms}
            />
          );
        }
        return (
          <SystemIntFkSelector
            {...withValueProps}
            systemTables={systemTables}
            tableName={relatedSystem}
          />
        );
      case "systemstringfk":
        if (relatedSystem === "Currencies") {
          return (
            <CurrencySelector
              {...withValueProps}
              placeholder={placeholder}
              context={context}
            />
          );
        }
      // intentional fallthrough
      default:
        return buffered(<StringInput {...withValueProps} />);
    }
  },
  "InputWidget",
);

import { Entity } from "common/entities/types";
import { LoadingButtonLabels } from "common/form/footer";
import { Layout } from "common/form/types";
import { RunQuery } from "common/query/types";
import { Reload } from "common/record/types";
import { RequestOptions } from "common/types/api";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { Record } from "common/types/records";
import { GoFn } from "common/types/url";

export interface PropTypes {
  context: Context;
  goTo: GoFn;
  entity: Entity;
  runQuery: RunQuery;
  withLinks: boolean;
  loading: boolean;
  reload: Reload;
  eventId: string;
  auditTrailId: string;
  saving: boolean;
  deleting: boolean;
  deleteLabels?: LoadingButtonLabels;
  layout: Layout;
  isNew: boolean;
  disableEdit?: boolean;
  save: (
    record: Record,
    confirmDelete: boolean,
    requestOptions?: RequestOptions,
  ) => CancellablePromise<any>;
  onDelete: () => any;
  onCancel: () => any;
  onHasChanged?: (isDirty: boolean) => any;
  getUrl?: (entity: Entity, site: string) => string;
  displaySidebar?: boolean;
  displayHeader?: boolean;
}

export enum PurchaseOrderStatus {
  Opened = 1,
  WaitingForApproval = 2,
  WaitingForParts = 3,
  Closed = 4,
  Rejected = 5,
  Cancelled = 6,
  PartiallyRecieved = 8,
}

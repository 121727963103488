import { type FieldRefs } from "./types/html";

const WINDOW_BOTTOM_MARGIN = 120;

export const isInView = (element: HTMLElement, skipFooterHeight: number) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) -
        skipFooterHeight &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const focusElement = (fieldRefs: FieldRefs, fieldName: string) => {
  const fieldRef = fieldRefs[fieldName];
  if (!fieldRef?.current) return;

  const labelElement = fieldRef.current.querySelector("label");
  const isLabelInView = isInView(labelElement, WINDOW_BOTTOM_MARGIN);

  if (labelElement && !isLabelInView) {
    labelElement.scrollIntoView({ behavior: "smooth" });
  }

  setTimeout(
    () => {
      const focusableElement: HTMLElement = fieldRef.current.querySelector(
        "input, textarea, select, button, .x-customized-focus-element",
      );
      focusableElement?.focus();
    },
    isLabelInView ? 0 : 500,
  );
};

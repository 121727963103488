import * as R from "ramda";
import { arrayToString } from "common/utils/array";
import { mergeChain } from "common/merge";
import { ValueComponent, ValueProps } from "common/with-value-for";
import {
  QueryForEntity,
  SelectField,
  SelectExpression,
  isSelectField,
  isOrderField,
  isDerivedColumn,
} from "../../types";

interface PropTypes {
  column: string;
  queryColumn: SelectField | SelectExpression;
  label: string;
  styles?: string;
  allowOrder: boolean;
  hasEmphasis: boolean;
  relatedEntityName?: string;
}

export type Props = PropTypes & ValueProps<QueryForEntity>;

export class Column extends ValueComponent<QueryForEntity, PropTypes> {
  getCurrentOrder = () => {
    const { queryColumn, value } = this.props;

    if (!queryColumn) return undefined;

    return isSelectField(queryColumn)
      ? R.find(
          (o) =>
            isOrderField(o) &&
            o.path === queryColumn.path &&
            o.name === queryColumn.name,
          value.query.order || [],
        )
      : undefined;
  };

  toggleSort = () => {
    const { queryColumn, value } = this.props;

    if (!queryColumn) return;

    const currentOrder = this.getCurrentOrder();

    const isAsc = !currentOrder || !currentOrder.desc;

    const newQuery = mergeChain(value)
      .prop("query")
      .set("order", [R.mergeRight(queryColumn, { desc: isAsc })])
      .set("page", 0)
      .output();

    this.setValue(newQuery);
  };

  render() {
    const {
      column,
      queryColumn,
      label,
      styles,
      allowOrder,
      hasEmphasis,
      relatedEntityName,
      value,
    } = this.props;

    if (!allowOrder || !value) {
      return (
        <th>
          <div className="x-plain-header-label">{label}</div>
        </th>
      );
    }

    const currentOrder = this.getCurrentOrder();

    const isAsc = !currentOrder || !currentOrder.desc;

    const orderClass = currentOrder
      ? "fa-chevron-" + (isAsc ? "up" : "down")
      : "fa-circle fa-empty";

    const ascDesc = currentOrder
      ? isAsc
        ? "x-column-order-asc"
        : "x-column-order-desc"
      : undefined;

    const name = column || relatedEntityName;
    const cn = arrayToString([
      styles,
      ascDesc,
      `ui-resizable qa-column-header-${name}`,
    ]);

    const canSort =
      queryColumn &&
      isSelectField(queryColumn) &&
      !isDerivedColumn(queryColumn);

    return (
      <th className={cn}>
        {relatedEntityName ? (
          <div className="x-header-title" role="heading">
            <span className="x-header-label">{label}</span>
          </div>
        ) : (
          <div
            onClick={canSort ? this.toggleSort : undefined}
            className={`x-header-title ${canSort ? "x-pointer" : ""}`}
            role="heading"
          >
            {hasEmphasis ? (
              <i className="x-icon-before fa fa-link" />
            ) : undefined}
            <span className="x-header-label">{label}</span>
            <i className={"x-icon-after fa " + orderClass} />
          </div>
        )}
      </th>
    );
  }
}

import * as R from "ramda";
import { Form } from "common/types/forms";
import { hasBehavior } from "common/api/behavior";
import { recordsApi } from "common/api/records";
import { behaveAs } from "common/entities";
import {
  isFkToEntityWithBehavior,
  looseColumnNameCheck,
} from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { getFormWithResolvedDefaults } from "common/form/defaults";
import { isForeignKey } from "common/functions/foreign-key";
import { getFormById, getFormByIdOrEntity } from "common/functions/forms";
import { getScheduledWorkOrderAssetIds } from "common/record/actions/functions/url";
import { getFormForClone } from "common/record/dependencies/clone";
import { getScheduledWODependencies } from "common/record/dependencies/scheduled-work-order";
import { getWorkOrderTaskDependencies } from "common/record/dependencies/work-order-task";
import {
  getPropertiesWithDefaultCalendar,
  getExtraPropsPartialForValue,
  getMatchingExtraProperties,
  resolveForeignKeysWithExtra,
} from "common/record/edit/functions";
import { defaultFormValue } from "common/record/edit/value";
import { getMatchingProperties } from "common/record/properties";
import { type StandardValue } from "common/record/types";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import {
  ForeignKeysMap,
  KeysOf,
  Properties,
  Record,
} from "common/types/records";
import { CopySettings } from "common/types/url";

function populateRecordByResolvedRelated(
  entities: Entities,
  entity: Entity,
  properties: Properties,
  resolvedProps: ForeignKeysMap,
): Properties {
  return (R.keys(properties) as KeysOf<Properties>).reduce((acc, column) => {
    if (!resolvedProps[column] || R.isEmpty(resolvedProps[column])) return acc;

    const extraProps =
      column === "parentId"
        ? // if we omit some columns (ie follow-up), getExtraPropsPartialForValue puts everything back again
          // this hack is to avoid this. RTS
          { parentId: resolvedProps[column] }
        : getExtraPropsPartialForValue(
            entities,
            entity,
            properties,
            column,
            resolvedProps[column],
          );

    return R.mergeRight(acc, extraProps) as Properties;
  }, properties);
}

/**
 * Filters out User foreign keys from properties
 */
function filterOutUserFks(
  properties: Properties,
  entity: Entity,
  entities: Entities,
): Properties {
  if (!entity?.columns) return properties;

  const isUserFk = isFkToEntityWithBehavior("User", entities);
  const userFkCols = entity.columns.reduce(
    (acc: string[], c) => (isUserFk(c) ? [...acc, c.name] : acc),
    [],
  );

  return R.omit(userFkCols, properties);
}

export function omitBlacklistedColumns(
  sourceEntity: Entity,
  targetEntity: Entity,
  properties: Properties,
) {
  return behaveAs("Asset", sourceEntity) &&
    (behaveAs("Request", targetEntity) || behaveAs("WorkOrder", targetEntity))
    ? R.pickBy(
        (_, column) => !looseColumnNameCheck(column, "description"),
        properties,
      )
    : properties;
}

function getMatchingSourceRecordProperties(
  context: Context,
  entity: Entity,
  copySettings: CopySettings,
  sourceRecord: Record,
  form?: Form,
): Properties {
  const sourceEntity = context.entities[copySettings?.sourceEntity];
  const propsWithoutBlacklisted = omitBlacklistedColumns(
    sourceEntity,
    entity,
    sourceRecord?.properties,
  );

  const allSourceProperties =
    copySettings?.copyType === "follow-up"
      ? filterOutUserFks(propsWithoutBlacklisted, entity, context.entities)
      : propsWithoutBlacklisted;

  return getMatchingProperties(sourceEntity, entity, allSourceProperties, form);
}

function shouldIncludeRelated(sourceEntity: Entity, targetEntity: Entity) {
  return (
    behaveAs("Task", sourceEntity) || behaveAs("ScheduledEvent", targetEntity)
  );
}

// Add the current site to SharedMultipleSites Entity and References
function getPropertiesWithSite(
  context: Context,
  entity: Entity,
  props: Properties,
) {
  return entity?.recordScope === "SharedMultipleSites" &&
    entity?.type !== "SubEntity"
    ? { ...props, sites: [context.site.name] }
    : props;
}

export function getRecordDependencies(
  context: Context,
  entity: Entity,
  isNew: boolean,
  defaultProps: Properties,
  formId: number,
  resolveDynamicValues: boolean = true,
  selectFormById: boolean = false,
  copySettings?: CopySettings,
) {
  const {
    copyType,
    sourceEntity,
    sourceId,
    sourceIds,
    targetColumn,
    includeRelated,
  } = copySettings ?? {};
  const fullSourceEntity = context.entities[sourceEntity];

  // if selectFormById is true, the form matching formId is used and not
  // the first entity form
  const form = selectFormById
    ? getFormById(context.forms, formId)
    : getFormByIdOrEntity(context.forms, entity?.name, formId);

  const sourceRecordRequest =
    sourceEntity && sourceId
      ? recordsApi(context.apiCall)
          .get(
            sourceEntity,
            sourceId,
            includeRelated || shouldIncludeRelated(fullSourceEntity, entity),
            false,
          )
          .catch(() => undefined)
      : CancellablePromise.resolve(undefined);

  return sourceRecordRequest.then((sourceRecord: Record) => {
    const defaultProperties = {
      ...getMatchingSourceRecordProperties(
        context,
        entity,
        copySettings,
        sourceRecord,
        form,
      ),
      ...getPropertiesWithDefaultCalendar(context, entity, defaultProps),
      ...(sourceId && targetColumn ? { [targetColumn]: sourceId } : {}),
    };

    return resolveForeignKeysWithExtra(
      context,
      entity,
      form,
      defaultProperties,
    ).then((resolvedProperties) => {
      const properties = getPropertiesWithSite(
        context,
        entity,
        populateRecordByResolvedRelated(
          context.entities,
          entity,
          defaultProperties,
          resolvedProperties,
        ),
      );

      const defaultForm = defaultFormValue(isNew, properties, form);

      if (!isNew) return CancellablePromise.resolve(defaultForm);

      if (copyType === "clone") {
        return getFormWithResolvedDefaults(
          context,
          entity,
          form,
          defaultForm,
          properties,
          undefined,
          resolveDynamicValues,
        ).then((formValue) =>
          getFormForClone(
            context,
            entity,
            formValue,
            sourceRecord,
            includeRelated,
          ),
        );
      }

      if (behaveAs("ScheduledEvent", entity)) {
        return getScheduledWODependencies(
          context,
          entity,
          properties,
          sourceIds ?? getScheduledWorkOrderAssetIds(entity, sourceRecord),
          isNew,
        );
      }

      if (hasBehavior(fullSourceEntity?.behaviors, "Task")) {
        return getWorkOrderTaskDependencies(
          context,
          entity,
          fullSourceEntity,
          sourceId,
          sourceRecord,
          defaultForm as StandardValue,
          form,
        );
      }

      const requestId = properties?.requestId;
      if (requestId && isForeignKey(requestId)) {
        const propertiesNotOverridableByFormDefaults =
          getMatchingExtraProperties(
            R.keys(requestId) as KeysOf<Properties>,
            R.keys(properties) as KeysOf<Properties>,
          );
        return getFormWithResolvedDefaults(
          context,
          entity,
          form,
          defaultForm,
          properties,
          // Do not apply form defaults to fields matching Work Order Request fields
          // Prevents overwriting empty Work Order request fields with defaults
          propertiesNotOverridableByFormDefaults,
          resolveDynamicValues,
        );
      }

      return getFormWithResolvedDefaults(
        context,
        entity,
        form,
        defaultForm,
        properties,
        undefined,
        resolveDynamicValues,
      );
    });
  });
}

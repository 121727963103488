import * as R from "ramda";
import { toDashedNoSpace } from "common";
import {
  ApiCall,
  ApiCallFull,
  ApiCallGeneric,
  ApiResponse,
  Method,
  Options,
} from "common/types/api";
import { ESignatureReason } from "common/types/reasons";

export const getData = <T>({ data }: ApiResponse<T>) => data;

export const getApiCallResolvingData =
  (apiCallFull: ApiCallFull): ApiCall =>
  <T>(method: Method, path: string, payload?: any, options?: Options) =>
    // for those interested in only "data" from response
    apiCallFull<T>(method, path, payload, options).then(getData);

export const withAuthAndReason =
  (
    apiCall: ApiCallGeneric,
    password: string,
    reason: ESignatureReason,
  ): ApiCallGeneric =>
  (method: Method, path: string, payload?: any, options?: Options) =>
    (apiCall as ApiCallFull)(
      method,
      path,
      reason ? { ...payload, reason } : payload,
      R.mergeRight(options, { headers: { Password: password } }) as Options,
    );

export const withSite =
  (apiCall: ApiCallGeneric, site: string): ApiCallGeneric =>
  <T>(method: Method, path: string, payload?: any, options?: Options) =>
    (apiCall as ApiCallFull)<T>(
      method,
      path.replace("/:site", `/${site}`),
      payload,
      options,
    );

export const withTenant =
  (apiCall: ApiCallGeneric, tenant: string): ApiCallGeneric =>
  <T>(method: Method, path: string, payload?: any, options?: Options) =>
    (apiCall as ApiCallFull)<T>(method, path, payload, {
      ...(options ?? {}),
      headers: {
        ...(options?.headers ?? {}),
        "X-Tenant": tenant,
      },
    });

export const withDownload =
  (apiCall: ApiCall, accept: string, fileName: string): ApiCall =>
  (method: Method, path: string, payload: any) =>
    apiCall<any>(method, path, payload, {
      download: true,
      accept,
    }).then((data) => {
      const safeFileName = toDashedNoSpace(fileName);

      const anchor = document.createElement("A");
      anchor.setAttribute("href", URL.createObjectURL(data));
      anchor.setAttribute("download", safeFileName);
      anchor.setAttribute("target", "_blank");
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      return data;
    });

import * as R from "ramda";
import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { Layout } from "common/form/types";
import { hasPermissionToRead } from "common/functions/roles";
import { shouldDisableRelatedEntity } from "common/record/disabled-related-entity";
import { StandardUiValue } from "common/record/types";
import { Context } from "common/types/context";
import { defaultRecord, Record } from "common/types/records";
import { InjectedProps, toggleable } from "common/widgets/toggleable";
import { withValue, WithValue } from "common/with-value";
import {
  getLabelFor,
  AUDIT_TRAIL,
  COMMENTS,
  GENERAL,
  OTHER,
  OVERVIEW,
  RELATED,
  REPORTS,
} from "../utils";
import { getRelated, getRelatedChanges, getSidebarElements } from "./functions";
import { SidebarElement } from "./types";
import { SidebarItem } from "./sidebar-item";

interface PropTypes {
  context: Context;
  mapElements?: (elements: SidebarElement[]) => SidebarElement[];
  entity: Entity;
  record: Record;
  ui?: StandardUiValue;
  layout: Layout;
  disabled: boolean;
  editing?: boolean;
  omitAuditTrail?: boolean;
  commentsCount?: number;
  isGeneralInformationInvalid?: boolean;
}

export type Props = PropTypes & InjectedProps & WithValue<SidebarElement>;

const overview: SidebarElement = {
  label: OVERVIEW,
};

export const generalInfo: SidebarElement = {
  label: GENERAL,
  isChild: true,
  isInvalid: false,
};

export const documents: SidebarElement = {
  label: "Documents",
  isChild: true,
};

const relatedTitle: SidebarElement = {
  label: RELATED,
};

const reportsTitle: SidebarElement = {
  label: REPORTS,
};

const otherTitle: SidebarElement = {
  label: OTHER,
};

export const auditTrail: SidebarElement = {
  label: AUDIT_TRAIL,
};

const childify = R.mergeRight({ isChild: true });

const defaultUi = defaultFor<StandardUiValue>();

const SidebarComp = ({
  context,
  entity,
  layout,
  disabled,
  omitAuditTrail,
  record = defaultRecord,
  ui = defaultUi,
  value = generalInfo,
  editing = false,
  toggleFn,
  toggleOpened,
  setValue,
  mapElements,
  commentsCount,
  isGeneralInformationInvalid,
}: Props) => {
  const uiRelated = ui.related?.form;
  const { entities } = context;
  const { related, properties } = record;
  const { reports, relatedEntities, staticEntries = [] } = layout;

  const relatedForSidebar = relatedEntities?.length
    ? relatedEntities.filter((e) => !e.showInOverview)
    : relatedEntities;

  const relatedEnts =
    !relatedForSidebar || relatedForSidebar.length
      ? getRelated(
          context,
          entity,
          relatedForSidebar,
          getRelatedChanges(uiRelated, related),
        )
      : [];

  const relatedSection =
    relatedEnts.length > 0
      ? [relatedTitle, ...relatedEnts.map(childify)]
      : relatedEnts;

  const rpts = (reports?.sidebar || []).map((report) => ({
    label: report.label,
    reportId: report.id,
  }));
  const reportsSection =
    rpts.length > 0 ? [reportsTitle, ...rpts.map(childify)] : rpts;

  const otherToOmit = !properties?.id ? [COMMENTS] : undefined;
  const otherElements = getSidebarElements(
    entity,
    context,
    staticEntries,
    otherToOmit,
  );
  const others =
    omitAuditTrail ||
    !hasPermissionToRead(context.userTypes, context.role, "AuditTrail") ||
    !properties?.id
      ? [...otherElements]
      : [...otherElements, auditTrail];

  const applyCommentsCount = (element: SidebarElement): SidebarElement => ({
    ...element,
    count: element.label === COMMENTS ? commentsCount : undefined,
  });

  const otherSection: SidebarElement[] =
    others.length > 0
      ? [otherTitle, ...others.map(childify).map(applyCommentsCount)]
      : others;

  const overviewSection = [
    overview,
    { ...generalInfo, isInvalid: isGeneralInformationInvalid },
  ];

  const columns: SidebarElement[] = R.filter(
    (c) => !!c,
    [...overviewSection, ...relatedSection, ...reportsSection, ...otherSection],
  );
  const sidebarElements = mapElements ? mapElements(columns) : columns;

  return (
    <div
      className={
        "x-sidebar x-padding-10" +
        (disabled ? " x-disabled" : "") +
        (editing ? " x-disable-grandchildren" : "") +
        (toggleOpened ? " x-show-sidebar" : " x-hide-sidebar")
      }
    >
      <div className="x-sidebar-dropdown" onClick={toggleFn}>
        <p>{value.label}</p>
        <i className="fa fa-chevron-down" />
      </div>
      <ul className={`list-group ${editing ? "x-padding-bottom-40" : ""}`}>
        {sidebarElements.map((element, i) => (
          <SidebarItem
            key={i}
            element={element}
            isActive={
              element.entity === value.label ||
              getLabelFor(element.label) === value.label
            }
            editing={editing}
            toggleOpened={toggleOpened}
            disabled={disabled}
            disabledEntity={
              element.entity &&
              shouldDisableRelatedEntity(
                entity,
                entities[element.entity],
                properties,
              )
            }
            toggleFn={toggleFn}
            setValue={setValue}
          />
        ))}
      </ul>
    </div>
  );
};

export const Sidebar = toggleable({ defaultOpened: false })(
  withValue<SidebarElement, PropTypes & InjectedProps>(SidebarComp, "Sidebar"),
);

import { ReactNode } from "react";
import { Component } from "common/component";
import { Modal, BasePropTypes as BaseModalProps } from ".";

interface PropTypes extends BaseModalProps {
  button: ReactNode;
  inputWithClear?: ReactNode;
  closeOnClick?: boolean;
  disabled?: boolean;
  isFocusable?: boolean;
  onCancel?: () => void;
  onOpen?: () => void;
}

interface StateType {
  open: boolean;
}

export class ButtonModal extends Component<PropTypes, StateType> {
  static readonly displayName = "ButtonModal";
  state: StateType = { open: false };

  onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.onClickOpen?.();
    }
  };

  onCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
    this.setState({ open: false });
  };

  onOk = () => {
    const { onOk } = this.props;
    if (onOk) onOk();
    this.setState({ open: false });
  };

  onClickOpen = () => {
    const { disabled, onOpen } = this.props;
    if (disabled) return;
    this.setState({ open: true }, onOpen);
  };

  render() {
    const {
      className = "",
      button,
      inputWithClear,
      content,
      closeOnClick,
      onOk,
      size,
      noPadding,
      title,
      hideFooter,
      okLabel,
      footerButtons,
      allowFullscreen,
      isFocusable = false,
    } = this.props;
    const { open } = this.state;

    return (
      <div className={`x-button-modal qa-button-modal ${className}`}>
        {inputWithClear}
        <div
          className="x-button-modal-button qa-button-modal-button x-customized-focus-element"
          tabIndex={isFocusable ? 0 : -1}
          onKeyDown={this.onKeyDown}
          onClick={this.onClickOpen}
        >
          {button}
        </div>
        {open ? (
          <Modal
            content={content}
            size={size}
            noPadding={noPadding}
            title={title}
            onContentClick={
              closeOnClick
                ? this.onChangeMergeDefaultState("open", false)
                : undefined
            }
            hideFooter={hideFooter}
            okLabel={okLabel}
            onOk={onOk ? this.onOk : undefined}
            footerButtons={footerButtons}
            onCancel={this.onCancel}
            allowFullscreen={allowFullscreen}
          />
        ) : undefined}
      </div>
    );
  }
}

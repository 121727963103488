import { Context } from "common/types/context";
import { Label } from "common/widgets/label";
import { getSiteLabelByName } from "x/account-settings/sites/functions";

type SitesLabelProps = {
  context: Context;
  value: string[] | string;
  textToHighlight: string;
};

export const SitesLabel = ({
  context,
  value,
  textToHighlight,
}: SitesLabelProps) => {
  const { site } = context;

  const sites = Array.isArray(value) ? value : [value];
  const selected = sites.find((s) => s === site.name) ?? sites[0];
  const label = getSiteLabelByName(context.sites, selected);

  return (
    <div className="x-multiple-labels">
      <div>
        <Label textToHighlight={textToHighlight} value={label} />
      </div>
      {sites.length > 1 ? (
        <div className="x-summary-more">{`+${sites.length - 1}`}</div>
      ) : undefined}
    </div>
  );
};

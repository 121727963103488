import { Component } from "react";
import { arrayToString } from "common/utils/array";
import { getColumn } from "common/entities";
import { isCustomOrSystemFk } from "common/entities/entity-column/functions";
import { FkValue, ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";
import { LookupPropTypes as PropTypes } from "../types";
import { MainForeignKey } from "./foreign-key";
import { DrilldownLevel } from "./level";
import {
  getEmptyOption,
  hasReadOnlyField,
  hasRestrictedColumn,
  INTERNAL_EMPTY,
  unwrap,
} from "./functions";

interface StateType {
  tempValue: Properties;
}

export class Drilldown extends Component<PropTypes, StateType> {
  constructor(props: PropTypes) {
    super(props);
    const { value, layoutColumn, mainFkColumnName } = props;
    const fkHasValue = !!value[mainFkColumnName];

    // if FK already set we replace null levels with an empty option
    const tempValue =
      layoutColumn.lookupConfiguration.mappedFields.reduce<Properties>(
        (acc, { columnName }) => {
          return {
            ...acc,
            [columnName]: value[columnName]
              ? unwrap(value[columnName], this.isLevelFk(columnName))
              : fkHasValue
                ? getEmptyOption()
                : undefined,
          };
        },
        value,
      );

    this.state = { tempValue };
  }

  isLevelFk = (columnName: string) => {
    const levelColumn = getColumn(this.props.entity, columnName);
    return isCustomOrSystemFk(levelColumn);
  };

  wrap = (columnName: string, levelValue: ForeignKey): FkValue => {
    const isEmpty = (levelValue?.id as any) === INTERNAL_EMPTY;
    return isEmpty
      ? undefined
      : this.isLevelFk(columnName)
        ? levelValue
        : levelValue?.id;
  };

  onDrilldownChange = (newValue: Properties) => {
    const { onChange, layoutColumn, value, mainFkColumnName } = this.props;

    this.setState({ tempValue: newValue });

    // removes internal state "empty" to undefined
    const drilldownLevelsValue =
      layoutColumn.lookupConfiguration.mappedFields.reduce<Properties>(
        (acc, { columnName }) => ({
          ...acc,
          [columnName]: this.wrap(columnName, newValue[columnName]),
        }),
        {},
      );

    onChange({
      ...value,
      ...drilldownLevelsValue,
      [mainFkColumnName]: newValue[mainFkColumnName],
    });
  };

  render() {
    const {
      context,
      entity,
      layoutColumn,
      mainFkColumnName,
      fieldRefs = {},
      formValidation,
      onFormValidationChange,
    } = this.props;
    const { tempValue } = this.state;
    const { lookupConfiguration } = layoutColumn || {};
    const { mappedFields } = lookupConfiguration || {};
    const mainFkColumn = getColumn(entity, mainFkColumnName);

    const isRestricted = hasRestrictedColumn(
      context,
      entity,
      mappedFields,
      mainFkColumnName,
    );
    const readOnly = hasReadOnlyField(entity, mappedFields, mainFkColumn);

    const mainFkColumnLabel = mainFkColumn?.localizedName;
    const tooltipMessage = arrayToString([
      _("Drilldown configuration for {FIELD_NAME} field.").replace(
        "{FIELD_NAME}",
        mainFkColumnLabel,
      ),
      isRestricted
        ? _("You are not allowed to edit drilldown level(s).")
        : readOnly
          ? _("Read-only field is used in drilldown configuration.")
          : _("Please select a value for each level to drill down."),
    ]);
    const tooltip = getTooltipProps(tooltipMessage, "info");

    return (
      <div className="x-drilldown-container">
        <span className="x-drilldown-info" {...tooltip}>
          <i className="fa fa-sort-amount-desc" />
        </span>
        {mappedFields.map((field, index) => {
          const column = getColumn(entity, field.columnName);
          return (
            <DrilldownLevel
              fieldRef={fieldRefs?.[field.columnName]}
              key={field.columnName}
              index={index}
              context={context}
              entity={entity}
              column={column}
              readOnly={isRestricted || readOnly}
              lookupConfiguration={lookupConfiguration}
              mainFkColumnName={mainFkColumnName}
              formValidation={formValidation}
              onFormValidationChange={onFormValidationChange}
              value={tempValue}
              onChange={this.onDrilldownChange}
            />
          );
        })}
        <MainForeignKey
          fieldRef={fieldRefs?.[mainFkColumnName]}
          context={context}
          column={mainFkColumn}
          layoutColumn={layoutColumn}
          readOnly={isRestricted || readOnly}
          mainFkColumnName={mainFkColumnName}
          value={tempValue}
          onChange={this.onDrilldownChange}
        />
      </div>
    );
  }
}

import * as R from "ramda";
import { useState } from "react";
import {
  getAllDescendantNames,
  getSitesDictionary,
  getSitesSubTree,
} from "common/functions/sites";
import { Context } from "common/types/context";
import { Site } from "common/types/sites";
import { Chicklets } from "common/widgets/chicklets";
import { SiteSelector } from "common/widgets/site-selector/index";
import { SiteSelectorTreeModal } from "common/widgets/site-selector/site-tree-modal";
import { getDisplaySiteFn } from "common/widgets/site-tree-selector-with-search/functions";
import { isGroupSite } from "x/account-settings/sites/functions";

interface RecordSitesSelectorProps {
  context: Context;
  readonly?: boolean;
  placeholder?: string;
  value: string[];
  onChange: (v: any) => void;
}

export const SitesSelectorWithChicklets = ({
  context,
  readonly,
  placeholder,
  value,
  onChange,
}: RecordSitesSelectorProps) => {
  const [selectedSite, setSelectedSite] = useState<Site>();

  const availableSites = context?.sites;
  const sitesDictionary = getSitesDictionary(availableSites);
  const displaySite = getDisplaySiteFn(sitesDictionary);
  const selectedSites = value
    ? availableSites.filter((s) => value.includes(s.name))
    : [];

  const onChangeSite = (sites: string[] = []) => onChange(sites);

  const onChangeSiteChicklets = (sites: Site[]) => {
    onChange(sites?.map((site) => site.name) || []);
  };

  const canDelete = readonly ? R.F : R.T;

  const onChickletClick = (site: Site) => {
    if (site.isGroup) setSelectedSite(site);
  };

  const isChickletClickable = (site: Site) => site.isGroup;

  const onTreeSelectorClose = () => {
    setSelectedSite(undefined);
  };

  const omitChildSites = (
    selectedSites: Site[],
    availableSites: Site[],
  ): Site[] => {
    const allChildSites = R.uniq(
      selectedSites.flatMap((selectedSite) =>
        isGroupSite(selectedSite)
          ? getAllDescendantNames(selectedSite, availableSites)
          : [],
      ),
    ).map((siteName) => availableSites.find((site) => site.name === siteName));

    return selectedSites.concat(allChildSites);
  };

  return (
    <>
      {!readonly && (
        <div className="form-group x-flex-grow-1">
          <SiteSelector
            context={context}
            sitesToOmit={omitChildSites(selectedSites, availableSites)}
            allowMultipleSelection={true}
            placeholder={placeholder}
            includeGroupSites={true}
            isExpandable={true}
            value={value}
            onChange={onChangeSite}
          />
        </div>
      )}
      <Chicklets<Site>
        className="x-margin-bottom-5 x-margin-top-5 qa-site-chicklets"
        canDeleteChicklet={canDelete}
        display={displaySite}
        isHighlightedChicklet={isGroupSite}
        value={selectedSites}
        onChange={onChangeSiteChicklets}
        isClickable={isChickletClickable}
        onClick={onChickletClick}
      />
      {selectedSite && context.sites.length > 0 ? (
        <SiteSelectorTreeModal
          sites={getSitesSubTree(selectedSite, context.sites)}
          isSiteDisabled={R.T}
          isExpandable={true}
          includeGroupSites={true}
          allowMultipleSelection={false}
          onClose={onTreeSelectorClose}
          value={value}
          onChange={onChange}
        />
      ) : undefined}
    </>
  );
};

import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import {
  createDefaultLayout,
  filterLayout,
} from "common/form/functions/layout";
import { Layout, RelatedEntity } from "common/form/types";
import { getFormById } from "common/functions/forms";
import { getReferenceQuery } from "common/functions/references";
import { getRelatedQuery } from "common/query/for-table";
import { Query } from "common/query/types";
import { updateTableQuery } from "common/record/form/content/related/requisitioning-item/functions";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { defaultRecord, Record } from "common/types/records";
import {
  OPEN,
  REQUESTED_BY,
  REQUISITIONING_STATUS,
} from "x/records/edit/behavior-form/requisitioning/consts";
import { PartRequisitionItem } from "./types";

export const getQueryForOpenRequisitions = (
  context: Context,
  entityName: string,
  userContact: ForeignKey,
): Query => {
  const defaultQuery = getReferenceQuery(context, context.entities[entityName]);
  return {
    ...defaultQuery,
    filter: {
      and: [
        {
          name: REQUESTED_BY,
          op: "eq",
          value: userContact?.id,
          excludeFromFkExpansion: true,
        },
        { name: REQUISITIONING_STATUS, op: "eq", value: OPEN },
      ],
    },
    restrict: ["Requisitioning"],
    select: context.entities[entityName].query?.select,
  };
};

export const getPartsClassification = (
  relatedRecords: Record[],
  selectedParts: PartRequisitionItem[],
) => {
  return selectedParts.reduce(
    (classification, selectedPart) => {
      const matchingRecord = relatedRecords.find((record) => {
        return (
          selectedPart.partId.id === record.properties.partId.id &&
          selectedPart.partLocationId === record.properties.partLocationId.id
        );
      });

      if (matchingRecord) {
        classification.duplicateParts.push({
          ...selectedPart,
          quantity: selectedPart.quantity + matchingRecord.properties.quantity,
          id: matchingRecord.properties.id,
        });
      } else {
        classification.uniqueParts.push({ ...selectedPart, isNew: true });
      }

      return classification;
    },
    {
      duplicateParts: [] as PartRequisitionItem[],
      uniqueParts: [] as PartRequisitionItem[],
    },
  );
};

const getLayout = (
  context: Context,
  entity: Entity,
  selectedPartRequisition: Record,
): Layout => {
  const { properties } = selectedPartRequisition ?? defaultRecord;
  const formId = properties?.formId;
  const { forms } = context;
  const form = getFormById(forms, formId);

  return form
    ? filterLayout((c) => !!getColumn(entity, c.columnName), form.settings)
    : createDefaultLayout(entity, context, properties, false);
};

export const getTableQuery = (
  context: Context,
  entity: Entity,
  relatedRecords: Record[],
  selectedPartRequisition: Record,
) => {
  const status =
    selectedPartRequisition?.properties?.[REQUISITIONING_STATUS]?.id || OPEN;
  const layout: Layout = getLayout(context, entity, selectedPartRequisition);
  const entityConfig: RelatedEntity = layout?.relatedEntities?.[0] ?? undefined;

  const query = getRelatedQuery(
    entity,
    context.entities,
    entityConfig,
    relatedRecords,
    false,
  );

  return updateTableQuery(query, status);
};

import { getLocalizedName } from "common";
import { Section } from "common/record/form/content/detail/view/section-group";
import { RelatedRecordsForm } from "common/record/form/content/related";
import {
  getEntityConfig,
  getPartsEntity,
} from "x/scheduler2/workorder-view/functions";
import type { SectionProps } from "x/scheduler2/workorder-view/section/types";

export const PartsSection = (props: SectionProps) => {
  const { context, workOrderEntityName, relatedValue, workOrderId } = props;
  const requirementEntity = getPartsEntity(
    workOrderEntityName,
    context.entities,
  );
  if (!requirementEntity) {
    return null;
  }

  const entityConfig = getEntityConfig(
    context,
    relatedValue?.record?.properties?.formId,
    requirementEntity.name,
  );

  return (
    <Section
      className="qa-WorkOrderView-parts"
      title={getLocalizedName(requirementEntity)}
    >
      <RelatedRecordsForm
        context={context}
        parentEntity={context.entities[workOrderEntityName]}
        entity={requirementEntity}
        recordId={workOrderId}
        entityConfig={entityConfig}
        value={relatedValue}
        onChange={undefined}
        isTemplate={false}
        displayTypes={["table"]}
        recordDetail={undefined}
        reload={undefined}
      />
    </Section>
  );
};

PartsSection.displayName = "PartsSection";

import { JSX, MouseEvent } from "react";
import { classNames } from "common/utils/jsx";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";

import "./chicklet.scss";

export type ChickletSize = "small" | "large";

interface PropTypes<T = string> {
  className?: string;
  content: string | JSX.Element | T;
  size?: ChickletSize;
  isHighlighted?: boolean;
  isValid?: boolean;
  isDisabled?: boolean;
  invalidMessage?: string | JSX.Element;
  canDelete?: boolean;
  onDelete?: (e: MouseEvent) => void;
  onClick?: (e: MouseEvent) => void;
}

export function Chicklet<T = string>({
  content,
  size,
  isHighlighted = false,
  isValid = true,
  isDisabled = false,
  invalidMessage,
  canDelete = true,
  onDelete,
  onClick,
  className,
}: PropTypes<T>) {
  const chickletClassNames = classNames([
    `x-chicklet${size ? "-" + size : ""}`,
    isHighlighted ? "x-highlighted" : undefined,
    !isValid ? "x-invalid" : undefined,
    canDelete ? "x-chicklet-removable" : undefined,
    "qa-chicklet",
    className,
  ]);

  const errorTooltipProps = !isValid
    ? getTooltipProps(invalidMessage, "error")
    : undefined;

  const chickletLabel = typeof content === "string" ? content : undefined;
  const chickletContent = (
    <>
      {!isValid ? (
        <i
          className="fa fa-warning qa-warning-icon"
          role="img"
          aria-label="warning icon"
        />
      ) : undefined}
      {typeof content === "string" ? (
        <div className="x-chicklet-label qa-chicklet-label">{content}</div>
      ) : (
        content
      )}
    </>
  );

  return (
    <div
      className={chickletClassNames}
      data-testid="chicklet"
      {...errorTooltipProps}
    >
      {onClick ? (
        <button
          className="x-chicklet-label-container x-chicklet-label-link"
          onClick={onClick}
          aria-label={chickletLabel}
        >
          {chickletContent}
        </button>
      ) : (
        <div className="x-chicklet-label-container" aria-label={chickletLabel}>
          {chickletContent}
        </div>
      )}

      {!isDisabled && canDelete ? (
        <button
          className="x-chicklet-remove qa-chicklet-remove"
          data-testid="chicklet-remove"
          onClick={onDelete}
        >
          <i className="fa fa-close" role="img" aria-label="closing icon" />
        </button>
      ) : undefined}
    </div>
  );
}

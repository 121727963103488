import { Component, Fragment } from "react";
import { recordsApi } from "common/api/records";
import { getEntityByBehavior } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { WidgetOverwrite } from "common/form/types";
import { filterFormsByEntity, getFormById } from "common/functions/forms";
import { Section } from "common/record/form/content/detail/view/section-group";
import {
  PART_LOCATION,
  QUANTITY,
  REQUISITIONING,
} from "common/record/form/content/related/requisitioning-item/consts";
// eslint-disable-next-line import/no-cycle
import { RelatedEntityTable } from "common/record/related-entity-table";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { Form } from "common/types/forms";
import { Record } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueProps } from "common/with-value-for";
import { loadUserContact } from "x/records/edit/behavior-form/requisitioning/functions/common";
import { findRequiredColumns, getFakeEntityColumns } from "../../functions";
import { RequisitionItem } from "../new-requisition/item";
import {
  getPartsClassification,
  getTableQuery,
  getQueryForOpenRequisitions,
} from "../functions";
// eslint-disable-next-line import/no-cycle
import { RequisitionGroupFields } from "../requisition-group-field";
import { PartRequisitionItem, Requisition } from "../types";

interface PropTypes extends ValueProps<Requisition> {
  context: Context;
  entity: Entity;
  partRequisitionEntity: Entity;
}

interface StateTypes {
  loading: boolean;
  error: ApiErrorResponse;
  partRequisitionId: string;
  requisitionItemForms: Form[];
  selectedPartRequisition: Record;
  requiredItemColumns: EntityColumn[];
  duplicateParts: PartRequisitionItem[];
  uniqueParts: PartRequisitionItem[];
}

export class AddToExistingRequisition extends Component<PropTypes, StateTypes> {
  static readonly displayName = "AddToExistingRequisition";

  constructor(props: PropTypes) {
    super(props);
    this.state = {
      loading: false,
      error: undefined,
      partRequisitionId: undefined,
      requisitionItemForms: undefined,
      selectedPartRequisition: undefined,
      requiredItemColumns: [],
      duplicateParts: [],
      uniqueParts: [],
    };
  }

  componentDidMount() {
    const { context, partRequisitionEntity, onChange, value } = this.props;

    loadUserContact(context, partRequisitionEntity)
      .then((userContact) => {
        onChange({
          ...value,
          partRequisition: {
            ...value.partRequisition,
            requestedBy: userContact,
          },
        });
      })
      .catch((error) => error);
  }

  onChangeRequisitionRecord = (requisitionDetails: Requisition) => {
    const {
      context,
      partRequisitionEntity,
      onChange,
      value: { partRequisition },
    } = this.props;
    const partRequisitionId = requisitionDetails.partRequisitionId.id;
    this.setState({ loading: true });

    recordsApi(context.apiCall)
      .get(partRequisitionEntity.name, partRequisitionId, true)
      .then((selectedPartRequisition) => {
        const relatedRecords: Record[] =
          selectedPartRequisition?.related?.PartRequisitionItems ?? [];
        const { duplicateParts, uniqueParts } = getPartsClassification(
          relatedRecords,
          partRequisition.partRequisitionItems,
        );
        const updatedPartRequisitionItems = [...uniqueParts, ...duplicateParts];
        const partRequisitionItemEntity = getEntityByBehavior(
          "RequisitioningItem",
          context.entities,
        );
        const requisitionItemForms: Form[] = filterFormsByEntity(
          context.forms,
          partRequisitionItemEntity.name,
        );
        const requiredItemColumns = this.findRequiredItemColumns(
          requisitionItemForms[0]?.id,
        );
        const isValid = this.isFormValid(
          uniqueParts,
          duplicateParts,
          requiredItemColumns,
        );

        this.setState({
          selectedPartRequisition,
          partRequisitionId,
          requisitionItemForms,
          loading: false,
          requiredItemColumns,
          duplicateParts,
          uniqueParts,
        });
        onChange({
          ...requisitionDetails,
          isValid,
          selectedPartRequisition,
          partRequisition: {
            ...partRequisition,
            partRequisitionItems: updatedPartRequisitionItems,
          },
        });
      })
      .catch((error) => this.setState({ error, loading: false }));
  };

  findRequiredItemColumns = (formId: number) => {
    const { context, entity } = this.props;

    const partRequisitionItemEntity = getEntityByBehavior(
      "RequisitioningItem",
      context.entities,
    );
    const requisitionItemsForms: Form[] = filterFormsByEntity(
      context.forms,
      partRequisitionItemEntity.name,
    );
    const form = getFormById(requisitionItemsForms, formId);
    const requiredItemColumns = findRequiredColumns(
      partRequisitionItemEntity,
      form,
      entity.columns,
    ).filter(
      (itemColumn) =>
        !(
          (itemColumn.name && itemColumn.name === PART_LOCATION) ||
          itemColumn.name === REQUISITIONING
        ),
    );

    return requiredItemColumns;
  };

  getGroupField = (columnName: string, widgetsProps?: WidgetOverwrite) => {
    const { context, value } = this.props;
    const columns = getFakeEntityColumns(context);

    return (
      <RequisitionGroupFields
        key={columnName}
        context={context}
        columnName={columnName}
        columns={columns}
        widgetsProps={widgetsProps}
        value={value}
        onChange={this.onChangeRequisitionRecord}
      />
    );
  };

  isFormValid = (
    uniqueParts: PartRequisitionItem[],
    duplicateParts: PartRequisitionItem[],
    requiredItemColumns: EntityColumn[],
  ) => {
    const isRequiredColumnsValid =
      requiredItemColumns.every((column) =>
        uniqueParts.every(
          (item) => item[column.name] != null && item.quantity > 0,
        ),
      ) && duplicateParts.every((item) => item.quantity > 0);

    return isRequiredColumnsValid;
  };

  onRequisitionItemChange = (newRequisitionItem: PartRequisitionItem) => {
    const { onChange, value } = this.props;
    const { duplicateParts, uniqueParts, requisitionItemForms } = this.state;
    const { partId, locationId, formId } = newRequisitionItem;
    const updatedUniqueParts = uniqueParts.map((part) =>
      part.partId.id === partId.id && part.locationId === locationId
        ? newRequisitionItem
        : part,
    );
    const updatedDuplicateParts = duplicateParts.map((part) =>
      part.partId.id === partId.id && part.locationId === locationId
        ? newRequisitionItem
        : part,
    );
    const updatedPartRequisitionItems = [
      ...updatedUniqueParts,
      ...updatedDuplicateParts,
    ];
    const requiredItemColumns = this.findRequiredItemColumns(
      formId || requisitionItemForms[0]?.id,
    );
    const isValid = this.isFormValid(
      updatedUniqueParts,
      updatedDuplicateParts,
      requiredItemColumns,
    );

    this.setState(
      {
        uniqueParts: updatedUniqueParts,
        duplicateParts: updatedDuplicateParts,
        requiredItemColumns,
      },
      () => {
        onChange({
          ...value,
          isValid,
          partRequisition: {
            ...value.partRequisition,
            partRequisitionItems: updatedPartRequisitionItems,
          },
        });
      },
    );
  };

  render() {
    const {
      context,
      partRequisitionEntity,
      value: { partRequisition },
    } = this.props;
    const {
      error,
      loading,
      selectedPartRequisition,
      partRequisitionId,
      requiredItemColumns,
      duplicateParts,
      uniqueParts,
    } = this.state;
    const relatedRecords = selectedPartRequisition?.related
      ? selectedPartRequisition.related.PartRequisitionItems
      : [];
    const fakeEntityColumns = getFakeEntityColumns(context);
    const widgetsProps: WidgetOverwrite = {
      query: getQueryForOpenRequisitions(
        context,
        partRequisitionEntity.name,
        partRequisition.requestedBy,
      ),
    };
    const quantityColumn = requiredItemColumns.filter(
      (itemColumn) => itemColumn.name === QUANTITY,
    );

    return (
      <div className="x-record-detail-view x-margin-0-i">
        {loading && <LoadingIcon />}
        {error && <ApiError error={error} />}
        <Section>
          {this.getGroupField("partRequisitionId", widgetsProps)}
        </Section>
        {partRequisitionId && (
          <div>
            <Section title={_("Part Requisition Items")}>
              <RelatedEntityTable
                context={context}
                query={getTableQuery(
                  context,
                  partRequisitionEntity,
                  relatedRecords,
                  selectedPartRequisition,
                )}
                parentEntityName={partRequisitionEntity.name}
                entity={partRequisitionEntity}
                recordId={partRequisitionId}
                orderBy={undefined}
                actions={undefined}
                ignore={undefined}
                value={relatedRecords}
                onChange={undefined}
                withLinks={false}
              />
            </Section>
            {uniqueParts.length > 0 && (
              <Section
                title={_("Parts To Be Added")}
                className="x-padding-top-20"
              >
                {uniqueParts.map((part, index) => (
                  <Fragment key={`${part.partId?.id}${part.locationId?.id}`}>
                    {index > 0 && <hr />}
                    <RequisitionItem
                      context={context}
                      columns={fakeEntityColumns}
                      requiredItemColumns={requiredItemColumns}
                      isFirst={index === 0}
                      value={part}
                      onChange={this.onRequisitionItemChange}
                    />
                  </Fragment>
                ))}
              </Section>
            )}
            {duplicateParts.length > 0 && (
              <Section
                title={_("Edit Existing Parts")}
                className="x-padding-top-20"
              >
                {duplicateParts.map((part, index) => (
                  <Fragment key={`${part.partId?.id}${part.locationId?.id}`}>
                    {index > 0 && <hr />}
                    <RequisitionItem
                      context={context}
                      columns={fakeEntityColumns}
                      requiredItemColumns={quantityColumn}
                      showHint={true}
                      isFirst={false}
                      value={part}
                      onChange={this.onRequisitionItemChange}
                    />
                  </Fragment>
                ))}
              </Section>
            )}
          </div>
        )}
      </div>
    );
  }
}

import { hasBehavior } from "common/api/behavior";
import { recordsApi } from "common/api/records";
import { ApproveValue } from "common/record/actions/ui/approve/types";
import {
  CreateFollowUp,
  FollowUpValue,
} from "common/record/actions/ui/create-follow-up";
import { Behavior } from "common/types/behaviors";
import { withValue, WithValue } from "common/with-value";
// eslint-disable-next-line import/no-cycle
import { SubmitPartsCount } from "common/record/actions/ui/submit-parts-count";
import { CreatePurchaseOrderFromReorderList } from "common/record/actions/ui/parts-reorder/purchase-order";
import { getPartsRecordProperties } from "common/record/actions/ui/parts-reorder/purchase-order/functions";
import { getEntityByBehavior } from "common/entities";
import { simpleAction } from "./simple-action";
// eslint-disable-next-line import/no-cycle
import { simpleGenericWorkOrderAction } from "./simple-generic-wo-action";
import { ActionHandlers, PropTypes } from "./types";
import { Approve } from "./ui/approve";
import { Create, CreateValue } from "./ui/create";
import { Delete } from "./ui/delete";
import { EmailList, EmailListValue } from "./ui/email-list";
import { SendEmailModal, SendEmailValue } from "./ui/email-record";
import { Reject, RejectValue } from "./ui/reject";
// eslint-disable-next-line import/no-cycle
import { ApproveRequisition } from "./ui/requisitioning/approve";
// eslint-disable-next-line import/no-cycle
import { CreatePurchaseOrder } from "./ui/requisitioning/create-purchase-order";
import { Reschedule, RescheduleValue } from "./ui/reschedule";
import { Sign, SignValue } from "./ui/sign";
import { SwitchForm, SwitchFormValue } from "./ui/switch-form";
// eslint-disable-next-line import/no-cycle
import { UpdateListModal, UpdateListValue } from "./ui/update-list";
import { PrintableLabelModal, PrintableLabelValue } from "./ui/printable-label";
import { ReceiveItems } from "./ui/purchase-order/receive-items";
import { ClosePo } from "./ui/purchase-order/close";
import { ReturnItems } from "./ui/purchase-order/return-items";
import {
  LiveAssetSettingsModal,
  LiveAssetSettingsValue,
} from "./ui/live-asset-settings";
import { Open } from "./ui/open";
import { Trigger } from "./ui/trigger";
import { ExportToPdfModal, ExportToPdfValue } from "./ui/export-to-pdf";
import { ChangeSite } from "./ui/change-site";
import { CreateRequisitionFromReorderList } from "./ui/parts-reorder/requisition";


export interface ActionHandlerValue {
  approve: ApproveValue;
  create: CreateValue;
  email: SendEmailValue;
  emailList: EmailListValue;
  exportToPdf: ExportToPdfValue;
  followUp: FollowUpValue;
  printableLabel: PrintableLabelValue;
  reject: RejectValue;
  reschedule: RescheduleValue;
  sign: SignValue;
  switchForm: SwitchFormValue;
  updateList: UpdateListValue;
  liveAssetSettings: LiveAssetSettingsValue;
}

export type Props = PropTypes & WithValue<ActionHandlerValue>;

const commonHandlers = (): ActionHandlers<ActionHandlerValue, PropTypes> => ({
  Approve: {
    label: _("Approve"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <Approve
          {...props}
          value={props.value?.approve}
          onChange={props.onChangeMergeValue("approve")}
        />
      ),
      "Approve",
    ),
  },
  ApproveRequisition: {
    label: _("Approve Request"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <ApproveRequisition {...props} />,
      "Approve Request",
    ),
  },
  ChangeSite: {
    label: _("Update Site(s)"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <ChangeSite {...props} />,
      "ChangeSite",
    ),
  },
  Close: simpleGenericWorkOrderAction<ActionHandlerValue>(
    _("Close"),
    _("Close"),
    "closeOut",
    (apiCall, entityName, payload) =>
      recordsApi(apiCall).bulkClose(entityName, payload),
  ),
  CloseBulk: simpleGenericWorkOrderAction<ActionHandlerValue>(
    _("Close"),
    _("Close records"),
    "closeOut",
    (apiCall, entityName, payload) =>
      recordsApi(apiCall).bulkClose(entityName, payload),
  ),
  Complete: simpleGenericWorkOrderAction<ActionHandlerValue>(
    _("Complete"),
    _("Complete"),
    "complete",
    (apiCall, entityName, payload) =>
      recordsApi(apiCall).bulkComplete(entityName, payload),
  ),
  CompleteBulk: simpleGenericWorkOrderAction<ActionHandlerValue>(
    _("Complete"),
    _("Complete records"),
    "complete",
    (apiCall, entityName, payload) =>
      recordsApi(apiCall).bulkComplete(entityName, payload),
  ),
  Create: {
    label: _("Create"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <Create
          {...props}
          value={props.value?.create}
          onChange={props.onChangeMergeValue("create")}
        />
      ),
      "Create",
    ),
  },
  CreateFollowUp: {
    label: _("Create Follow-up"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <CreateFollowUp
          {...props}
          value={props.value?.followUp}
          onChange={props.onChangeMergeValue("followUp")}
        />
      ),
      "CreateFollowUp",
    ),
  },
  Delete: {
    label: _("Archive"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <Delete {...props} />,
      "Delete",
    ),
  },
  DeleteBulk: {
    label: _("Archive records"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <Delete {...props} />,
      "DeleteBulk",
    ),
  },
  Email: {
    label: _("Send by email"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <SendEmailModal
          {...props}
          value={props.value?.email}
          onChange={props.onChangeMergeValue("email")}
        />
      ),
      "SendEmail",
    ),
  },
  EmailList: {
    label: _("Send results by email"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <EmailList
          {...props}
          value={props.value?.emailList}
          onChange={props.onChangeMergeValue("emailList")}
        />
      ),
      "SendEmailList",
    ),
  },
  ExportLabel: {
    label: _("Export Label"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <PrintableLabelModal
          {...props}
          value={props.value?.printableLabel}
          onChange={props.onChangeMergeValue("printableLabel")}
        />
      ),
      "ExportLabel",
    ),
  },
  ExportLabelBulk: {
    label: _("Export selected as labels"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <PrintableLabelModal
          {...props}
          value={props.value?.printableLabel}
          onChange={props.onChangeMergeValue("printableLabel")}
        />
      ),
      "ExportLabelBulk",
    ),
  },
  ExportToPdf: {
    label: _("Export to PDF"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <ExportToPdfModal
          {...props}
          value={props.value?.exportToPdf}
          onChange={props.onChangeMergeValue("exportToPdf")}
        />
      ),
      "ExportToPdf",
    ),
  },
  LiveAssetSettings: {
    label: _("LIVE-Asset Settings"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <LiveAssetSettingsModal
          {...props}
          value={props.value?.liveAssetSettings}
          onChange={props.onChangeMergeValue("liveAssetSettings")}
        />
      ),
      "LiveAssetSettings",
    ),
  },
  Open: {
    label: _("Open"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <Open {...props} />,
      "Open",
    ),
  },
  OpenBulk: {
    label: _("Open records"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <Open {...props} />,
      "OpenBulk",
    ),
  },
  UpdateBulk: {
    label: _("Mass change values"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <UpdateListModal
          {...props}
          value={props.value?.updateList}
          onChange={props.onChangeMergeValue("updateList")}
        />
      ),
      "UpdateBulk",
    ),
  },
  Reject: {
    label: _("Reject"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <Reject
          {...props}
          value={props.value?.reject}
          onChange={props.onChangeMergeValue("reject")}
        />
      ),
      "Reject",
    ),
  },
  RejectRequisition: simpleAction<ActionHandlerValue>(
    _("Reject"),
    _("Reject Request"),
    (apiCall, entityName, recordIds = []) =>
      recordsApi(apiCall).rejectRequisition(entityName, recordIds[0]),
  ),
  ReOpen: simpleAction<ActionHandlerValue>(
    _("Reopen"),
    _("Reopen"),
    (apiCall, entityName, recordIds) =>
      recordsApi(apiCall).reopen(entityName, recordIds[0]),
  ),
  Cancel: simpleAction<ActionHandlerValue>(
    _("Cancel"),
    _("Cancel"),
    (apiCall, entityName, recordIds) =>
      recordsApi(apiCall).cancel(entityName, recordIds[0]),
    _("Ok"),
  ),
  ReOpenBulk: simpleAction<ActionHandlerValue>(
    _("Reopen"),
    _("Reopen records"),
    (apiCall, entityName, recordIds) =>
      recordsApi(apiCall).bulkReopen(entityName, recordIds),
  ),
  ReSchedule: {
    label: _("Reschedule"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <Reschedule
          {...props}
          value={props.value?.reschedule}
          onChange={props.onChangeMergeValue("reschedule")}
        />
      ),
      "Reschedule",
    ),
  },
  Restore: simpleAction<ActionHandlerValue>(
    _("Restore"),
    _("Restore"),
    (apiCall, entityName, recordIds) =>
      recordsApi(apiCall).restore(entityName, recordIds[0]),
  ),
  RestoreBulk: simpleAction<ActionHandlerValue>(
    _("Restore"),
    _("Restore records"),
    (apiCall, entityName, recordIds) =>
      recordsApi(apiCall).bulkRestore(entityName, recordIds),
  ),
  Send: simpleAction<ActionHandlerValue>(
    _("Submit PO"),
    _("Submit PO"),
    (apiCall, entityName, recordIds) =>
      recordsApi(apiCall).send(entityName, recordIds[0]),
  ),
  Sign: {
    label: _("Signature sign-off"),
    Ui: withValue<ActionHandlerValue, PropTypes>((props) => {
      const image = props.records?.[0]?.properties?.signature;

      const value = props.value ? props.value.sign : { image, password: "" };

      return (
        <Sign
          {...props}
          value={value}
          onChange={props.onChangeMergeValue("sign")}
        />
      );
    }, "Sign"),
  },
  SubmitRequisition: simpleAction<ActionHandlerValue>(
    _("Submit Requisition"),
    _("Submit Requisition"),
    (apiCall, entityName, recordIds) =>
      recordsApi(apiCall).bulkSubmitRequisition(entityName, [recordIds[0]]),
  ),
  SubmitRequisitionBulk: simpleAction<ActionHandlerValue>(
    _("Submit Requisitions"),
    _("Submit Requisitions"),
    (apiCall, entityName, recordIds) =>
      recordsApi(apiCall).bulkSubmitRequisition(entityName, recordIds),
  ),
  SwitchForm: {
    label: _("Switch Form"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <SwitchForm
          {...props}
          value={props.value?.switchForm}
          onChange={props.onChangeMergeValue("switchForm")}
        />
      ),
      "SwitchForm",
    ),
  },
  SubmitPartsCount: {
    label: _("Submit Ready Counts"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <SubmitPartsCount {...props} />,
      "SubmitPartsCount",
    ),
  },
  Trigger: {
    label: _("Trigger"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <Trigger {...props} />,
      "Trigger",
    ),
  },
  TriggerBulk: {
    label: _("Trigger records"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <Trigger {...props} />,
      "TriggerBulk",
    ),
  },
});

const poHandlers = (): ActionHandlers<ActionHandlerValue, PropTypes> => ({
  Receive: {
    label: _("Receive Items"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <ReceiveItems {...props} />,
      "Receive",
    ),
  },
  Close: {
    label: _("Close"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <ClosePo {...props} />,
      "Close",
    ),
  },
  Return: {
    label: _("Return Items"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <ReturnItems {...props} />,
      "Return",
    ),
  },
});

const partsReorderHandler = (): ActionHandlers<
  ActionHandlerValue,
  PropTypes
> => ({
  CreatePurchaseOrder: {
    label: _("Create Purchase Order"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => (
        <CreatePurchaseOrderFromReorderList
          reorderRecords={getPartsRecordProperties(props.records)}
          {...props}
        />
      ),
      "Create Purchase Order",
    ),
  },
  CreateRequisition: {
    label: _("Create Part Requisition"),
    Ui: withValue<ActionHandlerValue, PropTypes>((props) => {
      return (
        <CreateRequisitionFromReorderList
          reorderRecords={getPartsRecordProperties(props.records)}
          partRequisitionEntity={getEntityByBehavior(
            "Requisitioning",
            props.context.entities,
          )}
          {...props}
        />
      );
    }, "Create Part Requisition"),
  },
});

const requisitionHandler = (): ActionHandlers<
  ActionHandlerValue,
  PropTypes
> => ({
  CreatePurchaseOrder: {
    label: _("Create Purchase Order"),
    Ui: withValue<ActionHandlerValue, PropTypes>(
      (props) => <CreatePurchaseOrder {...props} />,
      "Create Purchase Order",
    ),
  },
});

export const getActionHandlersByBehaviors = (
  behaviors: Behavior[],
): ActionHandlers<ActionHandlerValue, PropTypes> => ({
  ...commonHandlers(),
  ...(hasBehavior(behaviors, "PurchaseOrder") ? poHandlers() : undefined),
  ...(hasBehavior(behaviors, "Requisitioning")
    ? requisitionHandler()
    : undefined),
  ...(hasBehavior(behaviors, "PartsReorder")
    ? partsReorderHandler()
    : undefined),
});

import { HighlightProps, Label } from "common/widgets/label";

interface PropTypes extends HighlightProps {
  url: string;
  label?: string;
}

export const WebLinkLabel = ({ url, label, textToHighlight }: PropTypes) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <Label value={label ?? url} textToHighlight={textToHighlight} />
  </a>
);
WebLinkLabel.displayName = "WebLinkLabel";

import { Context } from "common/types/context";
import { defaultFilter as defaultValue, Filter } from "common/types/filters";
import { DeleteButton } from "common/ui/buttons";
import { Modal } from "common/widgets/modal";
import { ValueProps } from "common/with-value-for";
import { getFiltersPermissions } from "x/records/list/functions";
import { SaveFilterState } from "x/records/list/types";
import { FilterContentForm } from "./content";

interface PropTypes extends ValueProps<Filter> {
  saveFilterState: SaveFilterState;
  lastFilterName: string;
  filters: Filter[];
  context: Context;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
}

const isDuplicateFilterName = (
  isNewFilter: boolean,
  lastFilterName: string,
  filters: Filter[] = [],
  name: string = "",
) =>
  filters.some((f) => f.name.toLowerCase() === name.toLowerCase()) &&
  (isNewFilter || lastFilterName !== name);

const isValidFilter = (
  isNewFilter: boolean,
  lastFilterName: string,
  filters: Filter[],
  filter: Filter,
) =>
  !!filter?.name &&
  !isDuplicateFilterName(isNewFilter, lastFilterName, filters, filter.name) &&
  (!filter.shared || filter.sites?.length > 0);

const isOkLabel = (saveFilterState: SaveFilterState) => {
  switch (saveFilterState) {
    case "new":
    case "edit":
      return _("Save");
    case "update":
      return _("Update");
  }
};

const getTitle = (saveFilterState: SaveFilterState) => {
  switch (saveFilterState) {
    case "new":
      return _("Save new view");
    case "edit":
      return _("Edit current view");
    case "update":
      return _("Update current view");
  }
};

export const SaveFilterModal = ({
  saveFilterState,
  lastFilterName,
  filters,
  context,
  onSave,
  onCancel,
  onDelete,
  value = defaultValue,
  onChange,
}: PropTypes) => {
  const { canCreateShared, canDelete } = getFiltersPermissions(context, [
    "CreateShared",
    "Delete",
  ]);

  const footerButtons =
    saveFilterState === "edit" && canDelete ? (
      <DeleteButton className="x-float-left qa-btn-delete" onClick={onDelete} />
    ) : undefined;

  return (
    <Modal
      content={
        <FilterContentForm
          saveFilterState={saveFilterState}
          lastFilterName={lastFilterName}
          filters={filters}
          canCreateShared={canCreateShared}
          context={context}
          onSave={onSave}
          value={value}
          onChange={onChange}
        />
      }
      title={getTitle(saveFilterState)}
      okLabel={isOkLabel(saveFilterState)}
      onOk={
        isValidFilter(saveFilterState === "new", lastFilterName, filters, value)
          ? onSave
          : undefined
      }
      onCancel={onCancel}
      footerButtons={footerButtons}
    />
  );
};

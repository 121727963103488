import { JSX } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH_CONFIG } from "./config";

export const withAuth0Provider = (
  children: JSX.Element,
  clientId: string,
  organization?: string,
) => (
  <Auth0Provider
    domain={AUTH_CONFIG.domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: AUTH_CONFIG.callbackUrl,
      audience: AUTH_CONFIG.audience,
      response_type: AUTH_CONFIG.responseType,
      scope: AUTH_CONFIG.defaultScope,
      organization: organization,
    }}
  >
    {children}
  </Auth0Provider>
);

import * as R from "ramda";
import { Entities, Entity } from "common/entities/types";
import {
  checkFormValidation,
  isFormWithDynamicValuesValid,
} from "common/form/functions/validation";
import { FormValidation, Layout } from "common/form/types";
import { ScheduledEventValue } from "common/record/types";
import { isNotDeletedRecord } from "common/record/utils";
import {
  Inspection,
  InspectionTrigger,
  Pm,
  PmTrigger,
  ScheduledEvent,
  ScheduledEventAssetRecord,
  ScheduledEventRecord,
} from "common/types/scheduled-event";
import { Context } from "common/types/context";
import { filterFormsByEntity } from "common/functions/forms";
import { MAX_INTERVAL_DAYS, MIN_INTERVAL_DAYS } from "./defaults";
import {
  getEntityWithColumnsMadeOptional,
  getLayoutWithColumnsMadeOptional,
} from "./form/work-order-template/functions";
import { getAssetIdCol, getEventAssets, getWorkOrderEntity } from "./functions";

export const validateProjectionLength = (projectionLength: number) =>
  !!projectionLength &&
  projectionLength >= MIN_INTERVAL_DAYS &&
  projectionLength <= MAX_INTERVAL_DAYS;

export const validateCommon = (value: ScheduledEvent) =>
  !!value?.description &&
  !R.isNil(value?.suppressionDays) &&
  !!(
    !value?.hasProjections || validateProjectionLength(value?.projectionLength)
  );

export const validateRelated = (
  entities: Entities,
  entity: Entity,
  record: ScheduledEventRecord,
) => {
  if (record?.properties?.type === "E") return true;

  const assets = getEventAssets(entities, entity, record).filter(
    isNotDeletedRecord,
  );
  return assets.length > 0;
};

export const validateWorkOrderTemplate = (
  context: Context,
  entity: Entity,
  record: ScheduledEventRecord,
  layout: Layout,
): boolean => {
  const { entities } = context;
  const woEntity = getWorkOrderEntity(entities, entity);
  const assetIdCol = getAssetIdCol(woEntity);

  const woEntityWithoutAssetId = {
    ...woEntity,
    columns: woEntity.columns.filter((c) => c !== assetIdCol),
  };

  const newWoEntity = getEntityWithColumnsMadeOptional(woEntityWithoutAssetId);
  const woFormProperties = record?.properties?.workOrderForm?.properties;
  const newLayout = getLayoutWithColumnsMadeOptional(layout);

  const woForms = filterFormsByEntity(context.forms, woEntity?.name);
  const isFormSelectionValid = !woForms?.length || !!woFormProperties?.formId;

  return (
    isFormSelectionValid &&
    !!woFormProperties &&
    isFormWithDynamicValuesValid(
      entities,
      newWoEntity,
      newLayout,
      undefined,
      woFormProperties,
    )
  );
};

const validateFrequency = (trigger: PmTrigger) =>
  !trigger?.frequency || !!trigger?.frequency?.value;

export const validateAsset = (
  a: ScheduledEventAssetRecord,
  hasFrequency: boolean,
  hasShadow: boolean,
) => {
  const { meters = [], lastOpenDate, lastCloseDate } = a.properties;

  return (
    R.all((m) => m.threshold > 0, meters) &&
    !!(hasFrequency
      ? hasShadow
        ? lastCloseDate
        : lastOpenDate
      : meters.length)
  );
};

const validateAssets = (
  entities: Entities,
  entity: Entity,
  record: ScheduledEventRecord,
) => {
  const assets = getEventAssets(entities, entity, record);
  const frequency = (record?.properties?.trigger as PmTrigger)?.frequency;
  const hasFrequency = !!frequency;
  const hasShadow = !!frequency?.shadow;
  return (
    assets.length > 0 &&
    R.all((a) => validateAsset(a, hasFrequency, hasShadow), assets)
  );
};

const validateInspectionTrigger = (trigger: InspectionTrigger) =>
  !!trigger &&
  !!trigger.crons &&
  trigger.crons.length > 0 &&
  !!trigger.startDate;

export const pmTriggerIsValid = (
  entities: Entities,
  entity: Entity,
  record: ScheduledEventRecord,
) =>
  validateRelated(entities, entity, record) &&
  validateFrequency((record?.properties as Pm)?.trigger) &&
  validateAssets(entities, entity, record);

const validateTrigger = (
  entities: Entities,
  entity: Entity,
  e: ScheduledEventRecord,
) => {
  switch (e.properties.type) {
    case "P":
      return pmTriggerIsValid(entities, entity, e);
    case "I":
      return validateInspectionTrigger(e?.properties?.trigger);
    default:
      return true;
  }
};

export const validateCustomFields = (entity: Entity, event: ScheduledEvent) => {
  const hasEmptyRequired = entity.columns.some(
    (column) =>
      !column.isSystem && column.required && R.isNil(event[column.name]),
  );
  return !hasEmptyRequired;
};

export const isValid = (
  context: Context,
  entity: Entity,
  record: ScheduledEventRecord,
  layout: Layout,
  formValidation: FormValidation,
) =>
  checkFormValidation(formValidation) &&
  validateCommon(record?.properties) &&
  validateRelated(context.entities, entity, record) &&
  validateTrigger(context.entities, entity, record) &&
  validateWorkOrderTemplate(context, entity, record, layout) &&
  validateCustomFields(entity, record?.properties);

export const canPreviewProjections = (
  entities: Entities,
  entity: Entity,
  record: ScheduledEventRecord,
) =>
  validateRelated(entities, entity, record) &&
  validateTrigger(entities, entity, record);

export const pmDetailIsValid = (entity: Entity, value: ScheduledEventValue) =>
  validateCommon(value?.record?.properties) &&
  validateCustomFields(entity, value?.record?.properties);

export const inspectionTriggerIsValid = (
  entities: Entities,
  entity: Entity,
  record: ScheduledEventRecord,
) =>
  validateRelated(entities, entity, record) &&
  validateInspectionTrigger((record?.properties as Inspection)?.trigger);

export const inspectionDetailIsValid = (
  entity: Entity,
  value: ScheduledEventValue,
) =>
  validateCommon(value?.record?.properties) &&
  validateCustomFields(entity, value?.record?.properties);

export const externalDetailIsValid = (
  entity: Entity,
  value: ScheduledEventValue,
) =>
  validateCommon(value?.record?.properties) &&
  validateCustomFields(entity, value?.record?.properties);

import { createRef, JSX, useEffect, useState } from "react";
import { Options, useHotkeys } from "react-hotkeys-hook";
import { ValidationWarning } from "common/record/form/form-validation-warning";
import { Entity } from "common/entities/types";
import { Groups } from "common/form";
import { focusElement } from "common/html";
import { FormFooter } from "common/form/footer";
import {
  appendColumnsToLastGroup,
  findMissingColumns,
  getLayoutAndDrilldownColumns,
} from "common/form/functions/layout";
import {
  getValidationResults,
  getValidationWarningMessages,
  isFormDirty,
  isFormValid,
} from "common/form/functions/validation";
import { FormValidation, Layout, MapWidgetPropsFn } from "common/form/types";
import { hasClearable } from "common/functions/related-form-display";
import { applyLookupQuery } from "common/query/entities";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import {
  defaultDisplayTypes,
  RelatedFormDisplayTypes,
} from "common/types/related-form-display";
import { ActionButtonLarge, ModifyButtonLarge } from "common/ui/buttons";
import { focusFirstInput } from "common/utils/html";
import { ValueProps } from "common/with-value-for";
import { type FieldRefs } from "common/types/html";
import { Custom } from "./types";

interface PropTypes extends ValueProps<Properties> {
  context: Context;
  onSave: () => void;
  onCancel?: () => void;
  entity: Entity;
  layout: Layout;
  defaultForm: Properties;
  custom: Custom;
  isValid: boolean;
  ownerId?: string;
  onPrevious?: () => void;
  onNext?: () => void;
  footerContent?: JSX.Element;
  displayTypes?: RelatedFormDisplayTypes;
  widgetsMapper?: MapWidgetPropsFn;
}

const formInputs: Options["enableOnTags"] = ["INPUT", "SELECT", "TEXTAREA"];

const runCallback = (callback: () => void, e: KeyboardEvent) => {
  if (callback) {
    e.preventDefault();
    callback();
  }
};

export const RelatedForm = ({
  onSave,
  onCancel,
  onPrevious,
  onNext,
  context,
  entity,
  ownerId,
  layout,
  isValid,
  defaultForm,
  custom,
  widgetsMapper,
  value,
  onChange,
  footerContent,
  displayTypes = defaultDisplayTypes,
}: PropTypes) => {
  const [formValidation, setFormValidation] = useState<FormValidation>();
  const [fieldRefs, setFieldRefs] = useState<FieldRefs>();

  useEffect(() => {
    const fieldRefs: FieldRefs = {};
    getLayoutAndDrilldownColumns(layout).forEach(
      (field) => (fieldRefs[field] = createRef()),
    );

    setFieldRefs(fieldRefs);
  }, []);

  const { entities } = context;
  const recordId = value?.id || value?.tempId;
  const isNew = !recordId;
  const hasClearButton = isNew && hasClearable(displayTypes);
  const formLayout = appendColumnsToLastGroup(
    findMissingColumns(layout, entity),
    layout,
  );
  const isRelatedFormValid =
    isValid && isFormValid(entity, formLayout, formValidation, value);

  const scrollToFieldLabel = (fieldName: string) =>
    focusElement(fieldRefs, fieldName);

  const validationResults = getValidationResults(
    entity,
    layout,
    value,
    formValidation,
  );
  const warningMessages = getValidationWarningMessages(
    entity,
    validationResults,
    scrollToFieldLabel,
  );

  useHotkeys(
    "shift+enter",
    (e: KeyboardEvent) => runCallback(onSave, e),
    { enableOnTags: formInputs, enabled: !isNew && isRelatedFormValid },
    [onSave, isRelatedFormValid],
  );
  useHotkeys(
    "pagedown, alt+n",
    (e: KeyboardEvent) => runCallback(onNext, e),
    { enableOnTags: formInputs, enabled: !isNew && isRelatedFormValid },
    [onNext, isRelatedFormValid],
  );
  useHotkeys(
    "pageup, alt+p",
    (e: KeyboardEvent) => runCallback(onPrevious, e),
    { enableOnTags: formInputs, enabled: !isNew && isRelatedFormValid },
    [onPrevious, isRelatedFormValid],
  );

  useEffect(() => {
    if (!isNew) {
      focusFirstInput(
        document.querySelector(".x-related-component .x-form-content"),
      );
    }
  }, []);

  const onFormCancel = () => {
    onChange(undefined);
  };

  return (
    <div className="x-related-component">
      <div className="x-form">
        <div className="x-form-content">
          {!isNew && warningMessages.length ? (
            <ValidationWarning
              headerMessage={_(
                "The provided input is invalid. Please review the following details:",
              )}
              warningMessages={warningMessages}
            />
          ) : undefined}

          {custom ? custom(value, onChange) : undefined}
          <Groups
            fieldRefs={fieldRefs}
            context={context}
            groups={formLayout ? formLayout.groups : []}
            entity={applyLookupQuery(entities, entity, formLayout, {
              ...value,
              ownerId,
            })}
            widgetsMap={widgetsMapper?.(value, formLayout, isNew)}
            withLinks={true}
            formValidation={formValidation}
            onFormValidationChange={setFormValidation}
            value={value}
            onChange={onChange}
          />
        </div>
        {isNew ? (
          <FormFooter
            isNew={isNew}
            noOverlay={true}
            isValid={isRelatedFormValid}
            canCancel={true}
            onCancel={onCancel || onFormCancel}
            canDelete={false}
            onDelete={undefined}
            isDeleting={false}
            canSave={true}
            onSave={onSave}
            isSaving={false}
            canRestore={false}
            onRestore={undefined}
            isRestoring={false}
            footerContent={footerContent}
            saveLabels={{
              idle: isNew ? _("Add") : _("Update"),
              loading: isNew ? _("Adding") : _("Updating"),
            }}
            cancelLabel={hasClearButton ? _("Clear") : _("Cancel")}
            disableCancel={
              isNew && !isFormDirty(defaultForm, formLayout?.defaults, value)
            }
          />
        ) : (
          <div className="x-form-footer">
            <div className="x-form-footer-content x-flex x-flex-end-center">
              <ActionButtonLarge
                className="qa-button-previous"
                disabled={!isRelatedFormValid || !onPrevious}
                onClick={onPrevious}
                tooltip={_(
                  "Apply changes and switch to the previous record [Page Up] or [Alt + P]",
                )}
              >
                {_("Previous")}
              </ActionButtonLarge>
              <ActionButtonLarge
                className="qa-button-next"
                disabled={!isRelatedFormValid || !onNext}
                onClick={onNext}
                tooltip={_(
                  "Apply changes and switch to the next record [Page Down] or [Alt + N]",
                )}
              >
                {_("Next")}
              </ActionButtonLarge>
              <ModifyButtonLarge
                className="qa-button-done"
                disabled={!isRelatedFormValid}
                onClick={onSave}
                tooltip={_("Apply changes and close the modal [Shift + Enter]")}
              >
                {_("Done")}
              </ModifyButtonLarge>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

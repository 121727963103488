export const CURRENT_SITE = "currentSite";

export const ALL_SITE = "allSites";

export type SiteType = "currentSite" | "allSites";

export const SITE_TYPES: SiteType[] = [CURRENT_SITE, ALL_SITE];

export const translateSiteType = (siteType: SiteType) => {
  switch (siteType) {
    case CURRENT_SITE:
      return _("Current Site");
    case ALL_SITE:
      return _("Shared: All Sites");
    default:
      return _("Unknown");
  }
};

import { defaultFor } from "common";
import { behaveAs } from "common/entities";
import { RecordContentForm } from "common/record/form/form";
import {
  FormValue,
  ScheduledEventValue,
  StandardValue,
} from "common/record/types";
import { ScheduledEventForm } from "common/scheduled-event/form";
import { LoadingIcon } from "common/widgets/loading-icon";
import { withValue, WithValue } from "common/with-value";
import { ValueProps } from "common/with-value-for";
import { GroupBehaviorForm } from "x/records/edit/behavior-form/group";
import { PurchaseOrderForm } from "x/records/edit/behavior-form/purchase-order";
import { PropTypes } from "x/records/edit/types";
import { ApprovalGroupBehaviorForm } from "./behavior-form/approval-group";
import { Requisitioning } from "./behavior-form/requisitioning";
import { TaxRateBehaviorForm } from "./behavior-form/tax-rate";
import { WorkOrderBehaviorForm } from "./behavior-form/work-order";

type Props = PropTypes & WithValue<FormValue> & ValueProps<FormValue>;

export const RecordForm = withValue<FormValue, PropTypes>(
  ({
    context,
    goTo,
    entity,
    runQuery,
    withLinks,
    loading,
    reload,
    eventId,
    auditTrailId,
    saving,
    deleting,
    deleteLabels,
    layout,
    isNew,
    disableEdit,
    save,
    onDelete,
    onCancel,
    onHasChanged,
    value = defaultFor<FormValue>(),
    onChangeSetValue,
    getUrl,
    displaySidebar,
    displayHeader,
  }: Props) => {
    if (loading) return <LoadingIcon />;

    const defaultProps: PropTypes & ValueProps<StandardValue> = {
      context,
      entity,
      runQuery,
      save,
      saving,
      onDelete,
      deleting,
      onCancel,
      onHasChanged,
      isNew,
      goTo,
      withLinks,
      loading,
      reload,
      eventId,
      auditTrailId,
      deleteLabels,
      layout,
      disableEdit,
      value: value as StandardValue,
      onChange: onChangeSetValue,
      getUrl: getUrl,
      displaySidebar,
      displayHeader,
    };

    if (behaveAs("ScheduledEvent", entity)) {
      return (
        <ScheduledEventForm
          context={context}
          entity={entity}
          save={save}
          auditTrailId={auditTrailId}
          saving={saving}
          onDelete={onDelete}
          deleting={deleting}
          onCancel={onCancel}
          onHasChanged={onHasChanged}
          isNew={isNew}
          value={value as ScheduledEventValue}
          onChange={onChangeSetValue}
        />
      );
    }

    if (behaveAs("PurchaseOrder", entity)) {
      return <PurchaseOrderForm {...defaultProps} />;
    }

    if (behaveAs("Group", entity)) {
      return <GroupBehaviorForm {...defaultProps} />;
    }

    if (behaveAs("ApprovalGroup", entity)) {
      return <ApprovalGroupBehaviorForm {...defaultProps} />;
    }

    if (behaveAs("Requisitioning", entity)) {
      return <Requisitioning {...defaultProps} />;
    }

    if (behaveAs("TaxRate", entity)) {
      return <TaxRateBehaviorForm {...defaultProps} />;
    }

    if (behaveAs("WorkOrder", entity)) {
      return <WorkOrderBehaviorForm {...defaultProps} />;
    }

    return <RecordContentForm {...defaultProps} extraValidation={undefined} />;
  },
  "RecordForm",
);

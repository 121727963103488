import { Component } from "react";
import { recordsApi } from "common/api/records";
import { Entity } from "common/entities/types";
import { Action } from "common/record/actions/action";
import { DismissAction } from "common/record/actions/types";
import { hasProtectedColumns } from "common/record/utils";
import { Context } from "common/types/context";
import { Properties, Record } from "common/types/records";
import { ActionButton } from "common/ui/buttons";
import { arrayToString } from "common/utils/array";
import { setLocationHref } from "common/utils/window-location";
import { filterFormsByEntity } from "common/functions/forms";
import { getTemporaryIdProps } from "common/record/edit/value";
import { ApiCall } from "common/types/api";
import { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import { toPartRequisitionRecord } from "../functions";
// eslint-disable-next-line import/no-cycle
import { AddToExistingRequisition } from "./add-to-existing";
import { CreateNewRequisition } from "./new-requisition";
import { PartRequisition, Requisition } from "./types";

import "./index.scss";

interface PartRequisitionPropTypes {
  context: Context;
  reorderRecords: Properties[];
  dismiss: DismissAction;
  entity: Entity;
  records: Record[];
  partRequisitionEntity: Entity;
}

interface StateTypes {
  createNew: boolean;
  actionScreen: boolean;
  requisition: Requisition;
}

export class CreateRequisitionFromReorderList extends Component<
  PartRequisitionPropTypes,
  StateTypes
> {
  static readonly displayName = "CreateRequisitionFromReorderList";

  constructor(props: PartRequisitionPropTypes) {
    super(props);
    this.state = {
      createNew: true,
      actionScreen: false,
      requisition: {
        partRequisition: this.initializePartRequisition(props.reorderRecords),
        isValid: false,
        partRequisitionId: undefined,
        selectedPartRequisition: undefined,
      },
    };
  }

  initializePartRequisition = (
    reorderRecords: Properties[] = [],
  ): PartRequisition => {
    const { context, partRequisitionEntity } = this.props;
    const tempId = getTemporaryIdProps(reorderRecords).$id;
    const requisitionsForms: Form[] = filterFormsByEntity(
      context.forms,
      partRequisitionEntity.name,
    );
    const formId: number =
      requisitionsForms?.length === 1 ? requisitionsForms[0].id : undefined;

    return {
      partRequisitionItems: reorderRecords.map((record) =>
        this.toPartRequisition(record, tempId),
      ),
      requestedBy: undefined,
      requisitionTitle: undefined,
      approvalGroupId: undefined,
      approvalCostCenterId: undefined,
      formId: formId,
      $id: tempId,
    };
  };

  toPartRequisition = (record: Properties, tempId: string) => ({
    $id: tempId,
    partId: record.partId,
    locationId: record.locationId,
    partLocationId: record.id,
    quantity: record.reorderQuantity,
  });

  getUpdatedRequisitionValue = (requisition: Requisition) => ({
    ...requisition,
    partRequisition: {
      ...requisition.partRequisition,
      partRequisitionItems:
        requisition.partRequisition.partRequisitionItems.map((item) => ({
          ...item,
          isNew: true,
        })),
    },
  });

  saveNewRequisition = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { partRequisitionEntity } = this.props;
    const { requisition } = this.state;
    const updatedRequisition = this.getUpdatedRequisitionValue(requisition);
    const record = toPartRequisitionRecord(updatedRequisition, true);

    return recordsApi(apiCall)
      .create(partRequisitionEntity.name, record)
      .then((response) => {
        dismiss(false);
        this.goToPartRequisitions(response.id);
      });
  };

  updateExistingRequisition = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { partRequisitionEntity } = this.props;
    const { requisition } = this.state;
    const record = toPartRequisitionRecord(requisition, false);

    return recordsApi(apiCall)
      .update(partRequisitionEntity.name, record)
      .then(() => {
        dismiss(false);
        this.goToPartRequisitions(requisition.partRequisitionId.id);
      });
  };

  goToPartRequisitions = (partRequisitionId: string) => {
    const {
      context: { site },
      partRequisitionEntity,
    } = this.props;

    return setLocationHref(
      arrayToString(
        [
          `#/${site.name}/${partRequisitionEntity.name}`,
          partRequisitionId ? `/${partRequisitionId}` : "",
        ],
        "",
      ),
    );
  };

  onCreateNew = (_: ApiCall, dismiss: DismissAction) => {
    this.setState(() => ({ createNew: true, actionScreen: true }));
    return CancellablePromise.resolve(dismiss);
  };

  onAddToExisting = () =>
    this.setState(() => ({ createNew: false, actionScreen: true }));

  onChange = (requisition: Requisition) => this.setState({ requisition });

  onOk = () => {
    const { actionScreen, createNew, requisition } = this.state;
    return actionScreen
      ? requisition.isValid &&
          (createNew ? this.saveNewRequisition : this.updateExistingRequisition)
      : this.onCreateNew;
  };

  render() {
    const {
      context,
      dismiss,
      partRequisitionEntity,
      records = [],
    } = this.props;
    const { createNew, actionScreen, requisition } = this.state;

    const footerButtons = !actionScreen ? (
      <ActionButton role="button" onClick={this.onAddToExisting}>
        {_("Add to Existing")}
      </ActionButton>
    ) : undefined;

    return (
      <Action
        requiresAuthentication={
          actionScreen ? hasProtectedColumns(partRequisitionEntity) : false
        }
        context={context}
        dismiss={dismiss}
        entity={partRequisitionEntity}
        records={records}
        title={_("Create Part Requisition")}
        btnLabel={actionScreen ? _("Save") : _("Create New")}
        hideEntityInTitle={true}
        hideRecordNumber={true}
        hideFooter={false}
        footerButtons={footerButtons}
        onOk={this.onOk()}
        noPadding={true}
        size="large"
      >
        <div className="x-requisition-controller x-record-layout">
          <div className="x-requisition-controller-content">
            {!actionScreen ? (
              _(
                "Do you want to create a new Part Requisition or add to existing one?",
              )
            ) : createNew ? (
              <CreateNewRequisition
                {...this.props}
                value={requisition}
                onChange={this.onChange}
              />
            ) : (
              <AddToExistingRequisition
                {...this.props}
                value={requisition}
                onChange={this.onChange}
              />
            )}
          </div>
        </div>
      </Action>
    );
  }
}

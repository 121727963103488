import { Properties, Record, RelatedPayload } from "common/types/records";
import { SidebarElement } from "common/record/sidebar/types";
import { Comment } from "common/types/comments";
import { ScheduledEventRecord } from "common/types/scheduled-event";

export interface RelatedForm extends Properties {
  tempId?: string;
}

export type Reload = (value?: any, recordId?: string) => any;

export interface DetailUiValue {
  form: Properties;
  tab?: string;
  isDirty?: boolean;
}

export interface PartialForm {
  [entityName: string]: Properties;
}

export interface RelatedUiValue {
  partialForm: PartialForm;
  form: RelatedPayload;
  isDirty: boolean;
}

interface OtherUiValue {
  comment: Comment;
}

export interface StandardUiValue {
  detail: DetailUiValue;
  related?: RelatedUiValue;
  sidebar?: SidebarElement;
  other?: OtherUiValue;
}

export interface StandardValue {
  record: Record;
  ui: StandardUiValue;
}

export interface ScheduledEventUiValue {
  isPreviewOpen: boolean;
  entityForm: StandardUiValue;
}
export interface ScheduledEventValue {
  record: ScheduledEventRecord;
  ui: ScheduledEventUiValue;
}

export type FormValue = StandardValue | ScheduledEventValue;

export const isStandardUiValue = (value: FormValue): value is StandardValue =>
  !!(value as StandardValue).record &&
  !!(value as StandardValue).ui &&
  !!(value as StandardValue).record.properties;

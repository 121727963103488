import * as R from "ramda";
import { isPropertiesObject } from "common/api/records";
import { searchApi } from "common/api/search";
import { behaveAs, getColumn, getNormalizedColumnsMap } from "common/entities";
import {
  isRelatedSiteDataColumn,
  isRelatedUserDataColumn,
  looseColumnNameCheck,
  normalizeColumnName,
} from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { isValidDynamicValue } from "common/form/dynamic-values";
import { createDefaultLayout } from "common/form/functions/layout";
import {
  EXTRA_PREFIX,
  getCommonEntityFieldsForForeignKey,
  getOverwritableColumns,
} from "common/form/group/functions";
import { Group } from "common/form/types";
import { isForeignKey } from "common/functions/foreign-key";
import { getFormByIdOrEntity } from "common/functions/forms";
import { merge1 } from "common/merge";
import { addFilter } from "common/query/filter";
import { addToSelectQuery } from "common/query/select";
import {
  isSelectField,
  Query,
  QueryForEntity,
  Select,
} from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKeyWithExtra } from "common/types/foreign-key";
import { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import {
  ForeignKeysMap,
  Properties,
  PropertyValue,
} from "common/types/records";

export const getFormOrDefault = (
  context: Context,
  entityName: string,
  formId?: number,
  defaultProperties?: Properties,
): Form => {
  const form = getFormByIdOrEntity(context.forms, entityName, formId);
  if (form) return form;
  const entity = context.entities[entityName];
  if (!entity) return undefined;
  const layout = createDefaultLayout(entity, context, defaultProperties, false);
  return { entityName, settings: layout, labels: {}, sites: undefined };
};

export const hasSystemCalendarColumn = (entity: Entity) =>
  R.any(
    (c) => c.dataType === "systemintfk" && c.name === "calendarId",
    entity.columns,
  );

export const getPropertiesWithDefaultCalendar = (
  context: Context,
  entity: Entity,
  defaultProperties: Properties,
) => {
  const defaultCalendar = R.find(
    (c) => c.settings.isDefault,
    context.calendars,
  );

  return defaultCalendar && hasSystemCalendarColumn(entity)
    ? merge1("calendarId", defaultCalendar.id, defaultProperties)
    : defaultProperties;
};

const hasIdSelect = (select: Select) =>
  R.any((field) => isSelectField(field) && field.name === "id", select);

const getQueryWithId = (query: Query) =>
  hasIdSelect(query.select)
    ? query
    : merge1("select", [{ name: "id" }, ...query.select], query);

export const getResolveQueries = (
  context: Context,
  groups: Group[],
  entity: Entity,
  defaultProperties: Properties,
): ResolveQueries =>
  R.keys(defaultProperties).reduce((acc: ResolveQueries, key: string) => {
    const defaultValue = defaultProperties[key];
    const column = getColumn(entity, key);

    const relativeEntity = column?.relatedEntity
      ? context.entities[column.relatedEntity]
      : undefined;
    const isDynamicValue = isValidDynamicValue(
      column?.dataType,
      relativeEntity,
      defaultValue,
    );

    if (
      !column?.isForeignKey ||
      isDynamicValue ||
      isForeignKey(defaultValue) ||
      isRelatedSiteDataColumn(column) ||
      isRelatedUserDataColumn(column)
    ) {
      return acc;
    }

    const relatedEntity = context.entities[column.relatedEntity];

    const querySelect = getCommonEntityFieldsForForeignKey(
      context,
      entity,
      column,
      defaultProperties,
      groups,
    );

    const defaultQuery: QueryForEntity = addFilter(
      { name: "id", op: "eq", value: defaultValue },
      {
        entity: relatedEntity.name,
        query: getQueryWithId(relatedEntity.query),
      },
    );

    const query = merge1(
      "query",
      addToSelectQuery(querySelect, defaultQuery.query),
      defaultQuery,
    );

    return merge1(key, query, acc);
  }, {});

export const resolveForeignKeysWithExtra = (
  context: Context,
  entity: Entity,
  form: Form,
  defaultProperties: Properties,
): CancellablePromise<ForeignKeysMap> => {
  const groups = form?.settings?.groups;
  const runQuery = searchApi(context.apiCall).runQueryFkExpansion;
  const resolveQueries = getResolveQueries(
    context,
    groups,
    entity,
    defaultProperties,
  );

  const promises: Array<CancellablePromise<ForeignKeyWithExtra>> = R.values(
    resolveQueries,
  ).map((q) =>
    runQuery(q).then((records: ForeignKeyWithExtra[]) => records[0]),
  );

  return CancellablePromise.all(promises).then((fks) =>
    R.zipObj(R.keys(resolveQueries), fks),
  );
};

interface ResolveQueries {
  [columnName: string]: QueryForEntity;
}

const isId = (value: any) => R.is(String, value) || R.is(Number, value);

const isBlacklistedColumn = (
  sourceEntity: Entity,
  targetEntity: Entity,
  columnName: string,
) =>
  behaveAs("Asset", sourceEntity) &&
  (behaveAs("Request", targetEntity) || behaveAs("WorkOrder", targetEntity)) &&
  looseColumnNameCheck(columnName, "description");

export const getExtraPropsPartialForValue = (
  entities: Entities,
  entity: Entity,
  properties: Properties,
  columnName: string,
  newValue: PropertyValue,
): Properties => {
  if (!entity || !columnName) return {};

  const column = getColumn(entity, columnName);

  if (!newValue || !column.isForeignKey || !isPropertiesObject(newValue))
    return { [columnName]: newValue };

  const sourceRelatedEntity = entities[column.relatedEntity];

  const initialProperties: Properties = {
    [columnName]: {
      id: isId(properties[columnName]) ? properties[columnName] : undefined,
      ...newValue,
    },
  };

  const entityColumnsMap = getNormalizedColumnsMap(entity);
  const overwritableColumns = getOverwritableColumns(entity);

  const matchingProperties = R.keys(newValue)
    .filter(R.startsWith(EXTRA_PREFIX))
    .reduce((acc, key: `extra_${string}`) => {
      const propertyName = key.replace(EXTRA_PREFIX, "");

      if (isBlacklistedColumn(sourceRelatedEntity, entity, propertyName))
        return acc;

      const destinationColumn = entityColumnsMap.get(
        normalizeColumnName(propertyName),
      );

      if (
        !destinationColumn ||
        destinationColumn.readOnly ||
        destinationColumn.unique
      )
        return acc;

      const canOverwrite =
        !properties ||
        R.isNil(properties[destinationColumn.name]) ||
        R.includes(destinationColumn.name, overwritableColumns);

      return canOverwrite
        ? merge1(destinationColumn.name, newValue[key], acc)
        : acc;
    }, initialProperties);

  return matchingProperties;
};

export const getMatchingExtraProperties = (
  relatedKeys: string[] = [],
  propertiesKeys: string[] = [],
) => {
  const propertiesKeysMap = new Map(
    propertiesKeys.map((key) => [normalizeColumnName(key), key]),
  );

  return relatedKeys.reduce((acc: string[], relatedKey: string) => {
    if (!relatedKey?.startsWith(EXTRA_PREFIX)) return acc;

    const normalizedKey = normalizeColumnName(
      R.replace(EXTRA_PREFIX, "", relatedKey),
    );

    return propertiesKeysMap.has(normalizedKey)
      ? acc.concat(propertiesKeysMap.get(normalizedKey))
      : acc;
  }, []);
};

import * as React from "react";
import debounce from "lodash-es/debounce";
import { Cancelable } from "common/types/debounce";
import { AlertErrorTip } from "common/widgets/alert";
import { setColumnIsValid } from "common/form/functions/validation";
import { ApiError } from "common/ui/api-error";
import { ApiErrorResponse } from "common/types/error";
import { Props, YourComponentProps } from "./types";
import { getUniqueErrorMessage } from "./functions";

import "./base.scss";

interface StateType {
  debounceValidateValue: ((value: string) => void) & Cancelable;
  pending: boolean;
  duplicated: boolean;
  usedInSites: string[];
  error: ApiErrorResponse;
}

export function withUnique<PropTypes>(
  YourComponent: React.ComponentType<YourComponentProps<PropTypes>>,
) {
  return class Unique extends React.Component<Props<PropTypes>, StateType> {
    static readonly displayName = "Unique";

    constructor(props: Props<PropTypes>) {
      super(props);
      this.state = {
        debounceValidateValue: debounce(this.validateValue, 250),
        pending: false,
        duplicated: false,
        usedInSites: [],
        error: undefined,
      };
    }

    componentDidMount() {
      const { validateOnMount = true, useUnique, value } = this.props;
      if (useUnique && validateOnMount && value) this.validateValue(value);
    }

    componentDidUpdate(prevProps: Props<PropTypes>) {
      const { useUnique, value } = this.props;
      if (useUnique && prevProps.value !== value) this.onChange(value);
    }

    componentWillUnmount() {
      const { debounceValidateValue } = this.state;
      if (debounceValidateValue) debounceValidateValue.cancel();
    }

    onChange = (value: string) => {
      const { onChange } = this.props;
      this.setState({ error: undefined });

      if (!value) {
        this.setState({ duplicated: false, usedInSites: [] });
        return onChange(undefined);
      }

      onChange(value);
      this.state.debounceValidateValue(value);
    };

    validateValue = (value: string) => {
      this.setState({ pending: true });
      this.setColumnValidity(false, true);

      this.props
        .fetchIsUnique(value)
        .then(({ isUnique, usedInSites }) => {
          this.setState({
            pending: false,
            duplicated: !isUnique,
            usedInSites,
          });
          this.setColumnValidity(isUnique, false);
        })
        .catch((error) => {
          this.setState({ error, pending: false });
          this.setColumnValidity(false, false);
        });
    };

    setColumnValidity = (isValid: boolean, isValidating: boolean) => {
      const { columName, formValidation, onFormValidationChange } = this.props;

      return onFormValidationChange?.(
        setColumnIsValid(formValidation, columName, isValid, isValidating),
      );
    };

    render() {
      const { useUnique, columnLocalizedName, value } = this.props;
      const { pending, duplicated, usedInSites, error } = this.state;

      const style = pending
        ? "x-highlighted"
        : duplicated || error
          ? "x-has-error"
          : "";
      return useUnique ? (
        <div className={`x-unique ${style}`}>
          <YourComponent
            {...this.props}
            value={value}
            onChange={this.onChange}
          />
          {pending ? <i className="fa fa-spinner fa-spin" /> : undefined}
          {duplicated ? (
            <AlertErrorTip
              message={getUniqueErrorMessage(columnLocalizedName, usedInSites)}
            />
          ) : error ? (
            <ApiError error={error} />
          ) : undefined}
        </div>
      ) : (
        <YourComponent {...this.props} />
      );
    }
  };
}

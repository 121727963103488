import { JSX } from "react";
import { AlertWarning } from "common/widgets/alert";
import { type AlertRef } from "common/widgets/alert/types";

interface ValidationWarningProps {
  headerMessage: string;
  warningMessages: JSX.Element[];
  validationWarningMessageRef?: AlertRef;
}

export const ValidationWarning = ({
  headerMessage,
  warningMessages,
  validationWarningMessageRef,
}: ValidationWarningProps) => (
  <AlertWarning
    alertRef={validationWarningMessageRef}
    isOpen={true}
    message={headerMessage}
  >
    <div className="x-invalid-form-input">
      {warningMessages.map((message, index) => (
        <div className="x-validation-warning" key={index}>
          {message}
        </div>
      ))}
    </div>
  </AlertWarning>
);

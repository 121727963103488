import { MouseEvent } from "react";
import * as R from "ramda";
import { toDashedNoSpace } from "common";
import { arrayToString } from "common/utils/array";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";
import { shouldDisableRelatedEntity } from "common/record/disabled-related-entity";
import { getLabelFor } from "../utils";
import { SidebarElement } from "./types";

type SidebarItemProps = {
  element: SidebarElement;
  disabledEntity?: ReturnType<typeof shouldDisableRelatedEntity>;
  isActive: boolean;
  editing?: boolean;
  disabled?: boolean;
  toggleOpened?: boolean;
  toggleFn?: () => void;
  setValue: (v: SidebarElement, noParse?: boolean) => void;
};
export const SidebarItem = ({
  element,
  isActive,
  disabledEntity,
  editing,
  disabled,
  toggleOpened,
  toggleFn,
  setValue,
}: SidebarItemProps) => {
  const { count, entity: itemEntity } = element;
  const label = getLabelFor(element.label);
  const countItem = R.is(Number, count) ? (
    <span className="badge pull-right x-count">{count}</span>
  ) : undefined;
  const invalidIndicator = element.isInvalid ? (
    <span
      className="x-invalid-element"
      {...getTooltipProps(
        _("The provided input is invalid. Please review the details"),
        "error",
      )}
    >
      <i className="fa fa-exclamation-circle" />
    </span>
  ) : undefined;

  const isDisabled = !!disabledEntity;
  const itemTitle =
    isDisabled && disabledEntity.reason
      ? disabledEntity.reason.replace("{LABEL}", label)
      : "";

  const className = arrayToString([
    "list-group-item x-ellipsis",
    isActive ? "selected" : undefined,
    element.isChild ? "list-group-item-child" : undefined,
    element.isGrandChild
      ? "x-list-group-item-grandchild x-padding-left-40"
      : undefined,
    isDisabled ? disabledEntity.className : undefined,
    itemEntity ? `qa-${itemEntity}` : undefined,
    `qa-${toDashedNoSpace(label)}`,
    disabled ? "x-item-disabled" : undefined,
  ]);

  const allowClick =
    !(disabled || (editing && element.isGrandChild)) &&
    element.isChild &&
    !isDisabled;
  const onClick = () => {
    if (!allowClick) return;
    if (toggleOpened) {
      toggleFn();
    }
    setValue(element);
  };
  const onLinkClick = (e: MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <li className={className} onClick={onClick}>
      {element.isChild ? (
        <a title={itemTitle} href="#" onClick={onLinkClick}>
          {countItem}
          {label}
          {invalidIndicator}
        </a>
      ) : (
        <span>
          {countItem}
          {label}
          {invalidIndicator}
        </span>
      )}
    </li>
  );
};

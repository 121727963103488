import * as R from "ramda";
import { searchApi } from "common/api/search";
import { getColumn } from "common/entities";
import { getSelectWithId } from "common/query/select";
import {
  defaultEventProps,
  getAvailableTypes,
} from "common/api/scheduled-event";
import { Entity } from "common/entities/types";
import { filterFormsByEntity } from "common/functions/forms";
import { defaultFor } from "common/index";
import { getPropertiesWithTemporaryIds } from "common/record/edit/value";
import { FormValue } from "common/record/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";

export function fetchAssetForeignKeys(
  context: Context,
  entity: Entity,
  assetIds: string[] = [],
): CancellablePromise<ForeignKey[]> {
  if (!assetIds.length) return CancellablePromise.resolve([]);

  const { entities, apiCall } = context;
  const { eventAssetEntity } = entity.arguments;
  const assetId = getColumn(entities[eventAssetEntity], "assetId");
  const assetEntity = assetId.relatedEntity;
  const originalQuery = entities[assetEntity].query;

  // see createUniqueAssetsQuery doing the same but `notin`
  const assetQuery = {
    entity: assetEntity,
    query: R.mergeRight(originalQuery, {
      select: getSelectWithId(originalQuery.select),
      filter: {
        and: [
          { name: "isDeleted", op: "isfalse" },
          { name: "id", op: "in", value: assetIds.join(",") },
        ],
      },
    }),
  };

  return searchApi(apiCall).runQuery(assetQuery) as CancellablePromise<
    ForeignKey[]
  >;
}

export function getScheduledWODependencies(
  context: Context,
  entity: Entity,
  defaultProperties: Properties = defaultFor<Properties>(),
  scheduledAssetIds: string[] = [],
  isNew?: boolean,
): CancellablePromise<FormValue> {
  const { workOrderEntity } = entity.arguments;
  const woForms = filterFormsByEntity(context.forms, workOrderEntity);

  // If only one form is available, preselect it
  const formId = woForms?.length === 1 ? woForms[0]?.id : undefined;

  const availableTypes = getAvailableTypes(context);

  const scheduledWOProperties = R.omit(["related", "id"], defaultProperties);

  const defaults = R.keys(scheduledWOProperties).length
    ? scheduledWOProperties
    : { workOrderForm: { properties: { formId } } };

  const typeProperties = {
    ...(availableTypes.length > 1
      ? undefined
      : defaultEventProps(context, availableTypes[0])),
    ...defaults,
  };
  const properties = getPropertiesWithTemporaryIds(entity, typeProperties);

  const eventAssetEntity = entity.arguments.eventAssetEntity;

  return fetchAssetForeignKeys(context, entity, scheduledAssetIds).then(
    (assetsFks = []) => {
      const assets = assetsFks.map((assetId: ForeignKey) => ({
        properties: {
          assetId,
          meters: [],
        },
      }));

      const related = assetsFks.length
        ? { [eventAssetEntity]: assets }
        : defaultProperties?.related;

      return {
        record: { properties, isNew, related },
        ui: { detail: {} },
      } as unknown as FormValue;
    },
  );
}

import * as React from "react";
import { JSX } from "react";
import * as R from "ramda";
import { getLocalizedColumnName } from "common/entities/entity-column/functions";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";
import type {
  ColumnValuesValidation,
  ValidationResults,
} from "common/form/types/validation";
import { getLayoutGroupColumns } from "common/form/functions/common";
import { defaultFor } from "common";
import { deepEqual } from "common/component";
import { behaveAs, findColumn } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { getConditionMonitoringInvalidFields } from "common/form/behavior-forms/condition-monitoring/functions";
import { FormValidation, GroupColumn, Layout } from "common/form/types";
import { merge2 } from "common/merge";
import { KeysOf, Properties } from "common/types/records";
import {
  column,
  columnWithDynamicValue,
  columnWithValue,
  isRecordSelfReference,
  skipColumnValidation,
} from "common/validate";

const COLUMNS_TO_SKIP = ["sites"];

const areColumnValuesValid = (props: Properties, entity: Entity) =>
  !entity?.columns.some(
    (c) => columnWithValue(entity, c, props?.[c.name]).length,
  );

const areColumnWithDynamicValuesValid = (
  entities: Entities,
  props: Properties,
  entity: Entity,
) =>
  !entity?.columns.some(
    (c) => columnWithDynamicValue(entities, entity, c, props?.[c.name]).length,
  );

export const checkFormValidation = (formValidation: FormValidation) =>
  !formValidation ||
  !Object.values(formValidation?.fields ?? {}).some((f) => f.isValid === false);

const getRequiredColumns = (
  cols: EntityColumn[],
  groupColumns: GroupColumn[] = [],
): GroupColumn[] => {
  return groupColumns.filter((column) => {
    const col = findColumn(cols, column.columnName);
    return col && (column.required || col.required) && !col.readOnly;
  });
};

export const validateGroupColumns = (
  cols: EntityColumn[],
  props: Properties,
  groupColumns: GroupColumn[],
) => {
  const requiredColumns = getRequiredColumns(cols, groupColumns);

  return R.all((column) => !R.isNil(props[column.columnName]), requiredColumns);
};

const validateLayout = (
  cols: EntityColumn[],
  props: Properties,
  layout: Layout,
) =>
  !layout ||
  R.all(
    (section) => validateGroupColumns(cols ?? [], props, section.columns),
    layout.groups || [],
  );

export const hasAnyFieldFilledIn = (form: Properties) =>
  R.toPairs(form ?? {}).some(
    ([column, value]) => !COLUMNS_TO_SKIP.includes(column) && !R.isNil(value),
  );

const hasNoSelfReferencingColumnValues = (entity: Entity, props: Properties) =>
  !behaveAs("Tree", entity) ||
  !entity?.columns?.some((c) => {
    const value = props[c.name];
    return (
      entity.arguments.fkColumn === c.name &&
      isRecordSelfReference(entity.name, props?.id, c, value)
    );
  });

export const getBehaviorInvalidFields = (
  entity: Entity,
  props: Properties = defaultFor(),
): string[] => {
  if (!behaveAs("ConditionMonitoring", entity)) {
    return [];
  }
  return getConditionMonitoringInvalidFields(props);
};

export const isBehaviorFormValid = (
  entity: Entity,
  props: Properties = defaultFor(),
) => getBehaviorInvalidFields(entity, props).length === 0;

// this ignores the check for hasAnyFieldFilledIn
export const isFormWithDynamicValuesValid = (
  entities: Entities,
  entity: Entity,
  layout: Layout,
  formValidation?: FormValidation,
  props: Properties = defaultFor(),
) =>
  areColumnWithDynamicValuesValid(entities, props, entity) &&
  checkFormValidation(formValidation) &&
  validateLayout(entity?.columns, props, layout) &&
  isBehaviorFormValid(entity, props);

export const isFormValid = (
  entity: Entity,
  layout: Layout,
  formValidation?: FormValidation,
  props: Properties = defaultFor(),
) =>
  areColumnValuesValid(props, entity) &&
  checkFormValidation(formValidation) &&
  validateLayout(entity?.columns, props, layout) &&
  hasAnyFieldFilledIn(props) &&
  isBehaviorFormValid(entity, props) &&
  hasNoSelfReferencingColumnValues(entity, props);

export const omitEmptyFields = (form: Properties) => {
  return R.pickBy((val) => !R.isNil(val), form);
};

const getRequiredColumnsWithoutValue = (
  cols: EntityColumn[],
  props: Properties,
  groupColumns: GroupColumn[],
): GroupColumn[] => {
  const requiredColumns = getRequiredColumns(cols, groupColumns);

  return requiredColumns.filter((column) => R.isNil(props[column.columnName]));
};

const getMissingRequiredFormColumns = (
  entity: Entity,
  layout: Layout,
  props: Properties,
) => {
  const missingColumns = getRequiredColumnsWithoutValue(
    entity.columns,
    props,
    getLayoutGroupColumns(layout?.groups),
  );

  return missingColumns.map((groupColumn) => groupColumn.columnName);
};

const prepareColumnNames = (columns: string[]) =>
  R.uniq(columns).sort((a, b) => a.localeCompare(b));

const validateColumnValues = (
  entity: Entity,
  layout: Layout,
  columnValuesValidation: ColumnValuesValidation,
  props: Properties,
) => {
  const result: ColumnValuesValidation = {
    ...columnValuesValidation,
    required: getMissingRequiredFormColumns(entity, layout, props) ?? [],
  };

  entity?.columns?.forEach((col) => {
    if (skipColumnValidation(col, entity)) return;

    const columnValidation = column(col, props[col.name]);

    columnValidation.forEach((validationType) => {
      result[validationType].push(col.name);
    });
  });

  Object.keys(result).forEach((validationType) => {
    result[validationType] = prepareColumnNames(result[validationType]);
  });

  return result;
};

const getInvalidFields = (formValidation: FormValidation) => {
  const invalidFields: string[] = [];

  Object.keys(formValidation?.fields ?? {}).forEach((fieldName) => {
    const field = formValidation.fields[fieldName];
    if (!field?.isValidating && !field?.isValid) {
      invalidFields.push(fieldName);
    }
  });

  return invalidFields;
};

export const getValidationResults = (
  entity: Entity,
  layout: Layout,
  props: Properties,
  formValidation?: FormValidation,
): ValidationResults => {
  const defaultColumnValuesValidation: ColumnValuesValidation = {
    required: [],
    maxValue: [],
    minValue: [],
    maxLength: [],
    minLength: [],
    invalidDecimal: [],
    invalid: [],
    unique: [],
  };

  return entity && props
    ? {
        // Get the validation results for each column
        columnValuesValidation: validateColumnValues(
          entity,
          layout,
          defaultColumnValuesValidation,
          props,
        ),
        // Get the invalid fields from Form validation
        formValidationInvalidFields: getInvalidFields(formValidation),
        // Get the invalid fields from Behavior validation
        behaviorValidationInvalidFields: getBehaviorInvalidFields(
          entity,
          props,
        ),
      }
    : {
        columnValuesValidation: defaultColumnValuesValidation,
        formValidationInvalidFields: [],
        behaviorValidationInvalidFields: [],
      };
};

const generateFieldButtons = (
  entity: Entity,
  fields: string[],
  scrollToField: (filedName: string) => void,
) => {
  return fields.map((field, index) => {
    const fieldName = getLocalizedColumnName(entity, field);
    return (
      <button
        key={field}
        className="x-validation-warning-field"
        onClick={() => scrollToField(field)}
        {...getTooltipProps(
          _("Click to navigate to {FIELD}").replace("{FIELD}", fieldName),
          "info",
        )}
      >
        {fieldName}
        {index < fields.length - 1 ? ", " : ""}
      </button>
    );
  });
};

const getValidationMessageForType = (
  entity: Entity,
  validationType: string,
  fields: string[],
  scrollToField: (filedName: string) => void,
) => {
  const fieldButtons = generateFieldButtons(entity, fields, scrollToField);

  switch (validationType) {
    case "required":
      return (
        <React.Fragment key={validationType}>
          <span className="x-validation-warning-header">
            {_("The following required fields are missing input:")}
          </span>
          <span className="x-validation-warning-fields"> {fieldButtons}</span>
        </React.Fragment>
      );

    case "maxValue":
      return (
        <React.Fragment key={validationType}>
          <span className="x-validation-warning-header">
            {_("The following fields exceeded the maximum value:")}
          </span>
          <span className="x-validation-warning-fields"> {fieldButtons}</span>
        </React.Fragment>
      );

    case "minValue":
      return (
        <React.Fragment key={validationType}>
          <span className="x-validation-warning-header">
            {_("The following fields do not meet the minimum value:")}
          </span>
          <span className="x-validation-warning-fields"> {fieldButtons}</span>
        </React.Fragment>
      );

    case "maxLength":
      return (
        <React.Fragment key={validationType}>
          <span className="x-validation-warning-header">
            {_("The following fields exceeded the maximum length:")}
          </span>
          <span className="x-validation-warning-fields"> {fieldButtons}</span>
        </React.Fragment>
      );

    case "minLength":
      return (
        <React.Fragment key={validationType}>
          <span className="x-validation-warning-header">
            {_("The following fields do not meet the minimum length:")}
          </span>
          <span className="x-validation-warning-fields"> {fieldButtons}</span>
        </React.Fragment>
      );

    case "invalidDecimal":
      return (
        <React.Fragment key={validationType}>
          <span className="x-validation-warning-header">
            {_("The following fields have an invalid decimal value:")}
          </span>
          <span className="x-validation-warning-fields"> {fieldButtons}</span>
        </React.Fragment>
      );

    case "unique":
      return (
        <React.Fragment key={validationType}>
          <span className="x-validation-warning-header">
            {_("The following fields have a value that is not unique:")}
          </span>
          <span className="x-validation-warning-fields"> {fieldButtons}</span>
        </React.Fragment>
      );

    case "invalid":
      return (
        <React.Fragment key={validationType}>
          <span className="x-validation-warning-header">
            {_("The following fields have an invalid value:")}
          </span>
          <span className="x-validation-warning-fields"> {fieldButtons}</span>
        </React.Fragment>
      );

    default:
      return null;
  }
};

export const getValidationWarningMessages = (
  entity: Entity,
  validationResults: ValidationResults,
  scrollToField: (field: string) => void,
) => {
  const warningMessages: JSX.Element[] = [];

  const { required: missingRequired, ...columnValidation } =
    validationResults.columnValuesValidation;
  const { formValidationInvalidFields } = validationResults;

  if (missingRequired.length > 0) {
    warningMessages.push(
      <React.Fragment key="missingRequired">
        <span className="x-validation-warning-header">
          {_("The following required fields are missing input:")}
        </span>
        <span className="x-validation-warning-fields">
          {generateFieldButtons(entity, missingRequired, scrollToField)}
        </span>
      </React.Fragment>,
    );
  }

  Object.entries(columnValidation).forEach(([validationType, columnNames]) => {
    if (columnNames.length === 0) return;

    warningMessages.push(
      getValidationMessageForType(
        entity,
        validationType,
        columnNames,
        scrollToField,
      ),
    );
  });

  if (formValidationInvalidFields.length > 0) {
    warningMessages.push(
      <React.Fragment key="formValidation">
        <span className="x-validation-warning-header">
          {_("The following fields have invalid value:")}
        </span>
        <span className="x-validation-warning-fields">
          {generateFieldButtons(
            entity,
            formValidationInvalidFields,
            scrollToField,
          )}
        </span>
      </React.Fragment>,
    );
  }

  if (validationResults.behaviorValidationInvalidFields.length > 0) {
    warningMessages.push(
      <React.Fragment key="behaviorValidation">
        <span className="x-validation-warning-header">
          {_("Behavior validation failed for the following fields:")}
        </span>
        <span className="x-validation-warning-fields">
          {generateFieldButtons(
            entity,
            validationResults.behaviorValidationInvalidFields,
            scrollToField,
          )}
        </span>
      </React.Fragment>,
    );
  }

  return warningMessages;
};

export const isFormDirty = (
  defaultForm: Properties,
  defaultsInLayout: Properties,
  form: Properties,
  ignoredProperties: KeysOf<Properties> = [],
) => {
  const formDefaults = {
    ...defaultForm,
    ...defaultsInLayout,
  };
  const cleanForm = R.omit(ignoredProperties, form);
  const formWithFilledFields = omitEmptyFields(cleanForm);

  return R.isEmpty(formDefaults)
    ? hasAnyFieldFilledIn(formWithFilledFields)
    : !deepEqual(formDefaults, formWithFilledFields);
};

export const setColumnIsValid = (
  formValidation: FormValidation,
  columnName: string,
  isValid: boolean,
  isValidating: boolean = false,
) =>
  merge2(
    "fields",
    columnName,
    { ...formValidation?.fields?.[columnName], isValid, isValidating },
    formValidation,
  );

import { Component } from "react";
import { defaultFor, getLocalizedName } from "common";
import { behaveAs, getColumn } from "common/entities";
import { Entity } from "common/entities/types";
// eslint-disable-next-line import/no-cycle
import { GroupField } from "common/form/group/field";
import { FormValidationProps, Group } from "common/form/types";
import { Section } from "common/form/ui";
import { LabelWidget } from "common/form/widget/label-widget";
import { getMeterType } from "common/functions/system-string-options";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { TRENDING_METER_TYPE_ID } from "common/types/system-strings";
import { VerticalField } from "common/ui/field";
import { RadioButtonList } from "common/widgets/radio-button-list";
import { ValueProps } from "common/with-value-for";
import { type FieldRefs } from "common/types/html";

interface PropTypes extends ValueProps<Properties>, FormValidationProps {
  context: Context;
  parentEntity: Entity;
  entity: Entity;
  groups: Group[];
  withLinks: boolean;
  fieldRefs?: FieldRefs;
}

export class MeterForm extends Component<PropTypes> {
  static readonly displayName = "MeterForm";

  onColumnChange = (columnName: string) => (columnValue: unknown) => {
    const { value, onChange } = this.props;

    onChange({ ...value, [columnName]: columnValue });
  };

  displayMeterType = (meterType: SystemIntFk) => {
    return getMeterType(meterType.title);
  };

  render() {
    const {
      parentEntity,
      entity,
      groups,
      context,
      withLinks,
      fieldRefs,
      formValidation,
      onFormValidationChange,
      value = defaultFor<Properties>(),
      onChange,
    } = this.props;

    const meterFormGroup = groups?.[0];
    const meterTypeColumn = getColumn(entity, "meterTypeId");
    const meterTypes = context.systemTables[meterTypeColumn.relatedSystem];
    const meterTypesToShow = meterTypes.filter(
      (o) => o.id !== TRENDING_METER_TYPE_ID,
    );

    const meterTypeInput =
      behaveAs("ConditionMonitoring", parentEntity) ||
      value.meterTypeId?.id === TRENDING_METER_TYPE_ID ? (
        <LabelWidget
          context={context}
          column={meterTypeColumn}
          value={value.meterTypeId}
        />
      ) : (
        <RadioButtonList
          name="type"
          items={meterTypesToShow}
          display={this.displayMeterType}
          value={meterTypesToShow.find(
            (item) => item.id === value.meterTypeId?.id,
          )}
          onChange={this.onColumnChange("meterTypeId")}
        />
      );

    return (
      <Section>
        <VerticalField
          ref={fieldRefs?.meterTypeId}
          label={getLocalizedName(meterTypeColumn)}
          error={!value.meterTypeId}
          input={meterTypeInput}
        />
        {meterFormGroup?.columns
          .filter((c) => c.columnName !== "meterTypeId")
          .sort((c) => (c.columnName === "unit" ? -1 : 0))
          .map((c) => (
            <GroupField
              key={c.columnName}
              context={context}
              entity={entity}
              groups={[]}
              layoutColumn={c}
              withLinks={withLinks}
              fieldRefs={fieldRefs}
              formValidation={formValidation}
              onFormValidationChange={onFormValidationChange}
              value={value}
              onChange={onChange}
            />
          ))}
      </Section>
    );
  }
}

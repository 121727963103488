import * as R from "ramda";
import { cloneElement, Component, ReactElement } from "react";
import { recordsApi } from "common/api/records";
import { searchApi } from "common/api/search";
import { behaveAs, isSharedMultipleSitesEntity } from "common/entities";
import { Entity } from "common/entities/types";
import { merge1 } from "common/merge";
import { QueryForEntity, RunQuery } from "common/query/types";
import { FormValue } from "common/record/types";
import { getRecordChanges, materializeTemporaryIds } from "common/record/utils";
import { Crud, RequestOptions } from "common/types/api";
import { Properties, Record } from "common/types/records";
import {
  createEditCtrlWithPermission,
  ExternalPropTypes,
} from "common/ui/controllers/edit";
import { createInjected } from "common/ui/controllers/edit/base";
import { ResolvedDependencies } from "common/with-dependencies";
import { withExplicitAuth, ExecuteAction } from "./with-explicit-auth";

export interface PropTypes extends ExternalPropTypes<string, Record> {
  entity: Entity;
  formId: number;
  confirmationTitle?: string;
  unwrap?: (formValue: FormValue) => Record;
  wrap?: (form: FormValue, record: Record) => FormValue;
  dependencies?: ResolvedDependencies;
  saveConfirmationMessage?: string;
  executeAction?: ExecuteAction;
}

const injectedRunQuery: { runQuery: RunQuery } = {
  runQuery: undefined,
};

export const EditController = createEditCtrlWithPermission<
  FormValue,
  string,
  Record
>();

export const recordInjected = R.mergeRight(
  createInjected<FormValue, string, Record>(),
  injectedRunQuery,
);

export const defaultUnwrap = (form: FormValue): Record => form.record;
export const defaultWrap = (form: FormValue, record: Record) =>
  merge1("record", record, form);

interface StateType {
  record: Record;
}

export class GenericRecordDetailControllerComp extends Component<
  PropTypes,
  StateType
> {
  static readonly displayName = "GenericRecordDetailController";
  constructor(props: PropTypes) {
    super(props);
    this.state = {
      record: undefined,
    };
  }

  getApi = (formId: number): Crud<string, Record> => {
    const { context, entity, executeAction } = this.props;

    const { site, recentlyViewed } = context;
    return {
      list: undefined,
      get: (id) =>
        recordsApi(context.apiCall)
          .get(entity.name, id, true)
          .then((record) => {
            this.setState({ record });
            recentlyViewed.add(site.name, entity.name, record.properties);
            return record;
          }),
      create: (record, requestOptions?: RequestOptions) => {
        const { properties } = record;

        const newProperties: Properties = {
          ...properties,
          ...(formId && !properties?.formId ? { formId } : undefined),
        };
        const newRecord = materializeTemporaryIds({
          ...record,
          properties: newProperties,
        });

        const createForSites =
          isSharedMultipleSitesEntity(entity) &&
          behaveAs("MasterContact", entity);

        return executeAction("save", this.state.record, record, (apiCall) => {
          const api = recordsApi(apiCall, requestOptions);
          const createRequest = createForSites
            ? api.createForSites(entity.name, newRecord)
            : api.create(entity.name, newRecord);
          return createRequest.then((response) => response);
        });
      },
      update: (updatedRecord, requestOptions?: RequestOptions) =>
        executeAction("save", this.state.record, updatedRecord, (apiCall) => {
          const { record } = this.state;

          const recordChanges = materializeTemporaryIds(
            getRecordChanges(record, updatedRecord, entity),
          );

          return recordsApi(apiCall, requestOptions)
            .update(entity.name, recordChanges)
            .then((response) => response);
        }),
      remove: (id, requestOptions?: RequestOptions) =>
        executeAction("remove", this.state.record, undefined, (apiCall) =>
          recordsApi(apiCall, requestOptions).remove(entity.name, id),
        ),
    };
  };

  // --- Event handlers ----------------------------------------------------- //

  runQueryForRecord = (query: QueryForEntity) => {
    const { context } = this.props;
    const { record } = this.state;
    const api = searchApi(context.apiCall);
    return record
      ? api.runQueryWithContext(query, record.properties)
      : api.runQuery(query);
  };

  wrapRecord = (form: FormValue, record: Record) => {
    const { wrap = defaultWrap } = this.props;
    return wrap(form, record);
  };

  unwrapRecord = (form: FormValue) => {
    const { unwrap = defaultUnwrap } = this.props;
    return unwrap(form);
  };

  // ------------------------------------------------------------------------ //

  render() {
    const {
      context,
      entity,
      formId,
      onHasChanged,
      children,
      confirmationTitle,
      canDelete,
      canRestore,
      forceFetch,
      id,
      isNew,
      onCancel,
      onDelete,
      onNotFound,
      onPreSave,
      onRecordChanged,
      onRestore,
      onSave,
      dependencies,
      needSaveConfirmation,
      saveConfirmationMessage,
      // unused: skipDeleteConfirmation
      // used internally: defaultProperties, unwrap, wrap
    } = this.props;

    return (
      <div className="x-detail">
        <EditController
          canDelete={canDelete}
          canRestore={canRestore}
          confirmationTitle={confirmationTitle}
          context={context}
          entity={entity}
          forceFetch={forceFetch}
          id={id}
          isNew={isNew}
          onCancel={onCancel}
          onDelete={onDelete}
          onNotFound={onNotFound}
          onPreSave={onPreSave}
          onRecordChanged={onRecordChanged}
          onRestore={onRestore}
          onSave={onSave}
          // formId comes from different places whether you
          // are creating wo normally, from wo task or requestor
          api={this.getApi(
            formId || dependencies?.defaultValue?.record?.properties?.formId,
          )}
          permissionCategory={entity.name}
          wrapRecord={this.wrapRecord}
          unwrapRecord={this.unwrapRecord}
          onHasChanged={onHasChanged}
          skipDeleteConfirmation={true}
          dependencies={dependencies}
          needSaveConfirmation={needSaveConfirmation}
          saveConfirmationMessage={saveConfirmationMessage}
          // optional props created for other use cases
          // that don't apply here
          deleteWarningMessage={undefined}
          dontLoad={undefined}
          confirmationLabel={undefined}
          defaultValue={undefined}
        >
          {cloneElement(children as ReactElement, {
            runQuery: this.runQueryForRecord,
          })}
        </EditController>
      </div>
    );
  }
}

export const GenericRecordDetailController = withExplicitAuth(
  GenericRecordDetailControllerComp,
);

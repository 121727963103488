import { Role } from "common/types/roles";
import { defaultUiFormat, UiFormat } from "common/types/ui-format";
import { UserContactInfo } from "common/types/user-contact-info";

export interface User {
  id: string;
  createdOn?: string;
  userName: string;
  password: string;
  passwordConfirm: string;
  fullName: string;
  role: Role;
  roleId: number;
  enabled: boolean;
  uiFormat: UiFormat;
  siteName: string;
  xToken: string;
  userTypes: UserType[];
  allowPasswordLogin: boolean;
  userContactInfo?: UserContactInfo;
  hasIntegration?: boolean;
  integrationToken?: string;
}

export type UserType =
  | "User"
  | "Admin"
  | "Requestor"
  | "FlukeMobile"
  | "Vendor";

export interface FilterableUser {
  id: string;
  enabled: boolean;
  userName: string;
  fullName: string;
  roleLabel: string;
  roleId: number;
  siteName: string;
  siteLabel: string;
  language: string;
  userType: string;
}

export const defaultUserType: UserType[] = ["User"];
export const adminUserType: UserType[] = ["Admin"];
export const requestorUserType: UserType[] = ["Requestor"];
export const vendorUserType: UserType[] = ["Vendor"];
export const fullUserTypes: UserType[] = ["User", "Admin"];
export const defaultUserTypes = [
  defaultUserType,
  adminUserType,
  requestorUserType,
];

// FM Additional combinations
export const flukeMobileUserType: UserType[] = ["FlukeMobile"];
export const userAndFlukeMobileUserType: UserType[] = ["User", "FlukeMobile"];
export const adminAndFlukeMobileUserType: UserType[] = ["Admin", "FlukeMobile"];
export const flukeMobileUserTypes = [
  flukeMobileUserType,
  userAndFlukeMobileUserType,
  adminAndFlukeMobileUserType,
];

export const defaultUser: User = {
  id: undefined,
  userName: undefined,
  password: undefined,
  passwordConfirm: undefined,
  fullName: undefined,
  role: undefined,
  roleId: undefined,
  enabled: false,
  uiFormat: defaultUiFormat,
  siteName: undefined,
  xToken: undefined,
  userTypes: defaultUserType,
  allowPasswordLogin: undefined,
  createdOn: undefined,
};

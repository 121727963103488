export const CREATED_ON = "createdOn";
export const CURRENCY = "currency";
export const ESTIMATED_COST = "estimatedCost";
export const PART = "partId";
export const PART_SUPPLIER = "partSupplierId";
export const PART_LOCATION = "partLocationId";
export const REQUISITIONING = "requisitioningId";
export const REQUISITIONING_ITEM_STATUS = "statusId";
export const UPDATED_ON = "updatedOn";
export const QUANTITY = "quantity";

export const AWAITING_APPROVAL_ITEM = 1; // Awaiting Approval
export const APPROVED_ITEM = 2; // Approved
export const REJECTED_ITEM = 3; // Rejected

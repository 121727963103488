import * as R from "ramda";
import { getLocalizedName } from "common";
import {
  getLocalizedColumnName,
  looseColumnNameCheck,
} from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { merge1 } from "common/merge";
import { PathMap } from "common/query/advanced-types";
import { getPathMap } from "common/query/joins";
import {
  ConditionFilter,
  Expression,
  Field as QueryField,
  Filter,
  FilterSubQuery,
  isAnd,
  isField,
  isOr,
  isSubQuery,
  JoinItem,
  Query,
  QueryForEntity,
  SummaryField,
} from "common/query/types";
import { Context } from "common/types/context";
import { Field } from "./types";

// TODO: this should be a SelectItem
interface QuerySelectItem {
  name?: string;
  expression?: string;
  path?: string;
  entityName?: string;
  queryValue?: FilterSubQuery;
}

export const handleAdd =
  (
    setValue: (x: QuerySelectItem[]) => any,
    value: QuerySelectItem[],
    map?: (x: QuerySelectItem) => QuerySelectItem,
  ) =>
  (field: Field) => {
    const newValue = !field
      ? { expression: "", alias: "" }
      : field.path
        ? { name: field.column.name, path: field.path }
        : { name: field.column.name };
    const mapFn = map ? map : (v: QuerySelectItem) => v;
    setValue(R.append(mapFn(newValue), value));
  };

export const getField = (fields: Field[], item: QuerySelectItem) =>
  R.find(
    (f) =>
      looseColumnNameCheck(f?.column?.name, item?.name) &&
      f?.path === (item?.path || ""),
    fields,
  );

export const isEntityDuplicated = (entityName: string, pathMap: PathMap) =>
  R.values(pathMap).filter((e) => e.name === entityName).length > 1;

export const resolveDuplicates = (
  entities: Entities,
  baseEntity: Entity,
  path: string,
) => {
  if (!path) return undefined;
  const paths = path.split("/").filter((p) => p);
  const columnName = paths[paths.length - 1];
  if (columnName.indexOf(".") !== -1) {
    const [entityName, name] = columnName.split(".");
    const entity = entities[entityName];
    const localColumnName = getLocalizedColumnName(entity, name);
    return `${getLocalizedName(entity)}.${localColumnName}`;
  }
  return getLocalizedColumnName(baseEntity, columnName);
};

const mapPathMapIntoField =
  (entities: Entities, pathMap: PathMap) =>
  ([path, entity]: [string, Entity]): Field[] =>
    entity.columns.map((column) => {
      const suffix = isEntityDuplicated(pathMap[path].name, pathMap)
        ? resolveDuplicates(entities, pathMap[""], path)
        : undefined;
      return {
        path,
        column,
        entityName: pathMap[path].name,
        minPath:
          getLocalizedName(pathMap[path]) + (suffix ? ` (${suffix})` : ""),
      };
    });

const getFieldsForMap =
  (entities: Entities) =>
  (pathMap: PathMap): Field[] => {
    if (!pathMap[""]) return [];
    const mapPair = mapPathMapIntoField(entities, pathMap);

    return R.compose(
      (fs: any) => R.flatten(fs),
      R.map(mapPair),
      R.toPairs,
    )(pathMap);
  };

const filterJoins = (
  pathMap: PathMap,
  joins: JoinItem[],
  parentPath: string = "",
): JoinItem[] =>
  R.reduce<JoinItem, JoinItem[]>(
    (acc, join) => {
      const joinPath = join.entity
        ? `${parentPath}/${join.entity}.${join.column}`
        : `${parentPath}/${join.column}`;

      const validJoin = !!pathMap[joinPath];

      if (!validJoin) return acc;

      if (!join.joins) return acc.concat(join);

      const newJoinChildren = filterJoins(pathMap, join.joins, joinPath);

      return acc.concat(merge1("joins", newJoinChildren, join));
    },
    [],
    joins,
  );

export const getFields = (entities: Entities, query: QueryForEntity) =>
  R.compose(getFieldsForMap(entities), getPathMap)(entities, query);

// Entities -> Query -> Query | undefined
export const sanitize = (
  entities: Entities,
  q: QueryForEntity,
): QueryForEntity => {
  // 0) q is defined
  if (!q) return undefined;
  if (!q.query) return q;

  // 3) Compute fields
  const pathMap = getPathMap(entities, q);

  const isValid = (item: QueryField | Expression | SummaryField): boolean =>
    item && isField(item) ? pathMap[item.path || ""] !== undefined : true;

  const { select, group, order, filter, having, relatedSummary } = q.query;

  const validSelect = (select || []).filter(isValid);
  const newSelect =
    (select || []).length === validSelect.length ? select : validSelect;
  const validGroup = (group || []).filter(isValid);
  const newGroup =
    (group || []).length === validGroup.length ? group : validGroup;
  const validOrder = (order || []).filter(isValid);
  const newOrder =
    (order || []).length === validOrder.length ? order : validOrder;

  // 4) Filter
  const sanitizeFilter = (filter: ConditionFilter) =>
    R.pickBy<ConditionFilter, ConditionFilter>((val) => !R.isNil(val), filter);

  const validateFilter = (f: Filter) => {
    if (!f) return undefined;

    const mapAndFilter = R.compose(
      (fs: Filter[]) => R.filter((f: Filter) => !!f, fs),
      (fs: Filter[]) => R.map(validateFilter, fs),
    );

    const filterChildren = (op: "and" | "or", f: Filter): Filter => {
      const ch = mapAndFilter((f as any)[op]);
      if (!ch.length) return undefined;
      return { [op]: ch } as unknown as Filter;
    };

    if (isAnd(f)) return filterChildren("and", f);
    if (isOr(f)) return filterChildren("or", f);
    if (isSubQuery(f)) return sanitizeFilter(f);

    return isValid(f) ? sanitizeFilter(f) : undefined;
  };
  const newFilter = filter ? validateFilter(filter) : undefined;
  const newHaving = having ? validateFilter(having) : undefined;

  const newJoins =
    q.query.joins && q.query.joins.length
      ? filterJoins(pathMap, q.query.joins)
      : undefined;

  const query: Query = {
    select: newSelect,
    joins: newJoins,
    group: newGroup,
    order: newOrder,
    filter: newFilter,
    having: newHaving,
    relatedSummary,
  };

  return { ...q, query: R.pickBy((value) => value !== undefined, query) };
};

export const sortFields = (fields: Field[]) =>
  R.sortBy((f) => `${f.path}/${f.column.name}`, fields);

export const fieldsEqual = (a: Field[], b: Field[]): boolean =>
  a.length === b.length &&
  R.all(
    ([fa, fb]) => fa.minPath === fb.minPath && fa.column === fb.column,
    R.zip(sortFields(a), sortFields(b)),
  );

export const isQueryEquals = (
  entities: Entities,
  entity: Entity,
  a: Query,
  b: Query,
): boolean => {
  const as = sanitize(entities, { entity: entity.name, query: a });
  const bs = sanitize(entities, { entity: entity.name, query: b });
  return R.equals(as, bs);
};

export const getFullPathFromField = (field: Field) => {
  if (!field) return undefined;

  const path = field.path ? field.path : "";
  return path + "/" + field.column.name;
};

export const getFromEntities = (context: Context): Entities => {
  const { entities, userTypes } = context;
  const isAdmin = userTypes.includes("Admin");
  const userDataViewName = "UserData";

  if (isAdmin || !(userDataViewName in entities)) return entities;

  const { [userDataViewName]: userDataView, ...filteredEntities } = entities;
  return filteredEntities;
};

import { Context } from "common/types/context";
import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { Link } from "common/widgets/link";
import { Title } from "../utils";

interface PropTypes {
  context: Context;
  entity: Entity;
  columnName: string;
  value: Title;
}

/** Title line */
export const TitleInfo = ({
  context,
  entity,
  columnName,
  value,
}: PropTypes) => {
  const { link, value: titleValue } = value;
  return (
    <div
      className={`x-info-title qa-${entity.name}-${columnName}`}
      role="heading"
    >
      {link ? (
        <Link
          className="x-link-record qa-link-record"
          href={link}
          title={titleValue}
        >
          <LabelWidget
            context={context}
            column={getColumn(entity, columnName)}
            value={titleValue}
          />
        </Link>
      ) : (
        <LabelWidget
          context={context}
          column={getColumn(entity, columnName)}
          value={titleValue}
        />
      )}
    </div>
  );
};

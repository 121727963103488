import * as R from "ramda";
import { toPascalCase } from "common";
import { mergeChain } from "common/merge";
import {
  GroupItem,
  isGroupExpression,
  isGroupField,
  isSelectExpression,
  isSelectField,
  isSummaryField,
  Query,
  QueryRelatedSummary,
  SelectField,
  SelectItem,
} from "common/query/types";

export const getGroupForSelect = (select: SelectItem[] = []): GroupItem[] =>
  select
    .filter(
      (s) =>
        (isSelectExpression(s) && !s.expression) || (isSelectField(s) && !s.fn),
    )
    .map(
      ({ name, path }: SelectField): GroupItem => ({
        name,
        path,
      }),
    );

// replace the select in `query` with `select` taking care of the `group`
export const setSelect = (select: SelectItem[] = [], query: Query): Query => {
  const group = query?.group;

  const noDups =
    R.uniqBy(
      (f) =>
        isSelectField(f)
          ? f.alias || f.name
          : isSummaryField(f)
            ? f.entityName
            : f?.alias,
      select,
    ) || [];

  const notAggregates = noDups.filter(isSelectField).filter((f) => !f.fn);

  const newGroup =
    !group || !group.length || !R.difference(notAggregates, group).length
      ? group
      : R.uniqBy(
          (f) =>
            [
              isGroupField(f) && f.path,
              isGroupField(f) && f.name,
              isGroupExpression(f) && f.expression,
            ].join("#"),
          (group || []).concat(notAggregates),
        );

  return R.mergeRight(query, {
    select: noDups,
    group: newGroup,
  });
};

// append `newSelect` to the `query`'s select taking care of the `group`
export const addToSelectQuery = (
  newSelect: SelectItem[] = [],
  query: Query,
): Query => {
  const select = query?.select || [];
  return setSelect(select.concat(newSelect), query);
};

export const mapSelectToRelatedSummary = (query: Query): Query => {
  const select = query?.select || [];

  const newSelect = R.filter((s) => !isSummaryField(s), select);

  const relatedSummary = select.reduce(
    (acc, s, index) =>
      isSummaryField(s) ? R.mergeRight(acc, { [s.entityName]: index }) : acc,
    {} as QueryRelatedSummary,
  );

  return mergeChain(query)
    .set("select", newSelect)
    .set("relatedSummary", relatedSummary)
    .output();
};

export const mapRelatedSummaryToSelect = (query: Query): Query => {
  const select = query?.select || [];
  const relatedSummary = query?.relatedSummary;

  const relatedSummaryByPosition = R.invertObj(relatedSummary as any);
  const relatedSummaryIndexes = R.values(relatedSummary);

  const newSelect = relatedSummaryIndexes.reduce((acc, index) => {
    const summary = {
      entityName: toPascalCase(relatedSummaryByPosition[index]),
    };
    return R.insert(index, summary, acc);
  }, select);

  return mergeChain(query)
    .set("select", newSelect)
    .set("relatedSummary", {})
    .output();
};

export const hasSelectWithName = (name: string, select: SelectItem[] = []) =>
  R.any((s) => isSelectField(s) && s.name === name, select);

export const getSelectField = (alias: string, select: SelectItem[]) =>
  alias && select?.length
    ? select.filter(isSelectField).find((s) => s.alias === alias)
    : undefined;

export const getSelectWithId = (select: SelectItem[] = []) =>
  hasSelectWithName("id", select) ? select : select.concat([{ name: "id" }]);

export const getSelectFieldByTitle = (select: SelectItem[]): SelectField =>
  getSelectField("title", select);

export const getSelectFieldBySubtitle = (select: SelectItem[]): SelectField =>
  getSelectField("subtitle", select);

export const getSelectFieldBySubSubtitle = (
  select: SelectItem[],
): SelectField => getSelectField("subsubtitle", select);

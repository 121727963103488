import { FormValue } from "common/record/types";
import { ValueProps } from "common/with-value-for";
import { withStar, PropTypes as PropTypesWithStar } from "common/with-star";
import { EditRecordForm, PropTypes as FormPropTypes } from "./form";

type PropTypes = PropTypesWithStar<FormPropTypes & ValueProps<FormValue>>;

const WithStar = withStar<PropTypes>();

export const FormWithStar = (
  {
    id,
    context,
    goTo,
    entity,
    loading,
    hidePanels,
    runQuery,
    withLinks,
    reload,
    eventId,
    auditTrailId,
    saving,
    deleting,
    deleteLabels,
    layout, // unused. the only parent sets undefined. FormWithLayout injects layout down the tree
    mapLayout,
    isNew,
    disableEdit,
    save,
    onDelete,
    onCancel,
    onHasChanged,
    hideRibbon,
    crumbs,
    value,
    onChange,
    getUrl,
    displaySidebar,
    displayHeader,
  }: PropTypes, // should not be in proptypes: starred, toggleStar
) => (
  <WithStar
    // the only props used
    id={id}
    context={context}
    entity={entity}
    // bad proptypes definition (not used here)
    starred={undefined}
    toggleStar={undefined}
    // these are all passed down to children
    mapLayout={mapLayout}
    runQuery={runQuery}
    save={save}
    saving={saving}
    onDelete={onDelete}
    deleting={deleting}
    onCancel={onCancel}
    onHasChanged={onHasChanged}
    isNew={isNew}
    goTo={goTo}
    withLinks={withLinks}
    loading={loading}
    reload={reload}
    eventId={eventId}
    auditTrailId={auditTrailId}
    deleteLabels={deleteLabels}
    disableEdit={disableEdit}
    hidePanels={hidePanels}
    hideRibbon={hideRibbon}
    crumbs={crumbs}
    layout={layout}
    value={value}
    onChange={onChange}
    getUrl={getUrl}
    displaySidebar={displaySidebar}
    displayHeader={displayHeader}
  >
    <EditRecordForm
      // injected by WithStar
      toggleStar={undefined}
      starred={undefined}
      // passed down from WithStar
      mapLayout={undefined}
      context={undefined}
      entity={undefined}
      runQuery={undefined}
      save={undefined}
      saving={undefined}
      onDelete={undefined}
      deleting={undefined}
      onCancel={undefined}
      onHasChanged={undefined}
      isNew={undefined}
      goTo={undefined}
      withLinks={undefined}
      loading={undefined}
      reload={undefined}
      eventId={undefined}
      auditTrailId={undefined}
      deleteLabels={undefined}
      disableEdit={undefined}
      hidePanels={undefined}
      hideRibbon={undefined}
      crumbs={undefined}
      layout={undefined}
      value={undefined}
      onChange={undefined}
      getUrl={undefined}
      displaySidebar={undefined}
      displayHeader={undefined}
    />
  </WithStar>
);

FormWithStar.displayName = "FormWithStar";

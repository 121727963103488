import { PropsWithChildren } from "react";
import type { ApplicationScope } from "common/types/context";
import { setDocumentTitle } from "common/utils/document-title";
import { Breadcrumb, Crumb } from "./breadcrumb";

const breadcrumbFor = (title: string, crumbs: Crumb[]) => {
  if (title) return <Breadcrumb crumbs={[{ name: title }]} />;
  if (crumbs) return <Breadcrumb crumbs={crumbs} />;
  return null;
};

interface RibbonPropTypes {
  onRefresh: () => any | void;
  scope: ApplicationScope;
  title?: string;
  shouldSetDocumentTitle?: boolean;
  className?: string;
  crumbs?: Crumb[];
  children?: any;
}

export const Ribbon = ({
  onRefresh,
  scope,
  title,
  shouldSetDocumentTitle = true,
  className,
  crumbs,
  children,
}: RibbonPropTypes) => {
  shouldSetDocumentTitle &&
    setDocumentTitle(
      title ||
        crumbs
          ?.slice()
          .reverse()
          .map((c) => c.name)
          .join(" - "),
      scope,
    );

  return (
    <div className={`x-ribbon ${className || ""}`}>
      {onRefresh ? (
        <div className="btn btn-ribbon" onClick={onRefresh}>
          <i className="fa fa-refresh" data-testid="refresh-icon" />
        </div>
      ) : undefined}
      {breadcrumbFor(title, crumbs)}
      {children}
    </div>
  );
};

export const RibbonButtons = ({ children }: PropsWithChildren<unknown>) => (
  <div className="x-btns-right">{children}</div>
);

Ribbon.displayName = "Ribbon";
RibbonButtons.displayName = "RibbonButtons";

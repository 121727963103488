import { PurchaseOrderStatus } from "./types";
export const READONLY_PO_STATUS: PurchaseOrderStatus[] = [
  PurchaseOrderStatus.WaitingForApproval,
  PurchaseOrderStatus.WaitingForParts,
];

export const DISABLED_PO_STATUS: PurchaseOrderStatus[] = [
  PurchaseOrderStatus.Closed,
  PurchaseOrderStatus.Rejected,
  PurchaseOrderStatus.Cancelled,
];

export const EDITABLE_SUBMITTED_PURCHASE_ORDER_STATUS: PurchaseOrderStatus[] = [
  PurchaseOrderStatus.Opened,
  PurchaseOrderStatus.WaitingForApproval,
  PurchaseOrderStatus.WaitingForParts,
  PurchaseOrderStatus.PartiallyRecieved,
];

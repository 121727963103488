import * as R from "ramda";
import { toPascalCase } from "common/index";
import { behaveAs } from "common/entities";
import { merge2, merge3, mergeChain } from "common/merge";
import { getDynamicDefaultsToResolve } from "common/form/defaults";
import { resolveDynamicValue } from "common/form/dynamic-values";
import { getFormByIdOrEntity } from "common/functions/forms";
import { normalizeIds } from "common/record/form/content/related/table-with-form/functions";
import {
  FormValue,
  isStandardUiValue,
  ScheduledEventValue,
} from "common/record/types";
import { BehaviorName } from "common/types/behaviors";
import { SYSTEM_FIELDS } from "common/entities/entity-column/types";
import { Record, Properties, RelatedRecords } from "common/types/records";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";

const excludedRelatedBehavior: BehaviorName[] = [
  "AssetMeterReading",
  "BatchCharge",
  "CurrencyTranslation",
  "LaborCharge",
  "PartCharge",
  "Stock",
];

export function getBehaviorColumnsToReset(entity: Entity) {
  if (behaveAs("PartsToBeCounted", entity)) {
    return [
      "physicalCountOne",
      "physicalCountTwo",
      "physicalCountThree",
      "statusId",
      "previousOnHand",
      "previousCost",
      "finalCost",
      "finalCount",
    ];
  }
  if (behaveAs("PurchaseOrderItem", entity)) {
    return ["statusId", "receivedOn", "receivedQty"];
  }
  if (behaveAs("RequisitioningItem", entity)) {
    return ["statusId"];
  }
  if (behaveAs("Procedure", entity)) {
    return ["procedureStatusId", "assetMeterReadingId"];
  }

  return [];
}

export function getEntityAllowedProperties(
  entity: Entity,
  properties: Properties,
) {
  if (!entity || !properties) return {};

  const excludedColumns = SYSTEM_FIELDS.concat(
    getBehaviorColumnsToReset(entity),
  );

  const allowedProperties: Properties = {};
  for (const column of entity.columns) {
    const value = properties[column.name];

    if (value && !column.unique && !excludedColumns.includes(column.name)) {
      allowedProperties[column.name] = value;
    }
  }
  return allowedProperties;
}

export function getFormDefaults(
  context: Context,
  entity: Entity,
  formId: number,
) {
  const form = getFormByIdOrEntity(context.forms, entity?.name, formId);
  const formDefaults = form?.settings?.defaults;

  const { defaultsWithDynamicValues } = getDynamicDefaultsToResolve(
    context,
    entity,
    formDefaults,
    true,
  );

  const resolvedDefaults: Properties = {};
  for (const defaultValue of defaultsWithDynamicValues) {
    const { key, dataType, value, entity } = defaultValue;

    resolvedDefaults[key] = resolveDynamicValue(
      dataType,
      entity,
      value,
      context,
    );
  }

  return {
    ...formDefaults,
    ...resolvedDefaults,
  };
}

export function shouldExcludeRelatedEntity(entity: Entity) {
  return excludedRelatedBehavior.some((behavior) => behaveAs(behavior, entity));
}

export function getRelatedEntityRecords(
  context: Context,
  sourceRecordRelated: RelatedRecords,
) {
  if (!sourceRecordRelated) return undefined;

  const newRelated: RelatedRecords = {};

  for (const entityName in sourceRecordRelated) {
    // Fix for BE lowercasing the first letter of the entity name in the WO Template
    const normalizedEntityName = toPascalCase(entityName);
    const relatedEntity = context.entities[normalizedEntityName];

    if (!relatedEntity || shouldExcludeRelatedEntity(relatedEntity)) continue;

    const relatedRecords = sourceRecordRelated[entityName].map(
      (relatedRecord) => {
        const formDefaults = getFormDefaults(
          context,
          relatedEntity,
          relatedRecord.properties?.formId,
        );
        const newProperties = getEntityAllowedProperties(
          relatedEntity,
          relatedRecord.properties,
        );
        return {
          ...relatedRecord,
          isNew: true,
          properties: {
            ...formDefaults,
            ...newProperties,
          },
          related: getRelatedEntityRecords(context, relatedRecord.related),
        };
      },
    );

    newRelated[normalizedEntityName] = normalizeIds(relatedRecords);
  }

  return newRelated;
}

export function getScheduledWorkOrderForm(
  context: Context,
  entity: Entity,
  form: FormValue,
  sourceRecord: Record,
  includeRelated: boolean,
) {
  const relatedRecords = getRelatedEntityRecords(
    context,
    sourceRecord?.related,
  );
  const scheduledWorkOrderRelated = includeRelated
    ? relatedRecords
    : R.pick([entity.arguments.eventAssetEntity], relatedRecords);
  const workOrderRelated = getRelatedEntityRecords(
    context,
    sourceRecord.properties.workOrderForm?.related,
  );

  const scheduledWorkOrderForm = merge2(
    "record",
    "related",
    scheduledWorkOrderRelated,
    form,
  );

  return includeRelated
    ? mergeChain(scheduledWorkOrderForm as unknown as ScheduledEventValue)
        .prop("ui")
        .prop("entityForm")
        .prop("related")
        .set("form", workOrderRelated)
        .output()
    : scheduledWorkOrderForm;
}

export function getFormForClone(
  context: Context,
  entity: Entity,
  form: FormValue,
  sourceRecord: Record,
  includeRelated: boolean,
): FormValue {
  if (!isStandardUiValue(form)) return form;

  if (behaveAs("ScheduledEvent", entity)) {
    return getScheduledWorkOrderForm(
      context,
      entity,
      form,
      sourceRecord,
      includeRelated,
    );
  }

  return merge3(
    "ui",
    "related",
    "form",
    getRelatedEntityRecords(context, sourceRecord?.related),
    form,
  );
}

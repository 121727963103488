import * as React from "react";
import { SiteSelectorTreeModal } from "common/widgets/site-selector/site-tree-modal";
import { getSitesDictionary } from "common/functions/sites";
import { Context } from "common/types/context";
import { Site } from "common/types/sites";
import { Selector } from "common/widgets/selector";
import { ValueProps } from "common/with-value-for";
import { isGroupSite } from "x/account-settings/sites/functions";
import { getDisplaySiteFn } from "../site-tree-selector-with-search/functions";
import { getSingleSites, updateSelectedSites } from "./functions";

interface PropTypes extends ValueProps<string[]> {
  context: Context;
  disabled?: boolean;
  sitesToOmit?: Site[];
  className?: string;
  includeGroupSites?: boolean;
  hideTreeSelector?: boolean;
  allowMultipleSelection?: boolean;
  isExpandable?: boolean;
  allowClear?: boolean;
  placeholder?: string;
}

interface StateType {
  isTreeSelectorOpen: boolean;
}

export class SiteSelector extends React.Component<PropTypes, StateType> {
  static readonly displayName = "SiteSelector";

  state: StateType = {
    isTreeSelectorOpen: false,
  };

  onSiteChange = (site: Site) => {
    const {
      context: { sites },
      value = [],
      allowMultipleSelection,
      onChange,
      includeGroupSites,
    } = this.props;

    const newValue = allowMultipleSelection
      ? updateSelectedSites(site, includeGroupSites, sites, value)
      : site?.name
        ? [site?.name]
        : undefined;

    onChange(newValue);
  };

  onTreeSelectorClick = () => {
    this.setState({ isTreeSelectorOpen: true });
  };

  isSiteDisabled = (site: Site) => {
    const { sitesToOmit, includeGroupSites, allowMultipleSelection } =
      this.props;
    return allowMultipleSelection
      ? !includeGroupSites && isGroupSite(site)
      : !!(
          sitesToOmit?.some((s) => s.name === site.name) ||
          (!includeGroupSites && isGroupSite(site))
        );
  };

  onClose = () => {
    this.setState({ isTreeSelectorOpen: false });
  };

  render() {
    const {
      className,
      context,
      sitesToOmit,
      includeGroupSites,
      hideTreeSelector,
      allowMultipleSelection,
      isExpandable,
      disabled,
      value,
      allowClear,
      placeholder,
      onChange,
    } = this.props;
    const { isTreeSelectorOpen } = this.state;
    const sitesDictionary = getSitesDictionary(context.sites);
    const sites = sitesToOmit
      ? context.sites.filter((s) => !sitesToOmit.includes(s))
      : context.sites;
    const sitesForSelector = includeGroupSites ? sites : getSingleSites(sites);

    return (
      <div className="x-site-selector-wrapper qa-site-selector">
        <Selector<Site>
          className={className}
          placeholder={placeholder || _("Select a site")}
          getOptionLabel={getDisplaySiteFn(sitesDictionary)}
          allowClear={allowClear}
          options={sitesForSelector}
          disabled={disabled}
          value={
            !allowMultipleSelection
              ? sites.find((s) => s.name === value?.[0])
              : undefined
          }
          onChange={this.onSiteChange}
        />

        {!hideTreeSelector ? (
          <div className="x-tree-selector">
            <button
              className={`btn x-tree-selector-button qa-tree-selector-button`}
              title={_("Sites Tree Selector")}
              onClick={this.onTreeSelectorClick}
            >
              <i className="fa fa-sitemap" />
            </button>
          </div>
        ) : undefined}

        {isTreeSelectorOpen && context.sites.length > 0 ? (
          <SiteSelectorTreeModal
            sites={context.sites}
            isSiteDisabled={this.isSiteDisabled}
            isExpandable={isExpandable}
            includeGroupSites={includeGroupSites}
            allowMultipleSelection={allowMultipleSelection}
            onClose={this.onClose}
            value={value}
            onChange={onChange}
          />
        ) : undefined}
      </div>
    );
  }
}

import * as R from "ramda";
import { findColumn, getNormalizedColumnsMap } from "common/entities";
import { Form } from "common/types/forms";
import { Entity } from "common/entities/types";
import {
  haveMatchingDataType,
  normalizeColumnName,
} from "common/entities/entity-column/functions";
import { Properties } from "common/types/records";
import { getLayoutAndDrilldownColumns } from "common/form/functions/layout";

const filterFormFields = (form: Form, properties: Properties) => {
  const formFieldNames = getLayoutAndDrilldownColumns(form?.settings);
  return R.pick(formFieldNames, properties);
};

export const getMatchingProperties = (
  sourceEntity: Entity,
  destinationEntity: Entity,
  properties: Properties,
  form?: Form,
) => {
  if (!destinationEntity || !sourceEntity || !properties) return undefined;

  const destinationColumnsMap = getNormalizedColumnsMap(destinationEntity);

  const entityProps: Properties = {};

  for (const propName in properties) {
    const sourceColumn = findColumn(sourceEntity.columns, propName);
    const destinationColumn = destinationColumnsMap.get(
      normalizeColumnName(propName),
    );

    const areMatchingDataTypes =
      sourceColumn &&
      destinationColumn &&
      haveMatchingDataType(
        sourceColumn,
        destinationColumn,
        properties[propName],
      );

    if (!areMatchingDataTypes) continue;

    const shouldNotPopulate =
      R.isNil(properties[propName]) ||
      destinationColumn.readOnly ||
      destinationColumn.unique;

    if (shouldNotPopulate) continue;

    entityProps[destinationColumn.name] = properties[propName];
  }

  return form ? filterFormFields(form, entityProps) : entityProps;
};

import * as R from "ramda";
import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { LookupQuery } from "common/form/types";
import { addFilterToQuery } from "common/query/filter";
import { Filter, QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { getPartialForm } from "../functions";
import { getSelectedLocationIdsForPart } from "../part/functions";
import { RelatedValue } from "../types";

export const createPartLocationFilter = (
  entity: Entity,
  value: RelatedValue,
  defaultOnly: boolean = false,
  partId: string = undefined,
  excludeSelectedLocations: boolean = true,
): Filter => {
  const currentPartId = getPartialForm(value, entity.name)?.partId?.id;
  const selectedPartLocationIds = getSelectedLocationIdsForPart(
    entity.name,
    value,
    partId || currentPartId,
  );

  return {
    and: [
      {
        excludeFromFkExpansion: true,
        name: "partId",
        op: "eq",
        value: partId || currentPartId,
      },
      selectedPartLocationIds.length && excludeSelectedLocations
        ? {
            excludeFromFkExpansion: true,
            name: "id",
            op: "notin",
            value: selectedPartLocationIds.join(","),
          }
        : undefined,
      defaultOnly ? { name: "isDefault", op: "istrue" } : undefined,
    ].filter(R.identity),
  };
};

const getPartLocationEntity = (context: Context, entity: Entity) =>
  context.entities[entity.arguments.stockEntity];

export const createPartLocationLookupQuery = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
  value: RelatedValue,
  excludeSelectedLocations?: boolean,
): LookupQuery => {
  const partLocationsEntity = getPartLocationEntity(context, entity);
  return {
    columnName: "partLocationId",
    entityName: partLocationsEntity.name,
    query: addFilterToQuery(
      createPartLocationFilter(
        entity,
        value,
        false,
        undefined,
        excludeSelectedLocations,
      ),
      partLocationsEntity.query,
    ),
  };
};

export const createDefaultPartLocationQuery = (
  context: Context,
  entity: Entity,
  value: RelatedValue,
  partId: string,
): QueryForEntity => {
  const partLocationsEntity = getPartLocationEntity(context, entity);
  return {
    entity: partLocationsEntity.name,
    query: addFilterToQuery(
      createPartLocationFilter(entity, value, true, partId),
      partLocationsEntity.query,
    ),
  };
};

import { JSX } from "react";
import { VariantType } from "react-tooltip";
import { defaultFor } from "common";
import { behaveAs } from "common/entities";
import { isRelatedSiteDataColumn } from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { Filter } from "common/query/types";
import { Context } from "common/types/context";
import { getValueFromUniqueValue } from "common/widgets/unique/functions";
import { getSiteLabelByName } from "x/account-settings/sites/functions";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";

export const defaultColumn = defaultFor<EntityColumn>();

export const isSiteRelatedColumn = (column: EntityColumn) =>
  column.name === "site" || isRelatedSiteDataColumn(column);

export const getSiteValue = (
  context: Context,
  column: EntityColumn,
  value: any,
) =>
  isSiteRelatedColumn(column)
    ? getSiteLabelByName(context.sites, value)
    : getValueFromUniqueValue(value);

export const getSelfOutFilter = (
  entity: Entity,
  column: EntityColumn,
  recordId: string,
): Filter => {
  if (!entity || !column) return undefined;

  const isTreeEntityWithSelfFkColumn =
    behaveAs("Tree", entity) &&
    column.name &&
    entity.arguments.fkColumn === column.name &&
    column.dataType === "fk" &&
    column.relatedEntity === entity.name;

  return recordId && isTreeEntityWithSelfFkColumn
    ? {
        name: "id",
        op: "neq",
        value: recordId,
      }
    : undefined;
};

const getTooltip = (message: string, variant: VariantType = "info") =>
  getTooltipProps(message, variant);

export const getReadOnlyFieldIcon = (
  column: EntityColumn,
  isRestricted: boolean,
): JSX.Element => {
  if (column && column.isSystem && column.readOnly) {
    return (
      <i
        className="fa fa-times-circle"
        {...getTooltip(_("This is a system read only field"))}
      />
    );
  }

  if (isRestricted) {
    return (
      <i
        className="fa fa-lock"
        {...getTooltip(_("You are not allowed to update this field"), "error")}
      />
    );
  }

  if (column?.userReadOnly) {
    return (
      <i
        className="fa fa-times-circle-o"
        {...getTooltip(_("This is a user read only field"))}
      />
    );
  }

  return (
    <i
      className="fa fa-times-circle-o"
      {...getTooltip(_("This is a read only field"))}
    />
  );
};

import * as R from "ramda";
import { useLocation } from "react-router-dom";
import { getError } from "common/api/error";
import { recordsApi } from "common/api/records";
import { PropTypes } from "common/record/actions/types";
import {
  showErrorNotification,
  showInfoNotification,
} from "common/ui/notification";
import { SiteSelectorTreeModal } from "common/widgets/site-selector/site-tree-modal";
import { useConfirmation } from "common/widgets/warning/use-confirmation";
import { Warning } from "common/widgets/warning";
import {
  isChildSelected,
  isParentSelected,
} from "common/widgets/site-selector/functions";

export const ChangeSite = (props: PropTypes) => {
  const location = useLocation();
  const { context, entity, records, dismiss, goTo } = props;
  const { onClose, onConfirm, isOpen, onOpen } = useConfirmation();

  if (!records.length) return null;

  const confirmation = isOpen ? (
    <Warning
      title={_(
        "This update will make the record unavailable for one or more of its current sites.",
      )}
      action1={_("Discard Changes")}
      action2={_("Save")}
      onAction1={onClose}
      onAction2={onConfirm}
    />
  ) : null;

  const { properties } = records[0];
  const { sites, id: recordId } = properties;
  const getSitesId = (sites: string[]) =>
    context.sites
      .filter((cSite) => sites.includes(cSite.name))
      .map((cSite) => cSite.id);

  const updateSite = (sites: string[], redirectTo?: string) =>
    recordsApi(context.apiCall)
      .sitesChange(entity.name, recordId, getSitesId(sites))
      .then(() => {
        showInfoNotification(_("The record was successfully updated"));
        redirectTo ? goTo(redirectTo) : dismiss(true);
      })
      .catch((e) => showErrorNotification(getError(e)));

  const onChange = (sites: string[]) => {
    const isSiteSelected = sites.includes(context.site.name);
    const isParent = isParentSelected(
      context.site.name,
      sites,
      context.sites,
      [],
    );
    const isChild = isChildSelected(
      context.site.name,
      sites,
      context.sites,
      [],
    );

    return isSiteSelected || isParent || isChild
      ? updateSite(sites)
      : onOpen().then(() => {
          const targetPath = `/${context.site.name}/${entity.name}`;
          const redirectTo =
            location.pathname === targetPath ? undefined : `#${targetPath}`;
          return updateSite(sites, redirectTo);
        });
  };
  return (
    <>
      {confirmation}
      <SiteSelectorTreeModal
        sites={context.sites}
        isSiteDisabled={R.F}
        isExpandable={true}
        includeGroupSites={true}
        allowMultipleSelection={true}
        onClose={() => dismiss(false)}
        value={sites}
        onChange={onChange}
        shouldCloseOnApply={false}
        isRequired={true}
      />
    </>
  );
};

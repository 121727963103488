import * as R from "ramda";
import { Component } from "react";
import {
  hasActions,
  hasForm,
  hasTab,
  hasTable,
} from "common/functions/related-form-display";
import {
  hasPermissionToCreate,
  hasPermissionToUpdate,
} from "common/functions/roles";
import { defaultFor } from "common/index";
import { merge2 } from "common/merge";
import { Action, ActionWithContent } from "common/query/table/types";
import { getFormOrDefault } from "common/record/edit/functions";
import { EditRelatedForm } from "common/record/form/content/related/table-with-form/related-form/form";
import { RelatedEntityTable } from "common/record/related-entity-table";
import { RelatedForm } from "common/record/types";
import { Record } from "common/types/records";
import { defaultDisplayTypes } from "common/types/related-form-display";
import { Tab } from "common/widgets/tabs/tab";
import { Tabs } from "common/widgets/tabs/tabs";
import { ValueProps } from "common/with-value-for";
import { deleteTempRecord, updateRecords } from "./functions";
import { CreateRelatedFormWithDependencies } from "./related-form";
import { defaultValue, PropTypes, TableWithFormValue } from "./types";

type Props = PropTypes & ValueProps<TableWithFormValue>;

export class TableWithForm extends Component<Props> {
  static readonly displayName = "TableWithForm";

  relatedEntityTableRef: RelatedEntityTable = undefined;

  getActions = (): Action[] => {
    const { entity, value = defaultValue, actions, onChange } = this.props;
    const { records = [] } = value;

    const commandHandlers: Action[] = [
      {
        name: "Delete",
        fn: (record: RelatedForm) => {
          const { id, tempId } = record;

          if (tempId) {
            const newRecords = deleteTempRecord(records, tempId);
            onChange({
              form: undefined,
              records: newRecords.length ? newRecords : undefined,
            });
          } else {
            const index = R.findIndex((r) => r.properties.id === id, records);

            const newRecord = (record: Record): Record =>
              merge2("properties", "isDeleted", true, record);

            const newRecords = updateRecords(records, index, newRecord);

            onChange({
              form: undefined,
              records: newRecords.length ? newRecords : undefined,
            });
          }
        },
      },
    ];

    return [
      ...(commandHandlers.filter((commandHandler) =>
        entity.commands.includes(commandHandler.name),
      ) ?? []),
      ...(actions ?? []),
    ];
  };

  onHideExpandedAction = () => {
    if (this.relatedEntityTableRef)
      this.relatedEntityTableRef.hideExpandedAction();
  };

  onCancelForm = () => {
    this.props.onChange({ ...this.props.value, form: undefined });
    this.onHideExpandedAction();
  };

  getActionsWithContent = (): ActionWithContent[] => {
    const {
      context,
      entity,
      actionsWithContent,
      defaultForm,
      isRecordDisabled,
      value = defaultFor(),
    } = this.props;

    const canUpdate = hasPermissionToUpdate(
      context.userTypes,
      context.role,
      entity.name,
    );

    return [
      ...(actionsWithContent ?? []),
      ...(canUpdate
        ? [
            {
              name: "Update",
              // We need this to be an anonymous function because it has to
              // trigger a re-render of the RelatedEntityTable component
              // eslint-disable-next-line react/no-unstable-nested-components
              fn: () => {
                const updatedValue = {
                  ...value,
                  records: value.records?.filter(
                    (record) => !isRecordDisabled?.(record),
                  ),
                };
                const form = R.keys(value.form).length
                  ? value.form
                  : defaultForm;

                const layoutForm = getFormOrDefault(
                  context,
                  entity.name,
                  form && form.formId,
                  form,
                );
                return (
                  <EditRelatedForm
                    {...this.props}
                    value={updatedValue}
                    layoutForm={layoutForm}
                    onCancel={this.onHideExpandedAction}
                  />
                );
              },
              onActionRun: this.onUpdateActionRun,
              onClose: this.onCancelForm,
            },
          ]
        : []),
    ];
  };

  onChangeRecords = (records: Record[]) =>
    this.props.onChange({ ...this.props.value, records });

  onUpdateActionRun = (record: RelatedForm) => {
    const { value, onChange } = this.props;

    // keeps the original record, removing any joined property
    // that might be incorrect after the record is modified
    const originalRecord = R.pickBy<RelatedForm, RelatedForm>(
      (_, columnName) => !R.includes(".", columnName),
      record,
    );
    onChange({ ...value, form: originalRecord });
  };

  setRelatedEntityTableRef = (ref: RelatedEntityTable) => {
    this.relatedEntityTableRef = ref;
  };

  render() {
    const {
      context,
      parentEntity,
      entity,
      recordId,
      query,
      withOwnerId,
      orderBy,
      displayTypes = defaultDisplayTypes,
      highlighted,
      ignore,
      forceInclude,
      warning,
      widgetsMapper,
      value = defaultValue,
      withLinks,
      isRecordDisabled,
    } = this.props;

    const { records = [], form } = value;
    const { name, localizedName } = entity;

    const tempId = form && form.tempId;
    const id = form && form.id;
    const isNewRecord = !tempId && !id;
    const entityName = name.toLowerCase();

    const canDisplayNewForm =
      isNewRecord &&
      hasForm(displayTypes) &&
      hasPermissionToCreate(context.userTypes, context.role, entity.name);

    return (
      <div className={`x-related-container qa-related-${entityName}`}>
        {hasTab(displayTypes) && (
          <div className="x-related-header">
            <Tabs key={name}>
              <Tab value={name} label={localizedName || name} />
            </Tabs>
          </div>
        )}
        <div className="x-no-padding">
          {canDisplayNewForm ? (
            <CreateRelatedFormWithDependencies {...this.props} />
          ) : undefined}

          {warning}

          {hasTable(displayTypes) && (
            <RelatedEntityTable
              ref={this.setRelatedEntityTableRef}
              context={context}
              query={query}
              parentEntityName={parentEntity.name}
              entity={entity}
              recordId={recordId}
              actions={hasActions(displayTypes) ? this.getActions() : undefined}
              actionsWithContent={
                hasActions(displayTypes)
                  ? this.getActionsWithContent()
                  : undefined
              }
              withOwnerId={withOwnerId}
              orderBy={orderBy}
              ignore={ignore}
              forceInclude={forceInclude}
              highlighted={highlighted}
              widgetsMapper={widgetsMapper}
              isRecordDisabled={isRecordDisabled}
              value={records}
              onChange={this.onChangeRecords}
              withLinks={withLinks}
            />
          )}
        </div>
      </div>
    );
  }
}

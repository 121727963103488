import * as R from "ramda";
import { StringObject } from "common/app/query-string";
import { getColumn } from "common/entities";
import { selectorFilterTypes } from "common/entities/entity-column/data-type/types";
import { Entities, Entity } from "common/entities/types";
import { getFieldAndFormat, getSubFKTitle } from "common/functions/foreign-key";
import { isOneToOne } from "common/query-builder/joins/functions";
import { PathMap } from "common/query/advanced-types";
import { addFilter } from "common/query/filter";
import { getPathMap, mergeQueryJoins } from "common/query/joins";
import {
  getSelectFieldByTitle,
  getSelectWithId,
  setSelect,
} from "common/query/select";
import {
  Filter,
  isSelectField,
  isSummaryField,
  JoinItem,
  Query,
  QueryForEntity,
  RunQuery,
  Select,
  SelectExpression,
  SelectField,
  SelectItem,
} from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import type { Site } from "common/types/sites";
import { SITE_DATA_VIEW } from "common/types/view";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { ImageWithLoading } from "common/widgets/image-with-loading";
import { getSiteLabelByName } from "x/account-settings/sites/functions";
import { getImageSource, getSrcParameters } from "../image/functions";
import { TabContentProps } from "./types";

export const MAX_SELECTOR_RECORDS = 35;

interface FetchType {
  select: SelectItem[];
  joins: JoinItem[];
  fetch: (input: string) => CancellablePromise<any>;
}

export const filterDataTypes = (
  pathMap: PathMap,
  entity: Entity,
  items: SelectField[],
) =>
  items.filter((i) => {
    const column = getColumn(pathMap[i.path] || entity, i.name);
    return (
      column &&
      i.name !== "id" &&
      R.includes(column.dataType, selectorFilterTypes)
    );
  });

const getSelectorFilter = (
  input: string,
  entities: Entities,
  fullQuery: QueryForEntity,
  showDeleted: boolean,
) => {
  const entity = entities[fullQuery.entity];
  const { query } = fullQuery;
  const pathMap = getPathMap(entities, fullQuery);

  const and: Filter[] = !showDeleted
    ? [{ name: "isDeleted", op: "isfalse" }]
    : [];
  const andWithFilter = query.filter ? and.concat([query.filter]) : and;

  const nonExpressions = query.select.filter(isSelectField) as SelectField[];

  const expressions = query.select
    .filter((s) => !isSelectField(s))
    .reduce((acc: SelectField[], e: SelectExpression) => {
      const columnNames = e.expression.match(/{\/[\w|/|.]+}/g);
      const columns = columnNames.map((c) => {
        const parts = c.slice(1, -1).split("/");
        const name = R.last(parts);
        const path = R.init(parts).join("/");
        return path && pathMap[path] ? { name, path } : { name };
      });
      return acc.concat(columns);
    }, []);

  const orArray = filterDataTypes(
    pathMap,
    entity,
    nonExpressions.concat(expressions),
  );
  return orArray.length
    ? {
        and: andWithFilter.concat({
          or: orArray.map((s: SelectField) => ({
            name: s.name,
            path: s.path,
            op: "contains",
            value: input,
          })),
        }),
      }
    : { and: andWithFilter };
};

export const queryForInput = (
  entity: Entity,
  entities: Entities,
  fullQuery: QueryForEntity,
  showDeleted: boolean,
  limit: number = MAX_SELECTOR_RECORDS,
) => {
  const { query } = fullQuery;
  const title = getSelectFieldByTitle(query.select);
  const order = title ? [{ name: title.name, path: title.path }] : undefined;

  return (input: string): QueryForEntity => ({
    entity: entity.name,
    query: R.mergeRight(query, {
      select: getSelectWithId(query.select),
      group: query.group ? query.group.concat([{ name: "id" }]) : undefined,
      joins: query.joins,
      filter: getSelectorFilter(input, entities, fullQuery, showDeleted),
      order,
      pageSize: limit,
    }),
  });
};

export const getFetchRecordQuery = (
  runQuery: RunQuery,
  entities: Entities,
  entity: Entity,
  query: QueryForEntity,
  showDeleted: boolean,
  limit?: number,
): FetchType => {
  const fullQuery = query
    ? query
    : {
        entity: entity.name,
        query: entity.query,
      };
  const getQuery = queryForInput(
    entity,
    entities,
    fullQuery,
    showDeleted,
    limit,
  );

  return {
    select: fullQuery.query.select,
    joins: fullQuery.query.joins,
    fetch: (input: string) => runQuery(getQuery(input)),
  };
};

export const defaultFailedImgSrcIcon = `$(this).replaceWith('<i class=x-broken-icon></i>')`;
const defaultUndefinedImgSrcIcon = <i className="fa fa-file-image-o" />;
const imageWithFallback = (image: string, srcParameters?: StringObject) => (
  <ImageWithLoading
    src={getImageSource(image, srcParameters)}
    errorComp={<i className="fa fa-file-excel-o" />}
  />
);

export const addIconDom = (image: string, srcParameters?: StringObject) => (
  <div className="x-icon">
    {image
      ? imageWithFallback(image, srcParameters)
      : defaultUndefinedImgSrcIcon}
  </div>
);

export const addSubtitleDom = (subtitle: string, searchTerm?: string) => (
  <div className="x-subtitle">
    <ReactHighlightWords text={subtitle || "-"} search={searchTerm} />
  </div>
);

const addCustomTitleDom = (customTitle: string, searchTerm?: string) =>
  customTitle ? (
    <div className="x-customTitle">
      <ReactHighlightWords text={customTitle} search={searchTerm} />
    </div>
  ) : undefined;

const appendSite = (title: string, site: string, sites: Site[]) =>
  site ? `${title} (${getSiteLabelByName(sites, site)})` : title;

export const formatFkItemForDropdown =
  (
    hasImage: boolean,
    hasSubtitle: boolean,
    hasSubsubtitle: boolean,
    hasCustomTitle?: boolean,
    entity?: Entity,
    context?: Context,
  ) =>
  (item: ForeignKey, searchTerm?: string) => {
    if (!item) return null;
    const title = getSubFKTitle(item.title);
    const subtitle = getSubFKTitle(item.subtitle);
    const subsubtitle = getSubFKTitle(item.subsubtitle);
    const customtitle = getSubFKTitle(item.customtitle);

    const formattedTitle =
      entity && context
        ? getFieldAndFormat(
            entity,
            context,
            "title",
            appendSite(title || item.label, item.site, context.sites),
          )
        : title || item.label || "-";
    const formattedSubtitle =
      entity && context
        ? getFieldAndFormat(entity, context, "subtitle", subtitle)
        : subtitle;
    const formattedSubsubtitle =
      entity && context
        ? getFieldAndFormat(entity, context, "subsubtitle", subsubtitle)
        : subsubtitle;

    return (
      <div className="x-select-item">
        {hasImage
          ? addIconDom(item.image, getSrcParameters(context))
          : undefined}
        <div className="x-data">
          <div className="x-title qa-title">
            <ReactHighlightWords
              text={item.number ? item.number.toString() : undefined}
              search={searchTerm}
            />
            {item.number && formattedTitle ? " - " : undefined}
            <ReactHighlightWords text={formattedTitle} search={searchTerm} />
          </div>
          {hasSubtitle
            ? addSubtitleDom(formattedSubtitle, searchTerm)
            : undefined}
          {hasSubsubtitle
            ? addSubtitleDom(formattedSubsubtitle, searchTerm)
            : undefined}
          {hasCustomTitle
            ? addCustomTitleDom(customtitle, searchTerm)
            : undefined}
        </div>
      </div>
    );
  };

export const formatItem = (
  select: Select,
  entity?: Entity,
  context?: Context,
) => {
  const findInSelect = (name: string) =>
    !!select.find(
      (selectItem) =>
        !isSummaryField(selectItem) &&
        (selectItem.alias ?? (selectItem as SelectField).name) === name,
    );
  return formatFkItemForDropdown(
    findInSelect("image"),
    findInSelect("subtitle"),
    findInSelect("subsubtitle"),
    findInSelect("customtitle"),
    entity,
    context,
  );
};

export const getQueryFromPreferences = (
  context: Context,
  entityName: string,
): Query => {
  const { preferenceService, site } = context;

  const preferences = preferenceService.get()?.list;
  const entityPreferences = preferences.find(
    (pref) =>
      !pref.reportId && pref.entity === entityName && pref.site === site.name,
  );

  return entityPreferences?.lastFilter?.filter?.query;
};

export const mergeQueries = (
  from: QueryForEntity,
  to: QueryForEntity,
  select: Select = [],
): QueryForEntity => {
  const { entity, query } = mergeQueryJoins(from, to);

  const newSelect = (to?.query?.select || [])
    .concat(from?.query?.select || [])
    .concat(select);

  const queryWithGroups = setSelect(newSelect, query);
  return { entity, query: queryWithGroups };
};

export const mapEntityColumnsToSelect = (
  entity: Entity,
  path: string,
): SelectField[] => {
  const select: SelectField[] = path
    ? entity.joins.reduce((acc, join) => {
        const { entityName, column } = join;

        const canJoinEntity =
          isOneToOne(join) &&
          entityName !== entity.name &&
          entityName !== SITE_DATA_VIEW;

        if (!canJoinEntity) return acc;

        const entityColumn = entity.columns.find((c) => c.name === column);
        const label = entityColumn?.localizedName || entityColumn?.name;

        const selectField: SelectField = {
          name: column,
          alias: column,
          label,
          path: `/${path}`,
        };

        return acc.concat([selectField]);
      }, [])
    : [];

  return select;
};

export const addFilterToContentQueries = (
  filter: Filter,
  contentQueries: TabContentProps[],
) =>
  filter
    ? contentQueries.map((tabContent) => ({
        ...tabContent,
        queryForEntity: addFilter(filter, tabContent.queryForEntity),
      }))
    : contentQueries;

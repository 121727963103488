import { Component } from "react";
import { getEntityByBehavior } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
// eslint-disable-next-line import/no-cycle
import { GroupField } from "common/form/group/field";
import { WidgetOverwrite } from "common/form/types";
import { Context } from "common/types/context";
import { ValueProps } from "common/with-value-for";
import { Hint } from "common/widgets/hint";
import { PartRequisition, PartRequisitionItem, Requisition } from "./types";

interface PropTypes
  extends ValueProps<PartRequisition | PartRequisitionItem | Requisition> {
  context: Context;
  columnName: string;
  columns: EntityColumn[];
  showHint?: boolean;
  widgetsProps?: WidgetOverwrite;
}

export class RequisitionGroupFields extends Component<PropTypes> {
  static readonly displayName = "RequisitionGroupFields";

  render() {
    const {
      context,
      columnName,
      columns,
      showHint,
      widgetsProps,
      value,
      onChange,
    } = this.props;
    const entity = {
      ...getEntityByBehavior("Requisitioning", context.entities),
      columns,
    };

    return (
      <div className={`x-edit-field qa-${columnName}`} key={columnName}>
        <GroupField
          key={columnName}
          context={context}
          withLinks={true}
          groups={[]}
          entity={entity}
          layoutColumn={{
            required: true,
            readOnly: false,
            disabled: false,
            highlighted: false,
            columnName,
          }}
          formValidation={undefined}
          widgetsProps={widgetsProps}
          onFormValidationChange={undefined}
          value={value}
          onChange={onChange}
        />
        {showHint && columnName === "quantity" && (
          <Hint
            message={_(
              "Quantity = Quantity from existing requisition Item + Quantity from Reorder List",
            )}
          />
        )}
      </div>
    );
  }
}

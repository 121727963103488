import { useState } from "react";
import { getLocalizedName, toKebabCase } from "common";
import { withAuthAndReason } from "common/api";
import { ApiError } from "common/ui/api-error";
import { ESignatureContext } from "common/explicit-auth/esignature-context";
import { useESignatureContext } from "common/explicit-auth/use-esignature-context";
import { ApiCall } from "common/types/api";
import { ESignature, ESignatureReason as Reason } from "common/types/reasons";
import { ActionPropTypes } from "common/record/actions/types";
import { VerticalField } from "common/ui/field";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Modal } from "common/widgets/modal";
import { arrayToString } from "common/utils/array";
import { getSortedRecordNumbers } from "./functions/record";

export const Action = ({
  dismiss,
  context,
  onOk,
  entity,
  title,
  btnLabel,
  footerButtons,
  children,
  size,
  records,
  requiresAuthentication,
  hideRecordNumber,
  allowFullscreen,
  hideEntityInTitle,
  hideReason,
  hideFooter,
  noPadding,
}: ActionPropTypes) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const {
    isFilled,
    password,
    reason,
    eSignatureSettings,
    loading,
    loadingError,
    onReasonChange,
    onPasswordChange,
  } = useESignatureContext(context.apiCall, requiresAuthentication, hideReason);

  const runAction = () => {
    const eSignature: ESignature = { text: reason?.text, code: reason?.code };
    const eSignatureReason: Reason = { eSignature };

    const api: ApiCall = password
      ? (withAuthAndReason(
          context.apiCall,
          password,
          eSignatureReason,
        ) as ApiCall)
      : context.apiCall;

    setIsLoading(true);
    const promise = onOk(api, () => dismiss(true));

    promise
      .then(() => {
        onPasswordChange(undefined);
        setIsLoading(false);
      })
      .catch((error) => {
        onPasswordChange(undefined);
        setIsLoading(false);
        setError(error);
      });
  };

  const onCancel = () => {
    dismiss(false);
  };

  const recordNumbers = getSortedRecordNumbers(records);

  const submit =
    onOk && !isLoading && (!requiresAuthentication || isFilled)
      ? () => runAction()
      : undefined;

  const content = (
    <>
      {isLoading && <LoadingIcon />}
      {!requiresAuthentication && error && (
        <ApiError error={error} className="x-margin-bottom-20-i" />
      )}
      {requiresAuthentication && (
        <ESignatureContext
          context={context}
          submit={submit}
          error={error}
          loading={loading}
          loadingError={loadingError}
          eSignatureSettings={eSignatureSettings}
          onReasonChange={onReasonChange}
          onPasswordChange={onPasswordChange}
          password={password}
          reason={reason}
          hideReason={hideReason}
          onErrorChange={setError}
        />
      )}
      {!hideRecordNumber && (
        <VerticalField
          label={
            recordNumbers.length > 1 ? _("Record numbers") : _("Record number")
          }
          input={<p className="x-align-left">{recordNumbers.join(", ")}</p>}
        />
      )}
      {children}
    </>
  );

  const fullTitle = arrayToString([
    title,
    hideEntityInTitle ? "" : getLocalizedName(entity),
  ]);

  return (
    <Modal
      className={`x-${toKebabCase(title)}`}
      content={content}
      title={fullTitle}
      okLabel={loading ? _("Please wait") : btnLabel}
      onOk={submit}
      onCancel={onCancel}
      size={size}
      footerButtons={footerButtons}
      allowFullscreen={allowFullscreen}
      hideFooter={hideFooter}
      noPadding={noPadding}
    />
  );
};

import { Component } from "react";
import { searchApi } from "common/api/search";
import { getEntityByBehavior } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { FormSelector } from "common/form/form-selector";
import { filterFormsByEntity, getFormById } from "common/functions/forms";
import {
  PART_LOCATION,
  REQUISITIONING,
} from "common/record/form/content/related/requisitioning-item/consts";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { ForeignKey } from "common/types/foreign-key";
import { Form } from "common/types/forms";
import { ApiError } from "common/ui/api-error";
import { VerticalField } from "common/ui/field";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Required } from "common/widgets/required";
import { ValueProps } from "common/with-value-for";
import { loadUserContact } from "x/records/edit/behavior-form/requisitioning/functions/common";
import { getCostCenterQuery } from "x/records/edit/behavior-form/requisitioning/functions/cost-center-query";
import { PartRequisition, PartRequisitionItem, Requisition } from "../types";
import { findRequiredColumns, getFormDefaults } from "../../functions";
// eslint-disable-next-line import/no-cycle
import { RequisitionForm } from "./form";

interface CreateNewRequisitionPropTypes extends ValueProps<Requisition> {
  context: Context;
  entity: Entity;
  partRequisitionEntity: Entity;
}

interface StateTypes {
  requisitionsForms: Form[];
  error: ApiErrorResponse;
  loading: boolean;
  requiredColumns: EntityColumn[];
  requiredItemColumns: EntityColumn[];
}

export class CreateNewRequisition extends Component<
  CreateNewRequisitionPropTypes,
  StateTypes
> {
  static readonly displayName = "CreateNewRequisition";

  constructor(props: CreateNewRequisitionPropTypes) {
    super(props);
    this.state = {
      requisitionsForms: [],
      error: undefined,
      loading: false,
      requiredColumns: [],
      requiredItemColumns: [],
    };
  }

  componentDidMount() {
    const { context, partRequisitionEntity, value, onChange } = this.props;
    const requisitionsForms: Form[] = filterFormsByEntity(
      context.forms,
      partRequisitionEntity.name,
    );
    const partRequisitionForm: Form = getFormById(
      requisitionsForms,
      value.partRequisition.formId,
    );
    const isValid = this.isFormValid(value.partRequisition);

    this.loadRequiredPartRequisitionColumns(partRequisitionForm);
    this.loadRequiredPartRequisitionItemColumns();
    this.loadUserContact(partRequisitionForm);
    this.setState(() => ({ requisitionsForms }));

    onChange({ ...value, isValid });
  }

  loadUserContact = (partRequisitionForm: Form) => {
    const { context, partRequisitionEntity, value, onChange } = this.props;
    const partRequisitionDefaults = getFormDefaults(partRequisitionForm);
    const { partRequisition } = value;

    if (partRequisitionDefaults?.requestedBy) {
      onChange({
        ...value,
        partRequisition: {
          ...partRequisition,
          requestedBy: partRequisitionDefaults.requestedBy,
        },
      });
    } else {
      this.setState({ loading: true });
      loadUserContact(context, partRequisitionEntity)
        .then((userContact) => {
          onChange({
            ...value,
            partRequisition: {
              ...partRequisition,
              requestedBy: userContact,
            },
          });
          this.setState({ loading: false });
        })
        .catch((error) => this.setState({ error, loading: false }));
    }
  };

  loadDefaultCostCenter = (approvalGroupId: ForeignKey) => {
    const { context, partRequisitionEntity, value, onChange } = this.props;
    const { partRequisition } = value;
    const { entities } = context;

    searchApi(context.apiCall)
      .runQuery(
        getCostCenterQuery(
          entities,
          partRequisitionEntity,
          approvalGroupId.id,
          true,
        ),
      )
      .then((approvalCostCenters: ForeignKey[] = []) => {
        const updatedRequisition = {
          ...partRequisition,
          approvalGroupId: approvalGroupId,
          approvalCostCenterId: approvalCostCenters[0],
        };
        const isValid = this.isFormValid(updatedRequisition);
        onChange({
          ...value,
          partRequisition: updatedRequisition,
          isValid,
        });
      })
      .catch((error) => this.setState({ error }));
  };

  loadRequiredPartRequisitionColumns = (partRequisitionForm: Form) => {
    const { entity, partRequisitionEntity, value, onChange } = this.props;
    const requiredColumns = findRequiredColumns(
      partRequisitionEntity,
      partRequisitionForm,
      entity.columns,
    );
    const partRequisitionDefaults = getFormDefaults(partRequisitionForm);

    this.setState({ requiredColumns });
    onChange({
      ...value,
      partRequisition: {
        ...value.partRequisition,
        ...partRequisitionDefaults,
      },
    });
  };

  loadRequiredPartRequisitionItemColumns = (
    partRequisitionItemFormId?: number,
  ) => {
    const { context, entity, value, onChange } = this.props;
    const { partRequisition } = value;
    const partRequisitionItemEntity = getEntityByBehavior(
      "RequisitioningItem",
      context.entities,
    );
    const requisitionItemForms: Form[] = filterFormsByEntity(
      context.forms,
      partRequisitionItemEntity.name,
    );
    const formId =
      partRequisitionItemFormId ||
      partRequisition.partRequisitionItems[0]?.formId ||
      requisitionItemForms[0]?.id;
    const partRequisitionItemForm = getFormById(requisitionItemForms, formId);
    const requiredItemColumns = findRequiredColumns(
      partRequisitionItemEntity,
      partRequisitionItemForm,
      entity.columns,
    ).filter(
      (itemColumn) =>
        !(
          itemColumn.name &&
          (itemColumn.name === PART_LOCATION ||
            itemColumn.name === REQUISITIONING)
        ),
    );
    const partRequisitionItemDefaults = getFormDefaults(
      partRequisitionItemForm,
    );
    const itemsWithDefaults: PartRequisitionItem[] =
      partRequisition.partRequisitionItems.map((partRequisitionItem) => ({
        ...partRequisitionItem,
        ...partRequisitionItemDefaults,
      }));

    this.setState({ requiredItemColumns });
    onChange({
      ...value,
      partRequisition: {
        ...partRequisition,
        partRequisitionItems: itemsWithDefaults,
      },
    });
  };

  isFormValid = (partRequisition: PartRequisition) => {
    const { requisitionsForms, requiredColumns, requiredItemColumns } =
      this.state;
    const {
      formId,
      partRequisitionItems,
      requestedBy,
      approvalCostCenterId,
      approvalGroupId,
    } = partRequisition;

    const isFormValid = !requisitionsForms?.length || !!formId;
    const isRequisitionValid =
      requestedBy &&
      approvalCostCenterId &&
      approvalGroupId &&
      !!partRequisitionItems.every((item) => item.quantity > 0);
    const isRequiredColumnsValid =
      requiredColumns.every((column) => partRequisition[column.name] != null) &&
      requiredItemColumns.every((column) =>
        partRequisitionItems.every((item) => item[column.name] != null),
      );

    return isFormValid && !!isRequisitionValid && isRequiredColumnsValid;
  };

  onFormSelectorChange = (partRequisitionFormId: number) => {
    const { value, onChange } = this.props;
    const { requisitionsForms } = this.state;
    const partRequisitionForm: Form = getFormById(
      requisitionsForms,
      partRequisitionFormId,
    );

    this.loadRequiredPartRequisitionColumns(partRequisitionForm);
    onChange({
      ...value,
      partRequisition: {
        ...value.partRequisition,
        formId: partRequisitionFormId,
      },
    });
  };

  onPartRequisitionChange = (newPartRequisition: PartRequisition) => {
    const { value, onChange } = this.props;
    const prevApprovalGroupId = value.partRequisition.approvalGroupId?.id;
    const newApprovalGroupId = newPartRequisition?.approvalGroupId?.id;
    const newPartRequisitionItemFormId =
      newPartRequisition?.partRequisitionItems?.[0].formId;
    const prevPartRequisitionItemFormId =
      value.partRequisition?.partRequisitionItems?.[0].formId;

    const isValid = this.isFormValid(newPartRequisition);

    if (newApprovalGroupId && prevApprovalGroupId !== newApprovalGroupId) {
      this.loadDefaultCostCenter(newPartRequisition.approvalGroupId);
    }
    if (newPartRequisitionItemFormId !== prevPartRequisitionItemFormId) {
      this.loadRequiredPartRequisitionItemColumns(newPartRequisitionItemFormId);
    }

    onChange({
      ...value,
      partRequisition: newPartRequisition,
      isValid,
    });
  };

  render() {
    const {
      context,
      partRequisitionEntity,
      value: { partRequisition },
    } = this.props;
    const {
      error,
      loading,
      requisitionsForms,
      requiredColumns,
      requiredItemColumns,
    } = this.state;

    return (
      <div>
        {error && <ApiError error={error} />}
        {loading && <LoadingIcon />}
        {requisitionsForms?.length ? (
          <div className="x-form-selector-container">
            <VerticalField
              className={"x-form-selector"}
              label={_("Part Requisitions Form")}
              input={
                <Required value={partRequisition.formId}>
                  <FormSelector
                    preselectFirstByDefault={false}
                    disabled={requisitionsForms.length < 2}
                    forms={requisitionsForms}
                    value={partRequisition.formId}
                    onChange={this.onFormSelectorChange}
                  />
                </Required>
              }
            />
          </div>
        ) : undefined}
        <RequisitionForm
          context={context}
          entity={partRequisitionEntity}
          requiredColumns={requiredColumns}
          requiredItemColumns={requiredItemColumns}
          value={partRequisition}
          onChange={this.onPartRequisitionChange}
        />
      </div>
    );
  }
}

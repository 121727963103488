import * as R from "ramda";
import { genericSortByField } from "common/functions/generics";
import {
  getAllDescendantNames,
  getParentToChildrenMap,
  getSitesDictionary,
} from "common/functions/sites";
import { Site, Sites } from "common/types/sites";
import { isGroupSite } from "x/account-settings/sites/functions";

export const getSingleSites = (sites: Site[]) =>
  genericSortByField<Site>("name")(sites.filter((s) => !s.isGroup));

/**
 * It returns all sites whose labels match the given `searchTerm`. In order
 * to render a valid tree it also returns all ancestors. To improve the UX it
 * also returns all descendants as well.
 *
 * With the given structure:
 *
 * All
 * |- Europe
 *  |- Ireland
 *   |- Dublin
 *   |- Cork
 * |- America
 *
 * Searching for `Irel` produces (It's a search when you know at least a
 * part of a parent location but don't remember exactly the child location):
 *
 * All
 * |- Europe
 *  |- Ireland
 *   |- Dublin
 *   |- Cork
 *
 * Searching for `Dub` produces (It's a search when you know at least a part of
 * a child location's name):
 *
 * All
 * |- Europe
 *  |- Ireland
 *   |- Dublin
 *
 * @param allSites
 * @param searchTerm
 */
export function filterNodesBySearchTerm(allSites: Site[], searchTerm: string) {
  if (!searchTerm) return allSites;

  const childrenMap = getParentToChildrenMap(allSites);
  const nodeMap = getSitesDictionary(allSites);

  const includedNodeIds = new Set<string>();
  const searchTermLower = searchTerm.toLowerCase();

  function addDescendants(nodeId: string) {
    const children = childrenMap[nodeId];

    if (!children) return;

    for (const child of children) {
      if (!includedNodeIds.has(child.name)) {
        includedNodeIds.add(child.name);
        addDescendants(child.name);
      }
    }
  }

  for (const nodeId in nodeMap) {
    const node = nodeMap[nodeId];

    if (node.label.toLowerCase().includes(searchTermLower)) {
      includedNodeIds.add(node.name);

      let currentNode = node;
      while (currentNode.parentName) {
        if (includedNodeIds.has(currentNode.parentName)) break;

        includedNodeIds.add(currentNode.parentName);
        currentNode = nodeMap[currentNode.parentName];
      }

      addDescendants(node.name);
    }
  }

  return Array.from(includedNodeIds).map((id) => nodeMap[id]);
}

export const getSelectedSites = (
  sites: Sites,
  selectedSiteList: string[] = [],
): Site[] => {
  return selectedSiteList.reduce((filteredSites: Site[], siteName: string) => {
    const site = sites?.[siteName];
    if (site) {
      filteredSites.push(site);
    }
    return filteredSites;
  }, []);
};

const getSite = (siteName: string, sites: Site[] = []) =>
  sites.find((site) => site.name === siteName);

const areAllSiblingsSelected = (
  selectedSite: Site,
  sites: Site[],
  selectedSiteList: string[] = [],
): boolean => {
  if (!selectedSite.parentName) return false;

  const siblings = sites.filter(
    (site) =>
      site.parentName === selectedSite.parentName &&
      site.name !== selectedSite.name,
  );

  return siblings.every((sibling) => selectedSiteList.includes(sibling.name));
};

export const getParentSiteIfAllSiblingsSelected = (
  selectedSite: Site,
  sites: Site[],
  selectedSiteList: string[] = [],
): string[] => {
  if (!selectedSite.parentName) return [];

  const sitesDictionary = getSitesDictionary(sites);

  const collectParentSites = (site: Site): string[] => {
    if (!site.parentName) return [];
    const parentSite = sitesDictionary[site.parentName];
    return parentSite && areAllSiblingsSelected(site, sites, selectedSiteList)
      ? [parentSite.name, ...collectParentSites(parentSite)]
      : [];
  };

  return collectParentSites(selectedSite);
};

export const getAllChildSiteForGroupSites = (
  selectedSites: string[],
  sites: Site[],
): string[] => {
  const sitesDictionary = getSitesDictionary(sites);

  return R.uniq(
    selectedSites.flatMap((siteName) => {
      const selectedGroupSite = sitesDictionary[siteName];
      return selectedGroupSite && isGroupSite(selectedGroupSite)
        ? getAllDescendantNames(selectedGroupSite, sites)
        : [];
    }),
  );
};

export const isParentSelected = (
  siteName: string,
  selectedSiteList: string[],
  sites: Site[],
  visited: string[],
): boolean => {
  // if site is already visited, terminate recursion
  if (visited.includes(siteName)) return false;
  visited.push(siteName);

  const site = getSite(siteName, sites);
  return site?.parentName
    ? selectedSiteList.includes(site.parentName) ||
        isParentSelected(site.parentName, selectedSiteList, sites, visited)
    : false;
};

export const isChildSelected = (
  siteName: string,
  selectedSiteList: string[],
  sites: Site[],
  visited: string[] = [],
): boolean =>
  selectedSiteList.some((selectedSite) =>
    isParentSelected(selectedSite, [siteName], sites, visited),
  );

// If the site has its parent selected, remove that from list
export const removeSiteIfParentAlreadySelected = (
  selectedSiteList: string[],
  sites: Site[],
): string[] =>
  selectedSiteList.filter((siteName) => {
    return !isParentSelected(siteName, selectedSiteList, sites, []);
  });

export const removeGroupSites = (
  selectedSiteList: string[],
  sitesDictionary: Sites,
): string[] =>
  selectedSiteList.filter(
    (siteName) => !isGroupSite(sitesDictionary[siteName]),
  );

export const updateSelectedSites = (
  site: Site,
  includeGroupSites: boolean,
  sites: Site[] = [],
  selectedSiteList: string[] = [],
): string[] => {
  if (!site?.name) return selectedSiteList;

  const updatedSelection = [...selectedSiteList];

  if (!includeGroupSites) {
    updatedSelection.push(site?.name);
    return updatedSelection;
  }

  updatedSelection.push(site?.name);

  return removeSiteIfParentAlreadySelected(updatedSelection, sites);
};

import { Component } from "react";
import { displayEntity } from "common/api/entities";
import type { Entity } from "common/entities/types";
import { isSelectField } from "common/query/types";
import type { Context } from "common/types/context";
import { Selector } from "common/widgets/selector";
import { DebounceInputWithSearch } from "common/widgets/input-with-search";
import { Sizer, Sizes } from "common/widgets/sizer";
import { Configuration } from "x/scheduler2/planner/unassigned-work/configuration";
import { ToggleableDayGroup } from "x/scheduler2/planner/unassigned-work/day-group";
import {
  defaultWOSelect,
  getCurrentDateRangeDays,
  getOverdueWOsRange,
  getParentPanelHeight,
  isDayOpened,
  UnassignedDayRange,
} from "x/scheduler2/planner/unassigned-work/functions";
import { getSearchQuery } from "x/scheduler2/planner/unassigned-work/functions/query";
import {
  getUnassignedWorkOrdersData,
  setDefaultPreferences,
} from "x/scheduler2/preferences";
import type { EntityQueries, ViewType } from "x/scheduler2/types";
import type { OnWorkOrderViewClickFn } from "./types";

interface PropTypes {
  workOrderEntities: Entity[];
  context: Context;
  selectedDate: string;
  view: ViewType;
  onWorkOrderViewClick: OnWorkOrderViewClickFn;
  hideWeekends: boolean;
  isDraggable: boolean;
  assignedWorkOrderId: string;
  isVisible: boolean;
  lastRefresh: number;
}

interface StateType {
  customQueries: EntityQueries;
  selectedWorkOrderEntity: Entity;
  searchValue: string;
}

export class UnassignedWorkPanel extends Component<PropTypes, StateType> {
  state: StateType = {
    customQueries: undefined,
    selectedWorkOrderEntity: undefined,
    searchValue: undefined,
  };

  componentDidMount() {
    this.setState(getUnassignedWorkOrdersData(this.props.context));
  }

  componentDidUpdate(_: PropTypes, prevState: StateType) {
    const { searchValue } = this.state;

    if (prevState.searchValue !== searchValue) {
      setDefaultPreferences(this.props.context, "defaultWOSearch", {
        value: searchValue,
      });
    }
  }

  onConfigurationChange = (value: EntityQueries) => {
    const { context } = this.props;
    this.setState({ customQueries: value });
    setDefaultPreferences(context, "defaultWOQueries", value);
  };

  onSearchChange = (value: string) => {
    this.setState({ searchValue: value });
  };

  onEntityChange = (entity: Entity) => {
    this.setState({ selectedWorkOrderEntity: entity });
  };

  renderPanel = (sizes: Sizes) => {
    const {
      workOrderEntities,
      onWorkOrderViewClick,
      selectedDate,
      view,
      isDraggable,
      hideWeekends,
      context,
      assignedWorkOrderId,
      isVisible,
      lastRefresh,
    } = this.props;

    const { customQueries, selectedWorkOrderEntity, searchValue } = this.state;

    const workOrderEntity = selectedWorkOrderEntity ?? workOrderEntities?.[0];
    const isEntityDropdownVisible = workOrderEntities?.length > 1;
    const isDraggableEnabled = isDraggable && isVisible;
    const daysRanges = [
      getOverdueWOsRange(view, workOrderEntity),
      ...getCurrentDateRangeDays(selectedDate, view, hideWeekends),
    ];

    if (!workOrderEntity) return null;

    const customQuery = customQueries?.[workOrderEntity.name];
    const customSelect =
      customQuery?.query?.select.filter(isSelectField) || defaultWOSelect;

    const searchQuery = searchValue
      ? getSearchQuery(context, workOrderEntity, customSelect, searchValue)
      : undefined;

    return (
      <>
        {isEntityDropdownVisible ? (
          <Selector<Entity>
            className="x-padding-bottom-5"
            options={workOrderEntities}
            getOptionLabel={displayEntity}
            value={workOrderEntity}
            onChange={this.onEntityChange}
          />
        ) : undefined}
        <div className="x-unassigned-header">
          <h2 className="x-work-order-list-title x-text-uppercase">
            {_("Unassigned work")}
          </h2>
          <Configuration
            context={context}
            workOrderEntity={workOrderEntity}
            value={customQueries}
            onChange={this.onConfigurationChange}
          />
        </div>
        <DebounceInputWithSearch
          value={searchValue}
          onChange={this.onSearchChange}
        />
        {daysRanges.map((day: UnassignedDayRange) => (
          <ToggleableDayGroup
            key={day.from ?? day.label}
            label={day.label}
            context={context}
            isDraggable={isDraggableEnabled}
            dateRange={day}
            customQueries={customQueries}
            searchQuery={searchQuery}
            searchValue={searchValue}
            onWorkOrderViewClick={onWorkOrderViewClick}
            workOrderEntity={workOrderEntity}
            assignedWorkOrderId={assignedWorkOrderId}
            additionalInfo={day.additionalInfo}
            lastRefresh={lastRefresh}
            isOpened={isDayOpened(view, daysRanges, day)}
            parentPanelHeight={getParentPanelHeight(
              sizes,
              isEntityDropdownVisible,
            )}
          />
        ))}
      </>
    );
  };

  render() {
    return (
      <Sizer
        className="x-scheduler-unassigned-panel"
        render={this.renderPanel}
      />
    );
  }
}

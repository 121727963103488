import { Component, Fragment } from "react";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { WidgetOverwrite } from "common/form/types";
import { Section } from "common/record/form/content/detail/view/section-group";
import { Context } from "common/types/context";
import { ValueProps } from "common/with-value-for";
import {
  APPROVAL_COST_CENTER,
  APPROVAL_GROUP,
} from "x/records/edit/behavior-form/requisitioning/consts";
import { createApprovalGroupLookUpQuery } from "x/records/edit/behavior-form/requisitioning/functions/approval-group-query";
import { getCostCenterQuery } from "x/records/edit/behavior-form/requisitioning/functions/cost-center-query";
import { getFakeEntityColumns } from "../../functions";
import { PartRequisition, PartRequisitionItem } from "../types";
// eslint-disable-next-line import/no-cycle
import { RequisitionGroupFields } from "../requisition-group-field";
import { RequisitionItem } from "./item";

interface PropTypes extends ValueProps<PartRequisition> {
  context: Context;
  entity: Entity;
  requiredColumns: EntityColumn[];
  requiredItemColumns: EntityColumn[];
}

export class RequisitionForm extends Component<PropTypes> {
  static readonly displayName = "RequisitionForm";

  onRequisitionItemChange = (newRequisitionItem: PartRequisitionItem) => {
    const { onChange, value } = this.props;
    const { partId, locationId } = newRequisitionItem;
    const updatedPartRequisitionItems = value.partRequisitionItems.map(
      (requisitionItem) =>
        requisitionItem.locationId === locationId &&
        requisitionItem.partId === partId
          ? newRequisitionItem
          : requisitionItem,
    );

    onChange({ ...value, partRequisitionItems: updatedPartRequisitionItems });
  };

  renderRequiredGroupFields = (isOdd: boolean) => {
    const {
      context,
      entity,
      requiredColumns = [],
      value,
      onChange,
    } = this.props;
    const columns: EntityColumn[] = [
      ...getFakeEntityColumns(context),
      ...requiredColumns,
    ];

    const getWidgetProps = (
      columnName: string,
    ): WidgetOverwrite | undefined => {
      switch (columnName) {
        case APPROVAL_GROUP:
          return {
            query: createApprovalGroupLookUpQuery(
              context,
              entity,
              value.requestedBy?.email,
            )?.query,
          };
        case APPROVAL_COST_CENTER:
          return {
            query: getCostCenterQuery(
              context.entities,
              entity,
              value.approvalGroupId?.id,
              false,
            ).query,
          };
        default:
          return undefined;
      }
    };

    return requiredColumns
      .filter((_, index) => (isOdd ? index % 2 !== 0 : index % 2 === 0))
      .map((column) => (
        <RequisitionGroupFields
          key={column.name}
          context={context}
          columnName={column.name}
          columns={columns}
          value={value}
          onChange={onChange}
          widgetsProps={getWidgetProps(column.name)}
        />
      ));
  };

  render() {
    const { context, requiredItemColumns, value } = this.props;
    const fakeEntityColumns = getFakeEntityColumns(context);

    return (
      <>
        {
          <div className="x-record-detail-view x-margin-0-i">
            <Section>
              <div className="row">
                <div className="col-sm-6" data-testid="left-group">
                  {this.renderRequiredGroupFields(false)}
                </div>
                <div className="col-sm-6" data-testid="right-group">
                  {this.renderRequiredGroupFields(true)}
                </div>
              </div>
            </Section>
            <Section title={_("Part Requisition Items")}>
              {value.partRequisitionItems.map((item, index) => (
                <Fragment key={`${item.partId?.id}${item.locationId?.id}`}>
                  {index > 0 && <hr />}
                  <RequisitionItem
                    context={context}
                    columns={fakeEntityColumns}
                    requiredItemColumns={requiredItemColumns}
                    isFirst={index === 0}
                    value={item}
                    onChange={this.onRequisitionItemChange}
                  />
                </Fragment>
              ))}
            </Section>
          </div>
        }
      </>
    );
  }
}

import { Component } from "react";
import { type FieldRefs } from "common/types/html";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { SelectorOption } from "common/widgets/selector/types";
import { defaultFor } from "common";
import { getColumn, getColumnLabel } from "common/entities";
import { Entity } from "common/entities/types";
import { FormValidationProps } from "common/form/types";
// eslint-disable-next-line import/no-cycle
import { InputWidget } from "common/form/widget/input-widget";
import { getIntFkId } from "common/functions/system-int";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { VerticalField } from "common/ui/field";
import { Selector } from "common/widgets/selector";
import { Hint } from "common/widgets/hint";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { ValueProps } from "common/with-value-for";
import { getWoFormOptions } from "./functions";

interface PropTypes extends ValueProps<Properties>, FormValidationProps {
  context: Context;
  entity: Entity;
  fieldRefs?: FieldRefs;
}

const getFkOptionLabel = (option: SelectorOption<SystemIntFk>) =>
  isGroupedOption(option) ? option.label : option.title;

export class WorkOrderGenerationFields extends Component<PropTypes> {
  static readonly displayName = "WorkOrderGenerationFields";

  onGenerateOnExceptionChange = (toggle: boolean) => {
    const { context, entity, value, onChange } = this.props;
    const woEntityForms = getWoFormOptions(context, entity);

    onChange({
      ...value,
      generateOnException: toggle,
      workOrderFormId: toggle ? woEntityForms[0] : undefined,
      taskId: undefined,
    });
  };

  onColumnChange = (columnName: string) => (columnValue: unknown) => {
    const { value, onChange } = this.props;
    onChange({ ...value, [columnName]: columnValue });
  };

  render() {
    const {
      context,
      entity,
      fieldRefs,
      formValidation,
      onFormValidationChange,
      value = defaultFor<Properties>(),
    } = this.props;

    const woEntityForms = getWoFormOptions(context, entity);

    return (
      <>
        <VerticalField
          className="qa-generate-on-exception-field"
          label={getColumnLabel(entity, "generateOnException")}
          input={
            <YesNo
              name="generateOnException"
              value={!!value.generateOnException}
              onChange={this.onGenerateOnExceptionChange}
            />
          }
        />
        {value.generateOnException ? (
          <>
            {/* @TODO check if we could use GroupColumn + createColumn here setting it as required */}
            <VerticalField
              ref={fieldRefs?.taskId}
              className="qa-task-field"
              label={getColumnLabel(entity, "taskId")}
              error={!value.taskId}
              input={
                <>
                  <InputWidget
                    context={context}
                    column={getColumn(entity, "taskId")}
                    disabled={false}
                    buffer={false}
                    allowClear={true}
                    validate={false}
                    formValidation={formValidation}
                    onFormValidationChange={onFormValidationChange}
                    value={value.taskId}
                    onChange={this.onColumnChange("taskId")}
                  />
                  <Hint
                    message={_(
                      "Task details and related information will be copied automatically to the work order",
                    )}
                  />
                </>
              }
            />
            <VerticalField
              ref={fieldRefs?.workOrderFormId}
              className="qa-work-order-form-field"
              label={getColumnLabel(entity, "workOrderFormId")}
              error={!value.workOrderFormId}
              input={
                <Selector<SystemIntFk>
                  getOptionLabel={getFkOptionLabel}
                  options={woEntityForms}
                  value={woEntityForms.find(
                    (o) => o.id === getIntFkId(value.workOrderFormId),
                    woEntityForms,
                  )}
                  onChange={this.onColumnChange("workOrderFormId")}
                />
              }
            />
          </>
        ) : undefined}
      </>
    );
  }
}

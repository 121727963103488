import { Component } from "react";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { Uploader } from "common/widgets/file-selector/uploader";
import { ValueProps } from "common/with-value-for";
import { Image, ImageLarge } from "../image";
import { getSrcParameters } from "../image/functions";

import "./index.scss";

interface PropTypes extends ValueProps<string> {
  context: Context;
  allowClear: boolean;
  onRemove: () => void;
  onUpload?: (file: FileType) => CancellablePromise<any>;
  onError?: () => void;
  isLarge?: boolean;
  alt?: string;
}

export class ImageWithUpload extends Component<PropTypes> {
  static readonly displayName = "ImageWithUpload";

  removeImage = () => {
    const { onRemove, onChange } = this.props;
    onRemove();
    onChange(undefined);
  };

  render() {
    const { context, allowClear, onUpload, alt, isLarge, value, onError } =
      this.props;
    const showClearButton = value && allowClear;
    const ImageComp = isLarge ? ImageLarge : Image;
    return (
      <div className="x-image-with-upload">
        <ImageComp
          alt={alt}
          onError={onError}
          value={value}
          srcParameters={getSrcParameters(context)}
        />
        {onUpload && (
          <Uploader
            uploadFile={onUpload}
            buttonAsIcon={true}
            isNewFile={!value}
            acceptType="image"
          />
        )}
        {showClearButton && (
          <div className="x-btn-delete-wrapper" onClick={this.removeImage}>
            <i className="fa fa-trash" />
          </div>
        )}
      </div>
    );
  }
}
